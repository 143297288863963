import { api } from '../query/api';
import { useFetch } from '../query/reactQuery';
import { urlsApi } from "./urls/apiUrl";

export const useGetProductPrice = (lookupKey: string) =>
  api.get<any>(urlsApi.payment.get_product_prices + '/' + lookupKey, {});

export const useGetCheckVatNumber = (param: any) =>
  api.get<any>(urlsApi.payment.check_vat_number + '/' + param.country + '/' + param.vat_number, {});

export const usePostProductPriceWithTax = (data: any) =>
  api.post<any>(urlsApi.payment.get_product_price_with_tax + '/' + data.lookupKey + '/' + data.country, data.data);

export const useGetPaymentMethod = (enabled: boolean) =>
  useFetch<any>(urlsApi.payment.payment_method, {}, {enabled});

export const usePostPaymentMethod = (data: any) =>
  api.post<any>(urlsApi.payment.payment_method, data);

export const useDeletePaymentMethod = () =>
  api.delete<any>(urlsApi.payment.payment_method);

export const useGetUserInvoices = (enabled: boolean) =>
  useFetch<any>(urlsApi.payment.get_user_invoices + '?paid&invoice_origin=manda_matchmaking', {}, {enabled, queryKey: ['receipts', undefined]});

export const useGetUserReceipts = (pagination?: {page:number, size: number}) =>
  api.get<any>(urlsApi.payment.get_user_invoices + '?paid&invoice_origin=manda_matchmaking' + (pagination ? ('&page=' + pagination.page + '&page_size=' + pagination.size) : ''), {});
import { useEffect, useState } from "react";
import {
    useGetAcquisitionType,
    useGetHowValueBusiness,
    useGetReasonListingBusiness,
    useGetTimeframes
} from "../../../../../api/seller";
import SellerSteps from "../../../../../components/seller/seller-steps";
import { IAcquisitionOverview } from "../../../../../types/acquisition-overview";

export type IProps = {
    handleNextStep: (step: number) => void;
    showCancelModal: () => void;
    setData: (data: IAcquisitionOverview) => void;
    data: IAcquisitionOverview;
    validateSteps: Array<number>;
    currentStep: number;
    setCurrentStep: (step: number) => void;
    removeStepInValidated: (step: number) => void;
    addStepToValidated: (step: number) => void;
};

export default function AcquisitionOverview(props: IProps) {
    const [formState, setFormState] = useState(props?.data);
    const [enabled, setEnabled] = useState(false);
    const { data: dataAcquisition } = useGetAcquisitionType(enabled);
    const { data: dataTimeframes } = useGetTimeframes(enabled);
    const { data: dataHowValueBusiness } = useGetHowValueBusiness(enabled);
    const { data: dataReasonListing } = useGetReasonListingBusiness(enabled);

    const [errorListing, setErrorListing] = useState(false);
    const [errorDescListing, setErrorDescListing] = useState(false);
    const [errorTimeframe, setErrorTimeframe] = useState(false);
    const [errorValueBusiness, setErrorValueBusiness] = useState(false);
    const [errorAcquisitions, setErrorAcquisitions] = useState(false);

    const updateState = (property: string, value: string) => {
        setFormState((prevState: IAcquisitionOverview) => ({
            ...prevState,
            [property]: value,
        }));
    }

    useEffect(() => {
        if(props?.currentStep === 2) setEnabled(true);
        if(dataAcquisition && dataTimeframes && dataHowValueBusiness && dataReasonListing) setEnabled(false);
    }, [props?.currentStep, dataAcquisition, dataHowValueBusiness, dataReasonListing, dataTimeframes]);

    useEffect(() => {
        setErrorListing(false);
    }, [formState?.reason]);

    useEffect(() => {
        setErrorDescListing(false);
    }, [formState?.explication]);

    useEffect(() => {
        setErrorTimeframe(false);
    }, [formState?.transaction_duration]);

    useEffect(() => {
        setErrorValueBusiness(false);
    }, [formState?.business_value]);

    useEffect(() => {
        setErrorAcquisitions(false);
    }, [formState?.acquisiton_type]);

    useEffect(() => {
        props?.setData(formState);
    }, [formState]);

    const goToStep = (step: number, isGoingNextStep: boolean) => {
        if(Number(formState?.reason) !== 0 && Number(formState?.acquisiton_type) !== 0 && Number(formState?.transaction_duration) !== 0 && Number(formState?.business_value) !== 0) {
            if(Number(formState?.reason) == 1 && formState?.explication !== "") {
                props?.setData(formState);
                props?.handleNextStep(step);
                props?.addStepToValidated(props?.currentStep);
            } else if(Number(formState?.reason) !== 1) {
                formState.explication = "";
                props?.setData(formState);
                props?.handleNextStep(step);
                props?.addStepToValidated(props?.currentStep);
            }
            else {
                props?.removeStepInValidated(props?.currentStep);

                if(isGoingNextStep) setErrorDescListing(true);
                
                return;
            }
        } else {
            if(isGoingNextStep) {
                if(Number(formState?.reason) ===  0) {
                    setErrorListing(true);
                }
                if(Number(formState?.acquisiton_type) === 0) {
                    setErrorAcquisitions(true);
                }
                if(Number(formState?.transaction_duration) === 0) {
                    setErrorTimeframe(true);
                }
                if(Number(formState?.business_value) === 0) {
                    setErrorValueBusiness(true);
                }
            }

            props?.removeStepInValidated(props?.currentStep);

            return;
        }

    }

    return (
        <>
            <SellerSteps
                validatedSteps= {props?.validateSteps}
                currentStep= {props?.currentStep}
                handleContinue= {goToStep}
                setCurrentStep= {props?.setCurrentStep}
            />
            <div className='container mx-auto minHeightwin pt-4'>
                <div className="scrollContainer">
                    <div className='contentUser acquisition'>
                        <div className='contentBorder row'>
                            <div className='form-group required mb-4 p-0'>
                                <label htmlFor=''>Why are you listing your business in the M&A marketplace?</label>{" "}
                                {
                                    errorListing && (
                                        <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                                
                                <select className='form-control mt-2'
                                    name='reason'
                                    onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                                >
                                    <option value={0}>Select an option ...</option>
                                    {
                                        dataReasonListing?.data?.map((el : any, index : any) => (
                                            <option selected={el?.id == formState?.reason} value={el?.id} key={index}>{el?.attributes?.Label}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            {
                                Number(formState?.reason) == 1 && (
                                    <div className='form-group required mb-4 p-0'>
                                        <label htmlFor=''>Please elaborate why are you interested in finding an acquirer</label>
                                        {
                                            errorDescListing && (
                                                <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                                    <span className='error-red'> ERROR </span>{" "}
                                                    <span className='txt-error'>This field is required</span>
                                                </div>
                                            )
                                        }
                                        <textarea
                                            name='explication'
                                            className='form-control mt-2'
                                            maxLength={500}
                                            value={formState?.explication}
                                            onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                                        />
                                    </div>
                                )
                            }
                            
                            <div className='form-group required mb-4 p-0'>
                                <label htmlFor=''>What acquisition type are you aiming for? </label>
                                {
                                    errorAcquisitions && (
                                        <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                                <select className='form-control mt-2'
                                    name='acquisiton_type'
                                    onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                                >
                                    <option value={0}>Select an option ...</option>
                                    {
                                        dataAcquisition?.data?.map((el : any, index : any) => (
                                            <option selected={el?.id == formState?.acquisiton_type} value={el?.id} key={index}>{el?.attributes?.Label}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className='form-group required mb-4 p-0'>
                                <label htmlFor=''>Within what timeframe are you looking to close the transaction? </label>
                                {
                                    errorTimeframe && (
                                        <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                                <select className='form-control mt-2'
                                    name='transaction_duration'
                                    onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                                >
                                    <option value={0}>Select an option ...</option>
                                    {
                                        dataTimeframes?.data?.map((el : any, index : any) => (
                                            <option selected={el?.id == formState?.transaction_duration} value={el?.id} key={index}>{el?.attributes?.Label}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className='form-group required mb-4 p-0'>
                                <label htmlFor=''>How would you value your business?</label>
                                {
                                    errorValueBusiness && (
                                        <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                                <div>(Choose "Open to offer" if you don&apos;t have a specific starting price in mind)</div>
                                <select className='form-control mt-2'
                                    name='business_value'
                                    onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                                >
                                    <option value={0}>Select an option ...</option>
                                    {
                                        dataHowValueBusiness?.data?.map((el : any, index : any) => (
                                            <option selected={el?.id == formState?.business_value} value={el?.id} key={index}>{el?.attributes?.Label}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className='text-align-right stepsButtons'>
                            <button className='btn btn-devinsider cancel' onClick={() => props?.showCancelModal()}>
                                Cancel
                            </button>
                            <button className='btn btn-devinsider save' onClick={() => goToStep(props?.currentStep + 1, true)}>
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
            
    );
}
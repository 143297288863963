import { ChangeEvent, useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { generateYearList } from '../../../../../api/seller';
import { useGetStaticParams } from '../../../../../api/staticParams';
import BuyerStep from "../../../../../components/buyer/buyer-step";
import ModalIndustryList from '../../../../../components/modalIndustryList';
import ModalSolutionList from '../../../../../components/modalSolutionList';
import { ICompanyBasicsBuyer } from '../../../../../types/company-basics-buyer';
import { Countries } from '../../../../../widget/countries';
import { renderCountryOptions, renderOptions } from '../../../utils/renderOptions';
import {industriesTextToDisplay, solutionsTextSolutionsToDisplay, solutionsTextToDisplay} from "../../../../../utils/display_text";

type IProps = {
    handleNextStep: (step: number, isGoingNextStep?: boolean) => void;
    showCancelModal: () => void;
    setData: (data: ICompanyBasicsBuyer) => void;
    data: ICompanyBasicsBuyer;
    validateSteps: Array<number>;
    currentStep: number;
    setCurrentStep: (step: number) => void;
    removeStepInValidated: (step: number) => void;
    addStepToValidated: (step: number) => void;
};

export default function CompanyBasicsBuyer(props: IProps) {
    const _ = require('lodash');

    const [formState, setFormState] = useState(props?.data);

    const years = generateYearList();

    const [showPIndustry, setShowPIndustry] = useState(false);
    const [showPSolution, setShowPSolution] = useState(false);
    const [selectedIndustry, setSelectedIndustry] = useState<any[]>(formState?.targeted_industry);
    const [selectedSolution, setSelectedSolution] = useState<any[]>(formState?.software_solution);
    const [selectedFeaturedSolution, setSelectedFeaturedSolution] = useState<any[]>(formState?.featured_solution);

    const [selectedGeographicData, setSelectedGeographicData] = useState<any>({});

    const [enableGetIsvBased, setEnableGetIsvBased] = useState(true);

    const { data: companyHeadCarterData, isFetched: isIsvBasedFetched } = useGetStaticParams('COUNTRIES_SORT_NAME', enableGetIsvBased);

    const [enableGetEmployees, setEnableGetEmployees] = useState(true);
    const { data: employeesData, isFetched: isEmployeesFetched } = useGetStaticParams('EMPLOYEES', enableGetEmployees);

    const [enableGetHorizontals, setEnableGetHorizontals] = useState(true);
    const { data: horizontalsData, isFetched: isHorizontalsFetched } = useGetStaticParams('HORIZONTALS', enableGetHorizontals);

    const [enableGetVerticals, setEnableGetVerticals] = useState(true);
    const { data: dataIndustries, isFetched: isVerticalsFetched } = useGetStaticParams('VERTICALS', enableGetVerticals);

    const [enableGetCountries, setEnableGetCountries] = useState(true);
    const { data: countriesData, isFetched: isCountriesFetched } = useGetStaticParams('COUNTRIES', enableGetCountries);


    useEffect(() => {
        if (isIsvBasedFetched) setEnableGetIsvBased(false);
    }, [isIsvBasedFetched]);


    useEffect(() => {
        if (isEmployeesFetched) setEnableGetEmployees(false);
    }, [isEmployeesFetched]);

    useEffect(() => {
        if (isHorizontalsFetched) setEnableGetHorizontals(false);
    }, [isHorizontalsFetched]);

    useEffect(() => {
        if (isVerticalsFetched) setEnableGetVerticals(false);
    }, [isVerticalsFetched]);

    useEffect(() => {
        if (isCountriesFetched) setEnableGetCountries(false);
    }, [isCountriesFetched]);

    useEffect(() => {
        if (isIsvBasedFetched) setEnableGetIsvBased(false);
    }, [isIsvBasedFetched]);

    const [CompanyWebSiteError, setCompanyWebSiteError] = useState('');

    const [activeId, setActiveId] = useState<any>("-1");

    function toggleActive(id: any) {
        if (activeId === id) {
            setActiveId("null");
        } else {
            setActiveId(id);
        }
    }

    const geographicData = countriesData && countriesData
        ? countriesData.data.reduce((acc: any, current: any) => {
            if (current && current.attributes) {
                const continent = current.attributes.Continent;
                const iso2 = current.attributes.ISO2;
                const name = current.attributes.Name;

                if (continent && iso2 && name) {
                    if (!acc[continent]) {
                        acc[continent] = {
                            Continent: continent,
                            Countries: [{ ISO2: iso2, Name: name }],
                        };
                    } else {
                        acc[continent].Countries.push({ ISO2: iso2, Name: name });
                    }
                }
            }
            return acc;
        }, {})
        : {};

    let allSolutions = [...formState?.software_solution, ...formState?.featured_solution];

    const filteredSolution = (solution: any) => {
        let alreadyExist: Array<string> = [];
        solution?.map((el: any) => {
            let name = horizontalsData?.data?.find((e: any) => e?.id === el)?.attributes?.Label;
            if(alreadyExist.indexOf(name) === -1) {
                alreadyExist.push(name);
            }
        })
        return alreadyExist;
    }

    const txtBtnCat = solutionsTextSolutionsToDisplay(filteredSolution(allSolutions));
    

    const txtBtnIndustry = industriesTextToDisplay(formState?.targeted_industry);

    const addTargetedIndustry = (element: any) => {
        setSelectedIndustry([...selectedIndustry, element?.id]);
    }

    const removeTargetedIndustry = (element: any) => {
        setSelectedIndustry(selectedIndustry?.filter(
            (el: any) => el !== element?.id
        ));
    }

    const addSoftwareSolution = (element: any) => {
        setSelectedSolution([...selectedSolution, element?.id]);
    }

    const addFeaturedSolution = (element: any) => {
        setSelectedFeaturedSolution([...selectedFeaturedSolution, element?.id]);
    }

    const removeSolution = (element: any) => {

        let featuredSolutions = horizontalsData?.data?.filter((el: any) => el?.attributes?.Featured === true);
        let softwareSolutions = horizontalsData?.data?.filter((el: any) => el?.attributes?.Featured === false);

        let foundInFeaturedList = featuredSolutions?.find(
            (el: any) => el?.attributes?.Label == element?.attributes?.Label
        )

        let foundInSolutionList = softwareSolutions?.find(
            (el: any) => el?.attributes?.Label == element?.attributes?.Label
        )

        if(foundInSolutionList)
            setSelectedSolution(selectedSolution?.filter(
                (el: any) => el !== foundInSolutionList?.id
            ));
        
        if(foundInFeaturedList)
            setSelectedFeaturedSolution(selectedFeaturedSolution?.filter(
                (el: any) => el !== foundInFeaturedList?.id
            ));
    }

    const removeItemSelected = (element: any) => {

        let featuredSolutions = horizontalsData?.data?.filter((el: any) => el?.attributes?.Featured === true);
        let softwareSolutions = horizontalsData?.data?.filter((el: any) => el?.attributes?.Featured === false);

        let foundInFeaturedList = featuredSolutions?.find(
            (el: any) => el?.attributes?.Label == element
        )

        let foundInSolutionList = softwareSolutions?.find(
            (el: any) => el?.attributes?.Label == element
        )

        if(foundInSolutionList)
            setSelectedSolution(selectedSolution?.filter(
                (el: any) => el !== foundInSolutionList?.id
            ));
        
        if(foundInFeaturedList)
            setSelectedFeaturedSolution(selectedFeaturedSolution?.filter(
                (el: any) => el !== foundInFeaturedList?.id
            ));
    }

    const [briefError, setBriefError] = useState('');
    const [companyHeadCarterError, setCompanyHeadCarterError] = useState('');
    const [employeError, setEmployeError] = useState('');
    const [yearError, setYearError] = useState('');
    const [geographicSalesError, setGeographicSalesError] = useState('');
    const [industryError, setIndustryError] = useState('');
    const [solutionError, setSolutionError] = useState('');


    const validTargetedIndustry = () => {
        updateState('targeted_industry', selectedIndustry);
        setShowPIndustry(false);
    }

    const validSoftwareSolution = () => {
        updateState('software_solution', selectedSolution);
        updateState('featured_solution', selectedFeaturedSolution);
        setShowPSolution(false);
    }

    const backTarget = () => {
        setSelectedIndustry(formState?.targeted_industry);
        setShowPIndustry(false);
    }

    const backSolution = () => {
        setSelectedSolution(formState?.software_solution);
        setSelectedFeaturedSolution(formState?.featured_solution);
        setShowPSolution(false);
    }

    const updateState = (property: string, value: any) => {
        setFormState((prevState: any) => ({
            ...prevState,
            [property]: value,
        }));
    }

    const validateForm = () => {
        let hasError = false;

        if (!formState?.brief) {
            setBriefError(' ERROR This field is required');
            hasError = true;
        } else {
            setBriefError('');
        }
        if (formState?.companywebsite) {
            const urlPattern = /^(www\.)?[a-zA-Z0-9_-]+(\.[a-zA-Z]{2,6})+$/;

            if (!urlPattern.test(formState?.companywebsite)) {
                setCompanyWebSiteError(' ERROR Please enter a valid website format (e.g., www.example.com)');
                hasError = true;
            } else {
                setCompanyWebSiteError('');
            }
        }


        // if (selectedCompanyHeadquarters.length === 0) {
        //     setCompanyHeadCarterError(' ERROR This field is required');
        //     hasError = true;
        // }
        if (!formState?.companyHeadCarter) {
            setCompanyHeadCarterError(' ERROR This field is required');
            hasError = true;
        } else {
            setEmployeError('');
        }


        if (Number(formState?.employe) === 0) {
            setEmployeError(' ERROR This field is required');
            hasError = true;
        } else {
            setEmployeError('');
        }


        if (!formState?.founded) {
            setYearError(' ERROR This field is required');
            hasError = true;
        } else {
            setYearError('');
        }


        if (Object.keys(selectedGeographicData).length === 0) {
            setGeographicSalesError(' ERROR This field is required');
            hasError = true;
        } else {
            setGeographicSalesError('');
        }
        if (formState?.targeted_industry.length === 0) {
            setIndustryError(' ERROR This field is required');
            hasError = true;
        }

        if (filteredSolution(allSolutions).length < 1) {
            setSolutionError(' ERROR This field is required');
            hasError = true;
        }


        return hasError;
    }

    useEffect(() => {
        if (formState?.geographic_sales_footprint) {
            setSelectedGeographicData(formState.geographic_sales_footprint);
        }
    }, [formState]);

    const goToNextStep = (step: number, isGoingNextStep: boolean) => {
        const stepHasErrors = validateForm();

        if (stepHasErrors) {
            if (isGoingNextStep) props?.removeStepInValidated(props?.currentStep);
            return;
        }

        props?.addStepToValidated(props?.currentStep);
        props?.setData(formState);
        props?.handleNextStep(step, isGoingNextStep);
    }

    useEffect(() => {
        if (formState?.brief && formState?.brief.trim() !== '') {
            setBriefError('');
        }

        if (formState?.companywebsite) {
            const urlPattern = /^(www\.)?[a-zA-Z0-9_-]+(\.[a-zA-Z]{2,6})+$/;

            if (urlPattern.test(formState?.companywebsite)) {
                setCompanyWebSiteError('');
            }
        }
        if (Number(formState?.employe) !== 0) {
            setEmployeError('');
        }
        if (formState?.targeted_industry.length > 0) {
            setIndustryError('');

        }
        if (filteredSolution(allSolutions).length > 0) {
            setSolutionError('');
        }
        if (formState?.founded !== undefined && formState?.founded !== 'Select an option...' && formState?.founded !== '') {
            setYearError('');
        }

        if (Object.keys(selectedGeographicData).length > 0) {
            setGeographicSalesError('');
        }
        if (formState?.companyHeadCarter !== undefined && formState?.companyHeadCarter !== 'Select an option...' && formState?.companyHeadCarter !== '') {
            setCompanyHeadCarterError(' ');
        }
    }, [selectedGeographicData, formState?.brief, formState?.companywebsite, formState?.employe, formState?.companyHeadCarter, selectedIndustry.length, selectedSolution.length, selectedFeaturedSolution.length, formState?.founded, formState?.targeted_industry.length, filteredSolution, allSolutions]);

    return (
        <>
            <BuyerStep
                validatedSteps={props?.validateSteps}
                currentStep={props?.currentStep}
                handleContinue={goToNextStep}
                setCurrentStep={props?.setCurrentStep}
            />
            <div className='contentUser acquisition'>
                <div className='contentBorder row'>
                    <div className='form-group required mb-4 p-0'>
                        <label htmlFor='Brief'>Outline a brief overview of your company </label>
                        {
                            briefError && (
                                <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                    <span className='error-red'> ERROR </span>{" "}
                                    <span className='txt-error'>This field is required</span>
                                </div>
                            )
                        }
                        <textarea
                            id='Brief'
                            name='brief'
                            className='form-control mt-2'
                            maxLength={450}
                            value={formState?.brief}
                            onChange={(e) => {
                                updateState(e.target.name, e.target.value);
                            }}
                        />

                    </div>
                    <div className='form-group not-required mb-4 p-0'>
                        <label htmlFor=''>What is your company name? </label>
                        <input maxLength={100}
                            value={formState?.companyname}
                            name='companyname'
                            onChange={(e) => {
                                updateState(e.target.name, e.target.value);
                            }} className='form-control mt-2' type="text" required id="" />
                    </div>
                    <div className='form-group not-required mb-4 p-0'>
                        <label htmlFor=''>What is your website?</label>

                        <input maxLength={100}
                            value={formState?.companywebsite}
                            onChange={(e) => {
                                updateState('companywebsite', e.target.value);
                            }} className='form-control mt-2' type="text" required name="companywebsite" id="" />
                        {
                            CompanyWebSiteError && (
                                <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                    <span className='error-red'> {CompanyWebSiteError} </span>{" "}
                                </div>
                            )
                        }
                    </div>
                    <div className='form-group required mb-4 p-0'>
                        <label htmlFor=''>Where is your company headquartered?</label>
                        {
                            companyHeadCarterError && (
                                <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                    <span className='error-red'> {companyHeadCarterError}</span>{" "}
                                </div>
                            )
                        }
                        <select className='form-control mt-2' value={formState?.companyHeadCarter} name='companyHeadCarter' onChange={(e) => { updateState(e.target.name, e.target.value); }}>
                            {renderCountryOptions(companyHeadCarterData?.data)}
                        </select>

                    </div>
                    <div className='form-group required mb-4 p-0'>
                        <label htmlFor=''>How many people does your company employ? </label>
                        {
                            employeError && (
                                <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                    <span className='error-red'> ERROR </span>{" "}
                                    <span className='txt-error'>This field is required</span>
                                </div>
                            )
                        }
                        <select className='form-control mt-2' value={formState?.employe} name='employe' onChange={(e) => { updateState(e.target.name, e.target.value); }}>
                            {renderOptions(employeesData?.data)}
                        </select>

                    </div>
                    <div className='form-group required mb-4 p-0'>
                        <label htmlFor=''>In what year was your business founded? </label>
                        {
                            yearError && (
                                <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                    <span className='error-red'> ERROR </span>{" "}
                                    <span className='txt-error'>This field is required</span>
                                </div>
                            )
                        }
                        <select
                            className='form-control mt-2'
                            name='founded'
                            onChange={(e) => { updateState(e.target.name, e.target.value); }}
                            value={formState?.founded}
                        >
                            <option value="">Select an option ...</option>
                            {
                                years?.map((el: any, index: any) => (
                                    <option key={index} value={el}>{el}</option>
                                ))
                            }
                        </select>

                    </div>
                    <div className="form-group required pb-4 px-0 selectSol">
                        <label className='mb-2' htmlFor=''>What vertical industry(ies) do you serve? </label>
                        {
                            industryError && (
                                <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                    <span className='error-red'> ERROR </span>{" "}
                                    <span className='txt-error'>This field is required</span>
                                </div>
                            )
                        }
                        <div className="d-flex mb-1 w-100 align-items-center">
                            <div className="btn btn-devinsider m-0" onClick={() => setShowPIndustry(true)}>{txtBtnIndustry}</div>
                            <div className="listOf more">
                                {' '}
                                <div className='topicAds'>
                                    {formState?.targeted_industry?.map((el: any, key: number) => (
                                        <span className='topic' key={key}>{dataIndustries?.data?.find((element: any) => element?.id === el)?.attributes?.Label}</span>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="form-group required selectSol p-0">
                        <label className='mb-2' htmlFor=''>What horizontal solution(s) do you provide? </label>
                        {
                            solutionError && (
                                <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                    <span className='error-red'> ERROR </span>{" "}
                                    <span className='txt-error'>This field is required</span>
                                </div>
                            )
                        }
                        <div className="d-flex mb-1 w-100 mb-3 align-items-center">
                            <div className="btn btn-devinsider m-0" onClick={() => setShowPSolution(true)}>{txtBtnCat}</div>
                            <div className="listOf more">
                                {' '}
                                <div className='topicAds'>
                                    {filteredSolution(allSolutions)?.filter((value, index, self) => self.indexOf(value) === index)
                                        ?.map((el: any, key: number) => (
                                            <span className='topic' key={key}>{el}</span>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='form-group required mb-4 p-0'>
                        <label htmlFor=''>What is your geographic sales footprint? </label>
                        {
                            geographicSalesError && (
                                <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                    <span className='error-red'> ERROR </span>{" "}
                                    <span className='txt-error'>This field is required</span>
                                </div>
                            )
                        }
                        <span className="selectalloverstyle">Select all countries where you sell your solution</span>

                        <div className="geographicList">
                            <Accordion defaultActiveKey={activeId}>
                                <Countries
                                    geographicData={geographicData}
                                    activeId={activeId}
                                    toggleActive={toggleActive}
                                    data={formState.geographic_sales_footprint}
                                    stateName={"geographic_sales_footprint"}
                                    updateState={updateState}
                                    isEdit={false}
                                />
                            </Accordion>

                        </div>
                    </div>
                </div>
                <div className='text-align-right stepsButtons'>
                    <button className='btn btn-devinsider cancel' onClick={() => props?.showCancelModal()}>
                        Cancel
                    </button>
                    <button className='btn btn-devinsider save' onClick={() => goToNextStep(props?.currentStep + 1, true)}>
                        Finish
                    </button>
                </div>
            </div>
            
            <ModalIndustryList
                show={showPIndustry}
                cancel={backTarget}
                addIndustry={addTargetedIndustry}
                removeIndustry={removeTargetedIndustry}
                industrySelected={selectedIndustry}
                valid={validTargetedIndustry}
            />
            <ModalSolutionList
                show={showPSolution}
                cancel={backSolution}
                dataSolutions={horizontalsData?.data}
                addSoftwareSolution={addSoftwareSolution}
                solutionSelected={selectedSolution}
                valid={validSoftwareSolution}
                addFeaturedSolution={addFeaturedSolution}
                featuredSolutionSelected={selectedFeaturedSolution}
                removeItem={removeItemSelected}
                removeSolution={removeSolution}
            />
        </>
    );
}
import ISO3166 from 'iso-3166-1-alpha-2';
import { SetStateAction, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { UseAddToFavorite, useGetUserSubscription, UseGetMyBuyerMatchingListing, useGetMyBuyerListing } from "../../../../api/buyer";
import { useGetTypeUser } from "../../../../api/user";
import { useKeycloakHooks } from "../../../../hooks/keycloak";
import BuyerSecondMenu from '../../../../layouts/second-headers/buyer';
import { LoaderCommon } from "../../../../widget/loader";
import '../../buyer.scss';
import { getRoleDisplayName } from '../../../../utils/role-mapping';
import { addToArray, paginate } from '../../../../utils/outils';
import SeeBtn from '../../../../components/seeBtn';
import Lock from "../../../../resources/images/lock.svg";
import { useMutation, useQuery } from 'react-query';
import { useGetHowManyPeopleEmployed, useGetHowValueBusiness, useGetLifecycleStage, useGetMainValueBusiness, UseGetMySellerListing, useGetProfitability } from '../../../../api/seller';
import { useGetStaticParams } from '../../../../api/staticParams';
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";

type IdSellerType = string;
interface SellerData {
    id: string;
    matching: number;
    isFavorite: boolean;
    isUnlock: boolean;
    seller: {
        id: number;
        stage: string;
        company_reason: string;
        location: string;
        founded: string;
        company_size: string;
        value_driver: string;
        valuation: string;
        profitability: string;
        targeted_industry: string[];
        software_solution: string[];
        featured_solution: string[];
    }


}


export default function Favorite() {
    const navigate = useNavigate();

    const { keycloak, tokenInfo, initialized } = useKeycloakHooks();

    const [enableCheckSubscription, setEnabledCheckSubscription] = useState(true);
    const [enabledGetMyBuyerListing, setEnabledGetMyBuyerListing] = useState(true);
    const [enabledGetMySellerMatchingListing, setEnabledGettMySellerMatchingListing] = useState(true);
    const { data: checkSubscription, refetch: refetchCheck, isLoading } = useGetUserSubscription(enableCheckSubscription);

    const [enabledMySellerListing, setEnabledMySellerListing] = useState(false);
    const { data: sellerListing } = UseGetMySellerListing(enabledMySellerListing);

    const [enabledGetBuyerListing, setEnabledGetBuyerListing] = useState(false);
    const { data: buyerListing } = useGetMyBuyerListing(enabledGetBuyerListing);


    const [savedplanType, setplanType] = useState<number>(0);

    const [enabled, setEnabled] = useState(false);
    const { data: userRoles, isLoading: userRolesLoading } = useGetTypeUser(tokenInfo, enabled);

    const [enableData, setEnableData] = useState(true);
    const { data: horizontals, isFetched: isHorizontalsFetched } = useGetStaticParams('HORIZONTALS', enableData);
    const { data: verticals, isFetched: isVerticalsFetched } = useGetStaticParams('VERTICALS', enableData);
    const { data: dataLifecycle, isFetched: isStageFetched } = useGetLifecycleStage(enableData);
    const { data: dataHowManyPeople, isFetched: isCompanySizeFetched } = useGetHowManyPeopleEmployed(enableData);
    const { data: dataMainValueBusiness, isFetched: isMainValueFetched } = useGetMainValueBusiness(enableData);
    const { data: dataHowValueBusiness, isFetched: isValuationFetched } = useGetHowValueBusiness(enableData);
    const { data: dataProfitabilityBusiness, isFetched: isProfitabilityFetched } = useGetProfitability(enableData);

    
    const [params, setParams] = useState({
        location: [] as string[],
        horizontal: [] as string[],
        vertical: [] as string[],
    });



    const mutGetMatching = useMutation({
        mutationFn: UseGetMyBuyerMatchingListing,
        onSuccess: (data) => {
            if (data.status === 200) {
                refetchCheck();
            } else {
                refetchCheck();
            }
        }
    })

    const fetchMyMatching = async (savedplanType: any,params:any): Promise<any> => {
        const dataParam = {
            planType: savedplanType,
            params:params
        }
        const response = await mutGetMatching.mutateAsync(dataParam);
        return response;
    }



    useEffect(() => {
        if (checkSubscription && checkSubscription.data) {
            let subscription = checkSubscription.data.subscription;
            if (subscription) {
                const planType = subscription.type.name === "MOTIVATED BUYER" ? 0 : 1;
                setplanType(planType);
            }
        }
    }, [checkSubscription]);

    useEffect(() => {
        if (isHorizontalsFetched && isVerticalsFetched && isStageFetched &&
            isCompanySizeFetched && isMainValueFetched && isValuationFetched &&
            isProfitabilityFetched
        ) {
         setEnableData(false)
        }
    }, [isHorizontalsFetched, isVerticalsFetched, isStageFetched,
        isCompanySizeFetched, isMainValueFetched, isValuationFetched,
        isProfitabilityFetched
    ]);


    const useMyBuyerMatchingListing = (savedplanType: any, enabled: boolean) => { 
        const { data, isFetched, refetch, isLoading } = useQuery(
            ['myBuyerMatching', savedplanType],
            () => fetchMyMatching(savedplanType, {}),
            {
                enabled: enabled
            }
        );
        return {
            data: data,
            isFetched: isFetched,
            refetch: refetch,
            isLoading: isLoading
        };
    };
    

    const { data: myBuyerListing, isFetched: isMyBuyerListingFetched, isLoading: loadingDataMatching, refetch: refecthMyBuyerListing } = useMyBuyerMatchingListing(savedplanType, enabledGetMyBuyerListing);



    useEffect(() => {
        if (tokenInfo?.user_uuid) {
            setEnabled(true);
            setEnabledMySellerListing(true);
            setEnabledGetBuyerListing(true);
        } 
        if (userRoles && myBuyerListing) {
            setEnabledGetMyBuyerListing(false); 
            setEnabled(false);
        } 
    }, [tokenInfo?.user_uuid, userRoles])

    useEffect(() => {
        if (sellerListing) {
            setEnabledMySellerListing(false);
        }
    }, [sellerListing])

    useEffect(() => {
        if (buyerListing) {
            setEnabledGetBuyerListing(false);
        }
    }, [buyerListing])

    const [uniqueLocations, setUniqueLocations] = useState<string[]>([]);
    const [uniqueIndustries, setUniqueIndustries] = useState<string[]>([]);
    const [uniqueSolutions, setUniqueSolutions] = useState<string[]>([]);
    const [haveFavorite, sethaveFavorite] = useState<boolean[]>([]);


    useEffect(() => {
        if (isMyBuyerListingFetched) {
            const favoriteListings = myBuyerListing?.data.filter((item: any) => item.isFavorite === true);
            setUniqueLocations(Array.from(new Set(favoriteListings.map((item: any) => item.seller.location))));
            setUniqueIndustries(Array.from(new Set(favoriteListings.flatMap((item: any) => item.seller.targeted_industry))));
            setUniqueSolutions(Array.from(new Set(favoriteListings.flatMap((item: any) => [...item.seller.software_solution, ...item.seller.featured_solution]))));
            sethaveFavorite(favoriteListings);
        }
    }, [isMyBuyerListingFetched, myBuyerListing]);

    const handleGoToFavorite = () => {
        if (keycloak?.authenticated) { }
        else keycloak?.login();
    }

    // const goToDetails = () => {
    //     myBuyerListing && navigate(`/buyer/listing/details/${myBuyerListing.id}`);
    // };

    const [limitSolution, setLimitSolution] = useState(6);
    const [limitIndustry, setLimitIndustry] = useState(6);
    const [limitLocation, setLimitLocation] = useState(6);
    const [selectedSolution, setSelectedSolution] = useState<Array<string>>([]);
    const [selectedIndustry, setSelectedIndustry] = useState<Array<string>>([]);
    const [selectedLocation, setSelectedLocation] = useState<Array<string>>([]);
    const [searchInput, setSearchInput] = useState<string>('');



    const [filteredDatas, setFilteredDatas] = useState<Array<any>>([]);

    // const uniqueLocations = Array.from(new Set(myBuyerListing.map((item: any) => item.seller.location)));

    // const uniqueIndustries = Array.from(new Set(myBuyerListing.flatMap((item: any) => item.seller.targeted_industry)));

    // const uniqueSolutions = Array.from(new Set(myBuyerListing.flatMap((item: any) => [...item.seller.software_solution, ...item.seller.featured_solution])));



    const [isLocationOpen, setIsLocationOpen] = useState(true);
    const [isSolutionOpen, setIsSolutionOpen] = useState(true);
    const [isIndustryOpen, setIsIndustryOpen] = useState(true);

    const toggleFilterSection = (setState: React.Dispatch<React.SetStateAction<boolean>>) => {
        setState(prevState => !prevState);
    };



    const getUniqueSolutionLabels = () => {
        return Array.from(new Set(
          uniqueSolutions?.map((el: any) => 
            horizontals?.data?.find((element: any) => el == element?.id)?.attributes?.Label
          )
        ));
    };

    const handleSeeSolution = (action: string) => {
        const uniqueLabelsCount = getUniqueSolutionLabels()?.length;
        setLimitSolution(action === '0' ? uniqueLabelsCount : 6);
    };

    const handleSeeIndustry = (action: string) => {
        setLimitIndustry(action === '0' ? uniqueIndustries.length : 6);
    };

    const handleSeeLocation = (action: string) => {
        setLimitLocation(action === '0' ? uniqueLocations.length : 6);
    }

    const handleSelectSolution = (element: any) => {
        setSelectedSolution(addToArray(selectedSolution, element));
    }

    const handleSelectIndustry = (element: string) => {
        setSelectedIndustry(addToArray(selectedIndustry, element));
    }

    const handleSelectLocation = (element: any) => {
        setSelectedLocation(addToArray(selectedLocation, element));
    }

    const hasFilter = uniqueLocations.length > 0 && uniqueSolutions.length > 0 && uniqueIndustries.length > 0 && haveFavorite.length > 0 && userRoles;

    let shouldDisplayBlock = false;

    if (hasFilter) {
        shouldDisplayBlock = true;
    }
    const goToBuyerListing = () => {
        navigate("/buyer/listing");
    };

    const goToSellerListing = () => {
        navigate("/seller/listing");
    };
    const resetSellerListing = () => {
        let param = {
            location: [],
            horizontal: [],
            vertical: [],
        }
        setSearchInput('');
        setSelectedSolution([]);
        setSelectedLocation([]);
        setSelectedIndustry([]);

        setParams((prevParams) => ({
            ...prevParams,
            ...param
        }));
    }
    const goToDetails = (id: any) => {
        navigate(`/seller/listing/detail/matching/${id}`);
    };


    const isOpportunistic = (el: any) => {
        var isOppo = false;

        if (el?.urgency === "Opportunistic" || el?.reason_listing === "I'm opportunistic and open to receive inquiries from potential buyers") {
            isOppo = true
        }

        return isOppo;
    }
    const isPremium = (el: any) => {
        return el?.user_account?.is_premium === true;
    };


    const [sortedListings, setSortedListings] = useState([]);

    useEffect(() => {
        if (myBuyerListing && myBuyerListing?.data) {
            const premiumListings = myBuyerListing?.data.filter((el: any) => isPremium(el?.seller));
            const opportunisticListings = myBuyerListing?.data.filter((el: any) => !isPremium(el?.seller));
            const sortedListings = premiumListings.concat(opportunisticListings);
            setSortedListings(sortedListings);
        }
    }, [myBuyerListing]);


    const filterBuyerListing = async () => {
        const param = {
            location: selectedLocation,
            horizontal: selectedSolution,
            vertical: selectedIndustry
        };
        const response = await fetchMyMatching(savedplanType, param);
        const data = response.data;
        

        const finalFilteredData = data.length > 0 ? data : [];
        const favoriteListings = finalFilteredData.filter((item: any) => item.isFavorite === true);
        setFilteredDatas(favoriteListings);
    }



    const handleSearch = () => {
        if (searchInput.trim() !== '') {
            const favoriteListings = sortedListings.filter((item: any) => item.isFavorite === true);
            const filteredData = favoriteListings.filter((el: any) => {
                const country = ISO3166.getCountry(el?.seller.location);
                const locationMatches = country && country.toLowerCase().includes(searchInput.toLowerCase());
                const founded = el?.seller.founded.toLowerCase().includes(searchInput.toLowerCase());
                const stage = el?.seller.stage.toLowerCase().includes(searchInput.toLowerCase());
                const reason = el?.seller.company_reason.toLowerCase().includes(searchInput.toLowerCase());
                const valueDriver = el?.seller.value_driver.toLowerCase().includes(searchInput.toLowerCase());
                const companySize = el?.seller.company_size.toLowerCase().includes(searchInput.toLowerCase());
                const valueAtion = el?.seller.valuation.toLowerCase().includes(searchInput.toLowerCase());
                const profbility = el?.seller.profitability.toLowerCase().includes(searchInput.toLowerCase());
                return locationMatches || founded || stage || reason || valueDriver || companySize || valueAtion || profbility;
            });

            return filteredData || [];
        }

        return [];
    };



    const mutAddToFavorite = useMutation({
        mutationFn: UseAddToFavorite,
        onSuccess: (data) => {
            if (data.status === 200) {
                refecthMyBuyerListing();
            } else {
                refecthMyBuyerListing();
            }
        }
    })

    const toggleFavorite = async (itemId: any): Promise<any> => {
        const dataParam = {
            idBuyer: itemId,
        }
        const response = await mutAddToFavorite.mutateAsync(dataParam);
        return response;
    }

    const goToBuyerFavorite = () => {
        if(buyerListing && buyerListing?.is_published && !buyerListing?.is_removed)
            navigate("/buyer/listing/my-favorite");
    };

    const goToSellerFavorite = () => {
        if(sellerListing && sellerListing?.seller && sellerListing?.seller?.is_published && !sellerListing?.seller?.is_removed)
            navigate("/seller/listing/my-favorite");
    };


    const renderListItem = (item: any) => (
        (item?.is_removed !== true && item?.is_published !== false && item?.isFavorite !== false) && (
            <li key={item.id} className={`list ${isOpportunistic(item.seller) ? 'opportunistic' : ''} ${isPremium(item.seller) ? 'premium' : ''}`}>
                <div>
                    <div className="logo">
                        <span className="icon-building1"></span>
                    </div>
                    {savedplanType === 0 && (
                        <div className='match'>
                            <span>match</span>
                            <span>{item?.matching < 60 ? '<60' : item?.matching}%</span>
                        </div>
                    )}
                </div>
                <div className="desc">
                    <h2 className="title">
                        {dataLifecycle?.data?.find((element: any) => element?.id == item?.seller?.stage)?.attributes?.Label}  
                        <OverlayTrigger
                            overlay={
                                <Tooltip className="custom-tooltip">
                                    { !item?.isFavorite ? "Click here to bookmark the listing" : "Click here to remove bookmark" }
                                </Tooltip>
                            }
                            >
                            <span style={{cursor: 'pointer'}} className={`icon-star-${item?.isFavorite ? 'on' : 'off'}`} onClick={() => toggleFavorite(item.id)}></span>
                        </OverlayTrigger>
                    </h2>
                    <div className="about"><span>{item.seller?.company_reason}</span></div>
                    <a className="btn btn-devinsider" href="javascript:;" onClick={() => goToDetails(item?.seller?.id)}>More details</a>
                    <ul>
                        <li className="item" key={`${item.id}_location`}>
                            <div>Location</div>
                            <span className="ico icon-location"></span>
                            <span className="txt">{ISO3166.getCountry(item.seller?.location)}</span>
                        </li>
                        <li className="item" key={`${item.id}_founded`}>
                            <div>Founded</div>
                            <span className="ico icon-founded"></span>
                            <span className="txt">{item.seller?.founded}</span>
                        </li>
                        <li className="item" key={`${item.id}_companySize`}>
                            <div>Company size</div>
                            <span className="ico icon-compSize"></span>
                            <span className="txt">{dataHowManyPeople?.data?.find((element: any) => element?.id == item?.seller?.company_size)?.attributes?.Label}</span>
                        </li>
                        <li className="item" key={`${item.id}_valueDriver`}>
                            <div>Top value driver</div>
                            <span className="ico icon-topValue"></span>
                            <span className="txt">{dataMainValueBusiness?.data?.find((element: any) => element?.id == item?.seller?.value_driver)?.attributes?.Label}</span>
                        </li>
                        <li className="item" key={`${item.id}_valuation`}>
                            <div>Valuation</div>
                            <span className="ico icon-valuation"></span>
                            <span className="txt">{dataHowValueBusiness?.data?.find((element: any) => element?.id == item?.seller?.valuation)?.attributes?.Label}</span>
                        </li>
                        <li className="item" key={`${item.id}_profitability`}>
                            <div>Profitability</div>
                            <span className="ico icon-profitability"></span>
                            <span className="txt">{dataProfitabilityBusiness?.data?.find((element: any) => element?.id == item?.seller?.profitability)?.attributes?.Label}</span>
                        </li>
                    </ul>
                </div>
            </li>
        )
    );

    const renderSellerList = () => {
        if (loadingDataMatching) {
            return (
                <div className='loaderCommon'>
                    <LoaderCommon />
                </div>
            );
        }
        
        if (!loadingDataMatching && myBuyerListing) {
            if (searchInput.trim() !== '') {
                const searchResults = handleSearch();
                return searchResults.length > 0 
                    ? searchResults.map(renderListItem)
                    : <li className="no-data-message">No results found.</li>;
            }
    
            if (filteredDatas && filteredDatas.length > 0) {
                return filteredDatas.map(renderListItem);
            }
    
            if (sortedListings && sortedListings.length > 0) {
                const favoriteListings = sortedListings.filter((item: SellerData) => item.isFavorite === true);
                return favoriteListings.length > 0
                    ? favoriteListings.map(renderListItem)
                    : <li className="no-data-message">No results found.</li>;
            }
        }
    };

    return (
        <>
            {(initialized && !keycloak?.authenticated) && <Navigate to="/" />}
            {(initialized && keycloak?.authenticated) &&
                <div style={{ paddingTop: "8rem" }} id="my-listing" className="findIsvcontainer programContainer sellerContainer my-listing">
                    <BuyerSecondMenu
                        showSearch={true}
                        searchInput={searchInput}
                        setSearchInput={setSearchInput}
                        handleSearch={handleSearch}
                        handleGoToFavorite={handleGoToFavorite}
                    />

                    <div className="filterFindIsv leftTop hScroll">

                        <div className="contentFilter">
                            <div className="block sellerBuyer pb-0 border-0">
                                <div className="sellers active" onClick={() => goToBuyerFavorite()}>
                                    <a href="javascript:;">
                                        <span className="icon-sellers"></span>Sellers
                                    </a>
                                </div>
                                <div className="buyer" onClick={() => goToSellerFavorite()}>
                                    <a href="javascript:;">
                                        <span className="icon-buyerList"></span>Buyers
                                    </a>
                                </div>
                            </div>
                            {
                                shouldDisplayBlock ? (
                                    <>
                                        <div className="containerBtn block infoProgramContainer border-0">
                                            <button className="btn btn-devinsider cancel" onClick={() => filterBuyerListing()}>Filter</button>
                                            <a className="resetFilter" onClick={() => resetSellerListing()}>
                                                <span className="icon-refresh" />
                                                Reset filter
                                            </a>
                                        </div>
                                        <div className="block">
                                            <h3 className={`title withChild ${isLocationOpen ? 'open' : 'closed'}`} onClick={() => toggleFilterSection(setIsLocationOpen)}>HQ location</h3>
                                            <div className="listCheck">
                                                {
                                                    paginate(uniqueLocations, limitLocation)?.sort((a, b) => {
                                                        const countryA = ISO3166.getCountry(a) || "";
                                                        const countryB = ISO3166.getCountry(b) || "";
                                                        return countryA.localeCompare(countryB);
                                                    })?.map((el: any, index: any) => (
                                                        <div className="custom-control custom-checkbox orange" key={index}>
                                                            <input
                                                                type="checkbox"
                                                                id={`location${index}`}
                                                                className="custom-control-input"
                                                                onChange={() => handleSelectLocation(el)}
                                                                checked={
                                                                    selectedLocation?.find(
                                                                        (selected: any) => selected === el
                                                                    )
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            <label htmlFor={`location${index}`} className="custom-control-label">
                                                                {ISO3166.getCountry(el)}
                                                            </label>
                                                        </div>
                                                    ))
                                                }
                                                <SeeBtn
                                                    dataSource={uniqueLocations}
                                                    limit={limitLocation}
                                                    seeMoreOrLess={(p: string) => handleSeeLocation(p)}
                                                />
                                            </div>
                                        </div>
                                        <div className="block">
                                            <h3 className={`title withChild ${isSolutionOpen ? 'open' : 'closed'}`} onClick={() => toggleFilterSection(setIsSolutionOpen)}>Solution (Horizontal)</h3>
                                            <div className="listCheck">
                                                {
                                                     getUniqueSolutionLabels()
                                                     .slice(0, limitSolution) 
                                                     .map((uniqueLabel: any, index: number) => {
                                                       const el = uniqueSolutions?.find((solutionId: any) => 
                                                         horizontals?.data?.find((element: any) => 
                                                           solutionId == element?.id && element?.attributes?.Label === uniqueLabel
                                                         )
                                                       );

                                                       return(
                                                            <>
                                                                <div className="custom-control custom-checkbox orange" key={index}>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`solutions${index}`}
                                                                        className="custom-control-input"
                                                                        onChange={() => handleSelectSolution(el)}
                                                                        checked={
                                                                            selectedSolution?.find(
                                                                                (selected: any) => selected === el
                                                                            )
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                    <label htmlFor={`solutions${index}`} className="custom-control-label">
                                                                        {horizontals?.data?.find((element: any) => el == element?.id)?.attributes?.Label}
                                                                    </label>
                                                                </div>
                                                            </>
                                                        );
                                                    })
                                                }
                                                <SeeBtn
                                                    dataSource={getUniqueSolutionLabels()}
                                                    limit={limitSolution}
                                                    seeMoreOrLess={(p: string) => handleSeeSolution(p)}
                                                />
                                            </div>
                                        </div>
                                        <div className="block">
                                            <h3 className={`title withChild ${isIndustryOpen ? 'open' : 'closed'}`} onClick={() => toggleFilterSection(setIsIndustryOpen)}>Industry (Vertical)</h3>
                                            <div className="listCheck">
                                                {
                                                    paginate(uniqueIndustries, limitIndustry)?.sort()?.map((el: any, index: any) => (
                                                        <>
                                                            <div className="custom-control custom-checkbox orange" key={index}>
                                                                <input
                                                                    type="checkbox"
                                                                    id={`industries${index}`}
                                                                    className="custom-control-input"
                                                                    onChange={() => handleSelectIndustry(el)}
                                                                    checked={
                                                                        selectedIndustry?.find(
                                                                            (selected: any) => selected === el
                                                                        )
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                <label htmlFor={`industries${index}`} className="custom-control-label">
                                                                    {verticals?.data?.find((element: any) => element?.id == el)?.attributes?.Label}
                                                                </label>
                                                            </div>
                                                        </>
                                                    ))
                                                }


                                            </div>
                                        </div>
                                        <SeeBtn
                                            dataSource={uniqueIndustries}
                                            limit={limitIndustry}
                                            seeMoreOrLess={(p: string) => handleSeeIndustry(p)}
                                        />
                                    </>
                                ) : null
                            }
                        </div>

                    </div>
                    {
                        <div className="container mx-auto minHeightwin sellerRight">
                            <div className="listOfIsv listOfSeller scrollContainer">
                                <ul className="listSeller">
                                    {/* {searchInput.trim() !== '' ? (
                                        handleSearch().map((item: any, index: any) => (
                                            (item?.is_removed !== true && item?.is_published !== false && item?.isFavorite !== false) && (
                                                <li key={item.id} className={`list ${isOpportunistic(item.seller) ? 'opportunistic' : ''} ${isPremium(item.seller) ? 'premium' : ''}`}>
                                                    <div>
                                                        <div className="logo">
                                                            <span className="icon-building1"></span>
                                                        </div>
                                                        {savedplanType === 0 && (
                                                            <div className='match'>
                                                                <span>match</span>
                                                                <span>{item?.matching < 60 ? '<60' : item?.matching}%</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="desc">
                                                        <h2 className="title">
                                                            {item.seller?.stage}  <span className={`icon-star-${item?.isFavorite ? 'on' : 'off'}`} onClick={() => toggleFavorite(item.id)}></span>
                                                        </h2>
                                                        <div className="about"><span>{item.seller?.company_reason}</span></div>
                                                        <a className="btn btn-devinsider" href="javascript:;" onClick={() => goToDetails(item?.seller?.id)}>More details</a>
                                                        <ul>
                                                            <li className="item" key={`${item.id}_location`}>
                                                                <div>Location</div>
                                                                <span className="ico icon-location"></span>
                                                                <span className="txt">{ISO3166.getCountry(item.seller?.location)}</span>
                                                            </li>
                                                            <li className="item" key={`${item.id}_founded`}>
                                                                <div>Founded</div>
                                                                <span className="ico icon-founded"></span>
                                                                <span className="txt">{item.seller?.founded}</span>
                                                            </li>
                                                            <li className="item" key={`${item.id}_companySize`}>
                                                                <div>Company size</div>
                                                                <span className="ico icon-compSize"></span>
                                                                <span className="txt">{item.seller?.company_size}</span>
                                                            </li>
                                                            <li className="item" key={`${item.id}_valueDriver`}>
                                                                <div>Top value driver</div>
                                                                <span className="ico icon-topValue"></span>
                                                                <span className="txt">{item.seller?.value_driver}</span>
                                                            </li>
                                                            <li className="item" key={`${item.id}_valuation`}>
                                                                <div>Valuation</div>
                                                                <span className="ico icon-valuation"></span>
                                                                <span className="txt">{item.seller?.valuation}</span>
                                                            </li>
                                                            <li className="item" key={`${item.id}_profitability`}>
                                                                <div>Profitability</div>
                                                                <span className="ico icon-profitability"></span>
                                                                <span className="txt">{item.seller?.profitability}</span>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                </li>
                                            )))
                                    ) : (
                                        (filteredDatas && filteredDatas.length > 0) ? (
                                            filteredDatas.map((item: any, index: any) => (
                                                (item?.is_removed !== true && item?.is_published !== false && item?.isFavorite !== false) && (
                                                    <li key={item.id} className={`list ${isOpportunistic(item.seller) ? 'opportunistic' : ''} ${isPremium(item.seller) ? 'premium' : ''}`}>
                                                        <div>
                                                            <div className="logo">
                                                                <span className="icon-building1"></span>
                                                            </div>
                                                            {savedplanType === 0 && (
                                                                <div className='match'>
                                                                    <span>match</span>
                                                                    <span>{item?.matching < 60 ? '<60' : item?.matching}%</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="desc">
                                                            <h2 className="title">
                                                                {item.seller?.stage}  <span className={`icon-star-${item?.isFavorite ? 'on' : 'off'}`} onClick={() => toggleFavorite(item.id)}></span>
                                                            </h2>
                                                            <div className="about"><span>{item.seller?.company_reason}</span></div>
                                                            <a className="btn btn-devinsider" href="javascript:;" onClick={() => goToDetails(item?.seller?.id)}>More details</a>
                                                            <ul>
                                                                <li className="item" key={`${item.id}_location`}>
                                                                    <div>Location</div>
                                                                    <span className="ico icon-location"></span>
                                                                    <span className="txt">{ISO3166.getCountry(item.seller?.location)}</span>
                                                                </li>
                                                                <li className="item" key={`${item.id}_founded`}>
                                                                    <div>Founded</div>
                                                                    <span className="ico icon-founded"></span>
                                                                    <span className="txt">{item.seller?.founded}</span>
                                                                </li>
                                                                <li className="item" key={`${item.id}_companySize`}>
                                                                    <div>Company size</div>
                                                                    <span className="ico icon-compSize"></span>
                                                                    <span className="txt">{item.seller?.company_size}</span>
                                                                </li>
                                                                <li className="item" key={`${item.id}_valueDriver`}>
                                                                    <div>Top value driver</div>
                                                                    <span className="ico icon-topValue"></span>
                                                                    <span className="txt">{item.seller?.value_driver}</span>
                                                                </li>
                                                                <li className="item" key={`${item.id}_valuation`}>
                                                                    <div>Valuation</div>
                                                                    <span className="ico icon-valuation"></span>
                                                                    <span className="txt">{item.seller?.valuation}</span>
                                                                </li>
                                                                <li className="item" key={`${item.id}_profitability`}>
                                                                    <div>Profitability</div>
                                                                    <span className="ico icon-profitability"></span>
                                                                    <span className="txt">{item.seller?.profitability}</span>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                    </li>
                                                )))
                                        ) : (
                                            sortedListings && sortedListings.length > 0 ? (
                                                sortedListings.map((item: SellerData, index: number) => (
                                                    item.isFavorite === true ? (
                                                        <li key={item.id} className={`list ${isOpportunistic(item.seller) ? 'opportunistic' : ''} ${isPremium(item.seller) ? 'premium' : ''}`}>
                                                            <div>
                                                                <div className="logo">
                                                                    <span className="icon-building1"></span>
                                                                </div>
                                                                {savedplanType === 0 && (
                                                                    <div className='match'>
                                                                        <span>match</span>
                                                                        <span>{item?.matching < 60 ? '<60' : item?.matching}%</span>
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div className="desc">
                                                                <h2 className="title">
                                                                    {item.seller?.stage}  <span className={`icon-star-${item?.isFavorite ? 'on' : 'off'}`} onClick={() => toggleFavorite(item.id)}></span>
                                                                </h2>
                                                                <div className="about"><span>{item.seller?.company_reason}</span></div>
                                                                <a className="btn btn-devinsider" href="javascript:;" onClick={() => goToDetails(item?.seller?.id)}>More details</a>
                                                                <ul>
                                                                    <li className="item" key={`${item.id}_location`}>
                                                                        <div>Location</div>
                                                                        <span className="ico icon-location"></span>
                                                                        <span className="txt">{ISO3166.getCountry(item.seller?.location)}</span>
                                                                    </li>
                                                                    <li className="item" key={`${item.id}_founded`}>
                                                                        <div>Founded</div>
                                                                        <span className="ico icon-founded"></span>
                                                                        <span className="txt">{item.seller?.founded}</span>
                                                                    </li>
                                                                    <li className="item" key={`${item.id}_companySize`}>
                                                                        <div>Company size</div>
                                                                        <span className="ico icon-compSize"></span>
                                                                        <span className="txt">{item.seller?.company_size}</span>
                                                                    </li>
                                                                    <li className="item" key={`${item.id}_valueDriver`}>
                                                                        <div>Top value driver</div>
                                                                        <span className="ico icon-topValue"></span>
                                                                        <span className="txt">{item.seller?.value_driver}</span>
                                                                    </li>
                                                                    <li className="item" key={`${item.id}_valuation`}>
                                                                        <div>Valuation</div>
                                                                        <span className="ico icon-valuation"></span>
                                                                        <span className="txt">{item.seller?.valuation}</span>
                                                                    </li>
                                                                    <li className="item" key={`${item.id}_profitability`}>
                                                                        <div>Profitability</div>
                                                                        <span className="ico icon-profitability"></span>
                                                                        <span className="txt">{item.seller?.profitability}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                        </li>
                                                    ) : null
                                                ))
                                            ) : (
                                                <li className="no-data-message">"No results found."</li>
                                            )
                                        )
                                    )}*/}
                                {renderSellerList()}
                                </ul> 
                            </div>
                        </div>
                    }
                </div>

            }
        </>

    )
}
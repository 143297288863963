const renderOptions = (data) => {
    return (
        <>
            <option value={0}>Select an option...</option>
            {data && data.map((item) => (
                <option key={item.id} value={item.id}>
                    {item.attributes.Label}
                </option>
            ))}
        </>
    );
};

export { renderOptions };

const renderCountryOptions = (data) => {
    return (
        <>
            <option value="">Select an option...</option>
            {data && data.map((item) => (
                <option key={item.id} value={item.attributes.ISO2}>
                    {item.attributes.Name}
                </option>
            ))}
        </>
    );
};

export { renderCountryOptions };


const renderOptionsMultiple = (data, selectedValues) => {
    return (
        <>
            <option value="">Select an option...</option>
            {data.map((item) => (
                <option
                    key={item.id}
                    value={item.attributes.Label || item.attributes.ISO2}
                    selected={selectedValues.includes(item.attributes.Label || item.attributes.ISO2)}
                >
                    {item.attributes.Label || item.attributes.Name}
                </option>
            ))}
        </>
    );
};

export { renderOptionsMultiple };


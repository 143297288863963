import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useGetStaticParams } from '../api/staticParams';

type IIndustry = {
    attributes : {
        Label: string
    }
}

type IProps = {
    industriesSelected: Array<string>;
    addIndustry: (el: IIndustry) => void;
    removeIndustry: (el: IIndustry) => void;
}

export default function IndustriesList(props: IProps) {
    const [enabled, setEnabled] = useState(true);

    const { data: industries, isFetched: isIndustriesFetched } = useGetStaticParams('VERTICALS', enabled);

    let dataIndustry = industries?.data || [];
    let divider = Math.floor(dataIndustry?.length / 3);

    let firstColumn = [];
    let secondColumn = [];
    let thirdColumn = [];

    if (dataIndustry.length > 0 && divider > 0) {
        firstColumn = dataIndustry.slice(0, divider);
        secondColumn = dataIndustry.slice(divider, divider * 2);
        thirdColumn = dataIndustry.slice(divider * 2);
    }

    const addOrRemoveIndustry = (element: any) => {
        const isElementSelected = props?.industriesSelected?.includes(element?.id);

        if (!isElementSelected) props?.addIndustry(element);
        else props?.removeIndustry(element);
    }

    return (
        <div className='getstartedContainer largeContainer'>
            <div className='contentTargetIndustry'>

            <div className='col4'>
                {
                firstColumn?.map((el: any, index: number) =>(
                    <Form.Check
                    key={index}
                    label={el?.attributes?.Label}
                    id={el?.id}
                    onChange={() => addOrRemoveIndustry(el)}
                    checked={
                        props?.industriesSelected?.find((element: any) =>
                        element === el?.id
                    ) ? true
                        : false
                    }
                    className={`panel-toggle noicon ${props?.industriesSelected?.find((element: any) =>
                        element === el?.id) ? 'checked' : ''}`}
                    />
                ))
                }
            </div>

            <div className='col4'>
                {
                secondColumn?.map((el: any, index: number) => (
                    <Form.Check
                    key={index}
                    label={el?.attributes?.Label}
                    id={el?.id}
                    onChange={() => addOrRemoveIndustry(el)}
                    checked={
                        props?.industriesSelected?.find((element: any) => 
                        element === el?.id
                    ) ? true
                        : false
                    }
                    className={`panel-toggle noicon ${props?.industriesSelected?.find((element: any) =>
                        element === el?.id) ? 'checked' : ''}`}
                    />
                ))
                }
            </div>

            <div className='col4'>
                {
                thirdColumn?.map((el: any, index: number) => (
                    <Form.Check
                    key={index}
                    label={el?.attributes?.Label}
                    id={el?.id}
                    onChange={() => addOrRemoveIndustry(el)}
                    checked={
                        props?.industriesSelected?.find((element: any) =>
                        element === el?.id
                    ) ? true
                        : false
                    }
                    className={`panel-toggle noicon ${props?.industriesSelected?.find((element: any) =>
                        element === el?.id) ? 'checked' : ''}`}
                    />
                ))
                }
            </div>

            </div>
        </div>
    )
}

import { IMAInterest } from "../types/MAInterest";

export const initialMainterest: IMAInterest = {
    opportunity: 0,
    lifecycle: [],
    software_solution: [],
    featured_solution: [],
    targeted_industry:  [],
    isvBased: [],
    ecosystem: [],
    have_technology:null,
};

import { useEffect, useState } from "react";
import { useGetLifecycleStage, useGetMainValueBusiness } from "../../../../../api/seller";
import SellerSteps from "../../../../../components/seller/seller-steps";
import { INecessaryInformations } from "../../../../../types/necessary-informations";

type IProps = {
    handleNextStep: (number: number) => void;
    showCancelModal: () => void;
    setData: (data: INecessaryInformations) => void;
    data: INecessaryInformations;
    validateSteps: Array<number>;
    currentStep: number;
    setCurrentStep: (step: number) => void;
    removeStepInValidated: (step: number) => void;
    addStepToValidated: (step: number) => void;
};

export default function NecessaryInformations(props: IProps) {
    const [formState, setFormState] = useState(props?.data);
    const [enabled, setEnabled] = useState(false);

    const { data: dataLifecycle } = useGetLifecycleStage(enabled);
    const { data: dataMainValueBusiness } = useGetMainValueBusiness(enabled);
    
    const [errorSummarize, setErrorSummarize] = useState(false);
    const [errorLifecycleStage, setErrorLifecycleStage]= useState(false);
    const [errorMainvalue, setErrorMainvalue] = useState(false);
    const [errorKeyAssets, setErrorKeyAssets] = useState(false);
    const [errorTargetAudience, setErrorTargetAudience] = useState(false);
    const [errorBusiness, setErrorBusiness] = useState(false);

    const updateState = (property: string, value: string) => {
        setFormState((prevState: any) => ({
            ...prevState,
            [property]: value,
        }));
    }

    const changeTextAreaValue = (evt: any) => {
        const words = evt.target.value.trim().split(/\s+/);
    
        if (words.length <= 200) {
            updateState(evt.target.name, evt.target.value);
        } else {
            const truncatedContent = words.splice(0, 100).join(' ');
            updateState(evt.target.name, truncatedContent);
        }
    }
    
    const goToStep = (step: number, isGoingNextStep: boolean) => {
        if(formState?.summarize !== "" && Number(formState?.lifecycle_stage) !== 0 && Number(formState?.main_value) !== 0 && formState?.target_audience !== "" && formState?.acquirer_interest !== "" && formState?.key_assets !== "") {
            props?.setData(formState);
            props?.handleNextStep(step);
            props?.addStepToValidated(props?.currentStep);
        }
        else {
            if(isGoingNextStep) {
                if(Number(formState?.lifecycle_stage) === 0)
                    setErrorLifecycleStage(true);
                if(Number(formState?.main_value) === 0)
                    setErrorMainvalue(true);
                if(formState?.target_audience == "")
                    setErrorTargetAudience(true);
                if(formState?.acquirer_interest == "")
                    setErrorBusiness(true);
                if(formState?.key_assets == "")
                    setErrorKeyAssets(true);
                if(formState?.summarize == "")
                    setErrorSummarize(true);
            }
            
            props?.removeStepInValidated(props?.currentStep);

            return;
        }
    }

    useEffect(() => {
        if(props?.currentStep === 3) setEnabled(true);
        if(dataLifecycle && dataMainValueBusiness) setEnabled(false);
    }, [props?.currentStep, dataLifecycle, dataMainValueBusiness]);

    useEffect(() => {
        setErrorSummarize(false);
    }, [formState?.summarize]);

    useEffect(() => {
        setErrorLifecycleStage(false);
    }, [formState?.lifecycle_stage]);

    useEffect(() => {
        setErrorMainvalue(false);
    }, [formState?.main_value]);

    useEffect(() => {
        setErrorKeyAssets(false);
    }, [formState?.key_assets]);

    useEffect(() => {
        setErrorTargetAudience(false);
    }, [formState?.target_audience]);

    useEffect(() => {
        setErrorBusiness(false);
    }, [formState?.acquirer_interest]);

    useEffect(() => {
        props?.setData(formState);
    }, [formState]);

    return (
        <>
            <SellerSteps
                validatedSteps= {props?.validateSteps}
                currentStep= {props?.currentStep}
                handleContinue= {goToStep}
                setCurrentStep= {props?.setCurrentStep}
            />
            <div className='container mx-auto minHeightwin pt-4'>
                <div className="scrollContainer">
                    <div className='contentUser acquisition'>
                    <div className='contentBorder row'>
                        <div className='form-group required mb-4 p-0'>
                            <label htmlFor=''>Summarize your business in 100 words or less</label>
                            {
                                errorSummarize && (
                                    <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                        <span className='error-red'> ERROR </span>{" "}
                                        <span className='txt-error'>This field is required</span>
                                    </div>
                                )
                            }
                            <textarea className='form-control mt-2'
                                name="summarize"
                                value={formState?.summarize}
                                onChange={(evt) => changeTextAreaValue(evt)}
                            />
                        </div>
                        <div className='form-group required mb-4 p-0'>
                            <label htmlFor=''>What stage of the lifecycle is your business in? </label>
                            {
                                errorLifecycleStage && (
                                    <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                        <span className='error-red'> ERROR </span>{" "}
                                        <span className='txt-error'>This field is required</span>
                                    </div>
                                )
                            }
                            <select className='form-control mt-2'
                                name='lifecycle_stage'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            >
                                <option value={0}>Select an option ...</option>
                                {
                                    dataLifecycle?.data?.map((el : any, index : any) => (
                                        <option selected={el?.id == formState?.lifecycle_stage} value={el?.id} key={index}>{el?.attributes?.Label}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='form-group required mb-4 p-0'>
                            <label htmlFor=''>Where does the main value of your business come from? </label>
                            {
                                errorMainvalue && (
                                    <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                        <span className='error-red'> ERROR </span>{" "}
                                        <span className='txt-error'>This field is required</span>
                                    </div>
                                )
                            }
                            <select className='form-control mt-2'
                                name='main_value'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            >
                                <option value={0}>Select an option ...</option>
                                {
                                    dataMainValueBusiness?.data?.map((el : any, index : any) => (
                                        <option selected={el?.id == formState?.main_value} value={el?.id} key={index}>{el?.attributes?.Label}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='form-group required mb-4 p-0'>
                            <label htmlFor=''>Describe your key assets</label>
                            {
                                errorKeyAssets && (
                                    <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                        <span className='error-red'> ERROR </span>{" "}
                                        <span className='txt-error'>This field is required</span>
                                    </div>
                                )
                            }
                            <textarea className='form-control mt-2'
                                maxLength={500}
                                value={formState?.key_assets}
                                name="key_assets"
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            />
                        </div>
                        <div className='form-group required mb-4 p-0'>
                            <label htmlFor=''>Describe your target audience</label>
                            {
                                errorTargetAudience && (
                                    <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                        <span className='error-red'> ERROR </span>{" "}
                                        <span className='txt-error'>This field is required</span>
                                    </div>
                                )
                            }
                            <textarea className='form-control mt-2'
                                maxLength={500}
                                value={formState?.target_audience}
                                name="target_audience"
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            />
                        </div>
                        <div className='form-group not-required mb-4 p-0'>
                            <label htmlFor=''>Who are your top 3 competitors?</label>
                            <textarea className='form-control mt-2'
                                value={formState?.top_competitors}
                                maxLength={500}
                                name="top_competitors"
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            />
                        </div>
                        <div className='form-group required mb-4 p-0'>
                            <label htmlFor=''>Describe in 100 words or less why an acquirer should be interested in your business</label>
                            {
                                errorBusiness && (
                                    <div className="container-error pl-2  d-inline" style={{color: 'red'}}>
                                        <span className='error-red'> ERROR </span>{" "}
                                        <span className='txt-error'>This field is required</span>
                                    </div>
                                )
                            }
                            <textarea className='form-control mt-2'
                                value={formState?.acquirer_interest}
                                name="acquirer_interest"
                                onChange={(evt) => changeTextAreaValue(evt)}
                            />
                        </div>
                    </div>
                    <div className='text-align-right stepsButtons'>
                        <button className='btn btn-devinsider cancel' onClick={() => props?.showCancelModal()}>
                            Cancel
                        </button>
                        <button className='btn btn-devinsider save' onClick={() => goToStep(props?.currentStep + 1, true)}>
                            Continue
                        </button>
                    </div>
                </div>
                </div>
            </div>
        </>
    );
}
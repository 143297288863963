import ISO3166 from 'iso-3166-1-alpha-2';
import { Base64 } from 'js-base64';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useGetMyBuyerListing } from '../../../../api/buyer';
import { UseGetMySellerListing } from '../../../../api/seller';
import { useGetTypeUser } from '../../../../api/user';
import { useKeycloakHooks } from '../../../../hooks/keycloak';
import BuyerSecondMenu from '../../../../layouts/second-headers/buyer';
import { getRoleDisplayName } from '../../../../utils/role-mapping';
import '../../buyer.scss';
import { urlsApi } from "../../../../api/urls/apiUrl";
import { useGetStaticParams } from '../../../../api/staticParams';

export default function BuyerMyListing() {
    const navigate = useNavigate();

    const { keycloak, tokenInfo, initialized } = useKeycloakHooks();

    const [enabledGetMyBuyerListing, setEnabledGetMyBuyerListing] = useState(false);
    const { data: myBuyerListing, isFetched: isMyBuyerListingFetched } = useGetMyBuyerListing(enabledGetMyBuyerListing);

    const [enabledMySellerListing, setEnabledMySellerListing] = useState(false);
    const { data: mySellerListing, isLoading: mySellerListingsLoading, isFetched: isMySellerListingFetched } = UseGetMySellerListing(enabledMySellerListing);

    const [enabled, setEnabled] = useState(false);
    const { data: userRoles } = useGetTypeUser(tokenInfo, enabled);

    const [enabledData, setEnabledData] = useState(true);
    const { data: mainAcquisition, isFetched: isMainAcquisitionsFetched } = useGetStaticParams('MAIN_PURPOSES', enabledData);
    
    const goToDetails = () => {
        myBuyerListing && navigate(`/buyer/listing/details/${myBuyerListing.id}`);
    };

    const goToMySellerListing = () => {
        console.log(mySellerListing);
        if (userRoles && userRoles.isSeller && mySellerListing?.seller && !mySellerListing.seller.is_removed) {
            navigate(`/seller/listing/my-listing`);
        }
    };

    const handleGoToFavorite = () => {
        if (keycloak?.authenticated) { }
        else keycloak?.login();
    }

    const isOpportunistic = (el: any) => {
        var isOppo = false;

        if (el?.challenge === "I am opportunistic with no specific target") {
            isOppo = true
        }
        if(myBuyerListing && myBuyerListing?.userAccount?.is_premium === false) {
            isOppo = true;
        }

        return isOppo;
    }


    useEffect(() => {
        if (isMySellerListingFetched) setEnabledMySellerListing(false);
    }, [isMySellerListingFetched])

    useEffect(() => {
        if (isMyBuyerListingFetched) setEnabledGetMyBuyerListing(false);
    }, [isMyBuyerListingFetched])

    useEffect(() => {
        if (isMainAcquisitionsFetched) setEnabledData(false);
    }, [isMainAcquisitionsFetched])

    useEffect(() => {
        if (userRoles) setEnabled(false);
    }, [tokenInfo?.user_uuid, userRoles])

    const [imageSrc, setImageSrc] = useState<string>('');

    const base64String = myBuyerListing?.company_image;

    useEffect(() => {
        if (base64String) {
            const imageSrcs = Base64.decode(base64String);
            setImageSrc(imageSrcs);
        }
    }, [base64String]);
  
    return (
        <>
            {(initialized && !keycloak?.authenticated) && <Navigate to="/" />}
            {(initialized && keycloak?.authenticated) &&
                <div id="my-listing" className="findIsvcontainer programContainer sellerContainer">
                    <BuyerSecondMenu
                        showSearch={false}
                        handleGoToFavorite={handleGoToFavorite}
                    />

                    <div className="filterFindIsv leftTop hScroll">
                        <div className="contentFilter">
                            <div className="block sellerBuyer pb-0 border-0">
                                <div className="sellers" onClick={() => goToMySellerListing()} >
                                    <a href="javascript:;" className={`${(userRoles && !userRoles.isSeller) || (mySellerListing && mySellerListing.is_removed) ? 'my-listing-disabled' : ''}`}>
                                        <span className="icon-sellers"></span>My seller listing
                                    </a>
                                </div>
                                <div className="buyer active">
                                    <a href="javascript:;">
                                        <span className="icon-buyerList"></span>My buyer listing
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container mx-auto minHeightwin sellerRight mt-5 pt-5">
                        <div className="listOfIsv listOfSeller scrollContainer mt-5 overflow-visible">
                            <ul className="listSeller">
                                {myBuyerListing && (
                                    <li className="list">
                                        <div className="">
                                            <>
                                                {
                                                    myBuyerListing?.company_image !== null && myBuyerListing?.company_image !== undefined && myBuyerListing?.company_image !== "" ? (
                                                        <img src={process.env.REACT_APP_API_URL + urlsApi.upload.get_upload_image + '/' + myBuyerListing.company_image}
                                                            width={60}
                                                            height={60}
                                                            alt="Company Logo" />
                                                    ) : (
                                                        <div className="logo">
                                                            <span className='icon-building1 ico'></span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        </div>
                                        <div className="desc">
                                            <div className="title">
                                                {isOpportunistic(myBuyerListing) == false ? (
                                                    <div className='title'>
                                                        {myBuyerListing?.company_name !== "user_anonyma" &&  myBuyerListing?.company_name !==""? myBuyerListing?.company_name : 'Company Confidential'} <span className='motivated'>Motivated buyer</span>
                                                    </div>
                                                ) : (
                                                    <div className='title'>{myBuyerListing?.company_name !== "user_anonyma" &&  myBuyerListing?.company_name !==""? myBuyerListing?.company_name : 'Company Confidential'}</div>
                                                )}
                                            </div>

                                            <h2 className="about fs-6 mt-2">
                                                {myBuyerListing?.stage} <span>{myBuyerListing?.company_reason}</span>
                                            </h2>
                                            <a className="btn btn-devinsider" href="javascript:;" onClick={() => goToDetails()}>
                                                More details
                                            </a>
                                            <ul>
                                                <li className="item">
                                                    <div>Location</div>
                                                    <span className="ico icon-location"></span>
                                                    <span className="txt">{ISO3166.getCountry(myBuyerListing?.location)}</span>
                                                </li>
                                                <li className="item">
                                                    <div>Buyer type</div>
                                                    <span className="ico icon-location"></span>
                                                    <span className="txt">{tokenInfo && getRoleDisplayName(tokenInfo?.roles)}</span>
                                                </li>
                                                <li className="item">
                                                    <div>Main Acquisition Goal</div>
                                                    <span className="ico icon-topValue"></span>
                                                    <span className="txt">{mainAcquisition?.data?.find((el: any) => el?.id == myBuyerListing?.main_acquisition_goal)?.attributes?.Label}</span>
                                                </li>
                                            </ul>

                                            <div className="item listing">
                                                <span style={{ display: "inline-block", marginRight: "1rem" }}>Preferred acquisition target location : </span>
                                                {myBuyerListing?.preferred_hq_location && myBuyerListing?.preferred_hq_location.slice(0, 3).map((hqLocation: any, i: number) => (
                                                    <span className="txt" key={i}>{ISO3166.getCountry(hqLocation.iso2)}</span>
                                                ))}
                                                {myBuyerListing?.preferred_hq_location && myBuyerListing?.preferred_hq_location.length > 3 && (
                                                    <span className="txt">...</span>
                                                )}
                                            </div>

                                        </div>
                                    </li>
                                )}
                            </ul>

                        </div>
                    </div>
                </div>
            }
        </>

    );
}

import { INecessaryInformations } from "../types/necessary-informations";

export const initialNecessaryInformations: INecessaryInformations = {
    summarize: "",
    lifecycle_stage: 0,
    main_value: 0,
    key_assets: "",
    target_audience: "",
    top_competitors: "",
    acquirer_interest: "",
};
import { IFinancialDetails } from "../types/financial-details";

export const initialFinancialDetails: IFinancialDetails = {
    ownership_structure: "",
    raised_funds: 0,
    last_quarter_revenue: 0,
    profitability: 0,
    total_revenue1: "",
    total_revenue2: "",
    total_revenue3: "",
    total_profit1: "",
    total_profit2: "",
    total_profit3: ""
};


import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetTypeUser } from "../../api/user";
import { useKeycloakHooks } from "../../hooks/keycloak";
import { LoadingAllPage } from "../../widget/loader";
import Dashboard from "../dashboard";
import SellerListing from "../seller/listing";

export default function Home() {
    const navigate = useNavigate();
    const {keycloak, tokenInfo, initialized} = useKeycloakHooks();
    const { data: userRoles, isLoading: userRolesLoading, isFetched} = useGetTypeUser(tokenInfo, true);
    
    // useEffect(() => {
    //     if(initialized && keycloak.authenticated){
    //         if (userRoles && !userRoles?.isSeller && !userRoles?.isBuyer) {
    //             navigate('/seller/listing');
    //         }
    //     } else if(initialized && !keycloak.authenticated) {
    //         navigate('/seller/listing');
    //     }
    // }, [initialized, isFetched, keycloak.authenticated]);
    
    return <div>
        {(userRolesLoading || !initialized) && <LoadingAllPage />}
        {(initialized && isFetched && keycloak.authenticated && userRoles && (userRoles?.isSeller || userRoles?.isBuyer)) && <Dashboard />}
        {(initialized && keycloak.authenticated && userRoles && !userRoles?.isSeller && !userRoles?.isBuyer) && <SellerListing />}
        {(initialized && !keycloak.authenticated) && <SellerListing />}
    </div>;
}

import { ChangeEvent, useEffect, useState } from "react";
import { IMAStrategy } from "../../../../../types/MAStrategy";

import { useGetStaticParams } from "../../../../../api/staticParams";
import BuyerStep from "../../../../../components/buyer/buyer-step";
import { renderOptions } from '../../../utils/renderOptions';

export type IProps = {
    handleNextStep: (step: number) => void;
    showCancelModal: () => void;
    setData: (data: IMAStrategy) => void;
    data: IMAStrategy;
    validateSteps: Array<number>;
    currentStep: number;
    setCurrentStep: (step: number) => void;
    removeStepInValidated: (step: number) => void;
    addStepToValidated: (step: number) => void;
};

export default function MAStrategy(props: IProps) {

    const [formState, setFormState] = useState(props?.data);

    const [intentionsError, setIntentionsError] = useState('');
    const [acquisitionError, setAcquisitionError] = useState('');
    const [challengeError, setChallengeError] = useState('');
    const [acquisitionBudgetError, setAcquisitionBudgetError] = useState('');
    const [mainAcquisitionError, setMainAcquisitionError] = useState('');

    const [enableGetAcquisitions, setEnableGetAcquisitions] = useState(true);
    const [enableGetChallenges, setEnableGetChallenges] = useState(true);
    const [enableGetBudgets, setEnableGetBudgets] = useState(true);
    const [enableMainAcquisitions, setEnableMainAcquisitions] = useState(true);

    const { data: acquisitionsData, isFetched: isAcquisitionsFetched } = useGetStaticParams('ACQUISITIONS', enableGetAcquisitions);
    const { data: challenge, isFetched: isChallengesFetched } = useGetStaticParams('CHALLENGES', enableGetChallenges);
    const { data: acquisitionBudget, isFetched: isBudgetsFetched } = useGetStaticParams('ACQUISITION_BUDGETS', enableGetBudgets);
    const { data: mainAcquisition, isFetched: isMainAcquisitionsFetched } = useGetStaticParams('MAIN_PURPOSES', enableMainAcquisitions);

    useEffect(() => {
        if (isAcquisitionsFetched) setEnableGetAcquisitions(false);
    }, [isAcquisitionsFetched]);

    useEffect(() => {
        if (isChallengesFetched) setEnableGetChallenges(false);
    }, [isChallengesFetched]);

    useEffect(() => {
        if (isBudgetsFetched) setEnableGetBudgets(false);
    }, [isBudgetsFetched]);

    useEffect(() => {
        if (isMainAcquisitionsFetched) setEnableMainAcquisitions(false);
    }, [isMainAcquisitionsFetched]);

    const updateState = (property: string, value: any) => {
        setFormState((prevState: IMAStrategy) => ({
            ...prevState,
            [property]: value,
        }));
    }

    useEffect(() => {
        if (formState?.intentions !== "") {
            setIntentionsError('');
        }

        if (Number(formState?.acquisition) !== 0) {
            setAcquisitionError('');
        }

        if (Number(formState?.challenge) !== 0) {
            setChallengeError('');
        }

        if (Number(formState?.mainAcquisition) !== 0) {
            setMainAcquisitionError('');
        }

    }, [formState?.acquisition, formState?.challenge, formState?.intentions, formState?.mainAcquisition]);


    const goToNextStep = (step: number, isGoingNextStep: boolean) => {
        setIntentionsError('');
        setAcquisitionError('');
        setChallengeError('');
        setAcquisitionBudgetError('');
        setMainAcquisitionError('');

        let hasErrors = false;

        if(isGoingNextStep){
            if (formState?.intentions === "") {
                setIntentionsError(' ERROR This field is required');
                hasErrors = true;
            }
            else if (formState?.intentions.length > 450) {
                setIntentionsError(' ERROR Exceeded the limit of 450 characters');
                hasErrors = true;
            }
    
            if (Number(formState?.acquisition) === 0) {
                setAcquisitionError(' ERROR This field is required');
                hasErrors = true;
            }
    
            if (Number(formState?.challenge) === 0) {
                setChallengeError(' ERROR This field is required');
                hasErrors = true;
            }
    
            if (Number(formState?.mainAcquisition) === 0) {
                setMainAcquisitionError(' ERROR This field is required');
                hasErrors = true;
            }
    
            if (hasErrors) {
                if(isGoingNextStep) props?.removeStepInValidated(props?.currentStep);
                return;
            }
        }

        props?.addStepToValidated(props.currentStep);
        props?.setData(formState);
        props?.handleNextStep(step);
    }

    return (
        <>
            <BuyerStep
                validatedSteps={props?.validateSteps}
                currentStep={props?.currentStep}
                handleContinue={goToNextStep}
                setCurrentStep={props?.setCurrentStep}
            />
            <div className="scrollContainer">
                <div className='contentUser acquisition'>
                    <div className='contentBorder row'>
                        <div className='form-group required mb-4 p-0'>
                            <label htmlFor='intentions'>
                                Describe your M&A intentions in 450 characters or less
                            </label>{""}
                            {
                                intentionsError && (
                                    <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                        <span className='error-red'> ERROR </span>{" "}
                                        <span className='txt-error'>This field is required</span>
                                    </div>
                                )
                            }
                            <textarea
                                id='intentions'
                                className='form-control mt-2'
                                name='intentions'
                                maxLength={450}
                                value={formState?.intentions}
                                onChange={(e) => {
                                    updateState(e.target.name, e.target.value);
                                }}
                            />
                        </div>
                        <div className='form-group required mb-4 p-0'>
                            <label htmlFor=''>Specify how many acquisitions you have made in the past 5 years</label>
                            {""}
                            {
                                acquisitionError && (
                                    <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                        <span className='error-red'> ERROR </span>{" "}
                                        <span className='txt-error'>This field is required</span>
                                    </div>
                                )
                            }
                            <select name='acquisition' className='form-control mt-2' value={formState?.acquisition} onChange={(e) => { updateState(e.target.name, e.target.value) }}>
                                {renderOptions(acquisitionsData?.data)}
                            </select>
                        </div>

                        <div className='form-group required mb-4 p-0'>
                            <label htmlFor=''>How many techology and/or Go-to-Market challenges do you plan to solve with acquisitions in the next 12 months </label>      {""}
                            {
                                challengeError && (
                                    <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                        <span className='error-red'> ERROR </span>{" "}
                                        <span className='txt-error'>This field is required</span>
                                    </div>
                                )
                            }
                            <select name="challenge" className='form-control mt-2' value={formState?.challenge} onChange={(e) => { updateState(e.target.name, e.target.value); }}>
                                {renderOptions(challenge?.data)}
                            </select>
                        </div>
                        <div className='form-group not-required mb-4 p-0'>
                            <label htmlFor=''>What is your overall acquistion budget? </label>
                            <select name="acquisitionBudget" className='form-control mt-2' value={formState?.acquisitionBudget} onChange={(e) => { updateState(e.target.name, e.target.value); }}>
                                {renderOptions(acquisitionBudget?.data)}
                            </select>
                        </div>
                        <div className='form-group required mb-4 p-0'>
                            <label htmlFor=''>What would be the main purpose of acquisition?</label>
                            {
                                mainAcquisitionError && (
                                    <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                        <span className='error-red'> ERROR </span>{" "}
                                        <span className='txt-error'>This field is required</span>
                                    </div>
                                )
                            }
                            <select name="mainAcquisition" className='form-control mt-2' value={formState?.mainAcquisition} onChange={(e) => { updateState(e.target.name, e.target.value); }}>
                                {renderOptions(mainAcquisition?.data)}
                            </select>
                        </div>
                    </div>
                    <div className='text-align-right stepsButtons'>
                        <button className='btn btn-devinsider cancel' onClick={() => props?.showCancelModal()}>
                            Cancel
                        </button>
                        <button className='btn btn-devinsider save' onClick={() => goToNextStep(props?.currentStep + 1, true)}>
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
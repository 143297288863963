import { ChangeEvent, useEffect, useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import ReactFlagsSelect from "react-flags-select";
import { isValidPhoneNumber } from "react-phone-number-input";
import { ReactComponent as Loader } from '../../../resources/images/Rolling.svg';
import { IFinancialDetails } from "../../../types/financial-details";
import { contact } from "../../../utils/country_phone";

export default function MAContact(props: any) {
    const getInitialState = () => {
        return {
            ...props?.data,
            firstname: props?.data?.firstname !== 'manda_deleted_manda' ? props?.data?.firstname : '',
            lastname: props?.data?.lastname !== 'manda_deleted_manda' ? props?.data?.lastname : '',
            linkedin_url: props?.data?.linkedin_url !== 'manda_deleted_manda' ? props?.data?.linkedin_url : '',
            email: props?.data?.email !== 'manda_deleted_manda' ? props?.data?.email : '',
            phone_number: props?.data?.phone_number !== 'manda_deleted_manda' ? props?.data?.phone_number : ''
        };
    }
    const [formState, setFormState] = useState(getInitialState());

    useEffect(() => {
        if (props?.isOpen) {
            setFormState(getInitialState());
        }
    }, [props?.isOpen]);

    const [isUpdating, setIsUpdating] = useState<boolean>(props?.isUpdating || false);
    const updateState = (property: string, value: any) => {
        setFormState((prevState: any) => ({
            ...prevState,
            [property]: value,
        }));
    }
    const [isDisplayFirstName, setIsDisplayFirstName] = useState(false);
    const [isDisplayEmail, setIsDisplayEmail] = useState(false);
    const [isDisplayLinkedinurl, setIsDisplayLinkedinurl] = useState(false);
    const [isDisplayPhonenumber, setIsDisplayPhonenumber] = useState(false);

    const [phoneNumber, setPhoneNumber] = useState('');

    const [dialCodeSelected, setDialCodeSelected] = useState(
        "+1"
    );

    const [selected, setSelected] = useState(
        "US"
    );

    const handleSelect = (countryCode: any) => {
        setSelected(countryCode);
        setSelectedFlag(countryCode);
        const dialCode = contact?.find(
            (el: any) => el.code == countryCode
        )?.dial_code;
        setDialCodeSelected(dialCode ? dialCode : "");
        updateState("DialCode", dialCode ? dialCode : "");
        updateState("Country", countryCode || "");
    };

    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [linkedinUrlError, setLinkedinUrlError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');

    const [firstName, setFirstname] = useState('');
    const [lastName, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [linkedinurl, setLinkedinurl] = useState('');

    useEffect(() => {
        let tempPhoneNumber = formState?.phone_number || '';
        const cleanedPhoneNumber = formState?.phone_number.substring(4);

        if (!phoneNumber) {
            const dialCode = tempPhoneNumber.substring(0, 4);
            const foundCountry = contact?.find((el: any) => el.dial_code === dialCode);

            if (foundCountry) {
                setDialCodeSelected(dialCode);
                setSelected(foundCountry.code);
                setSelectedFlag(foundCountry.code);
            }
            setPhoneNumber(cleanedPhoneNumber);
        }
        setFirstname(formState?.firstname || '');
        setLastname(formState?.lastname || '');
        setEmail(formState?.email || '');
        setLinkedinurl(formState?.linkedin_url || '');
    }, [props.data, phoneNumber]);

    useEffect(() => {

        if (formState?.phone_number === '') {
            setDialCodeSelected('+1');
            setPhoneNumber('');
            setSelected('US');
            setSelectedFlag('US');
        }

    }, [formState?.phone_number]);

    const [isAccordionOpen, setIsAccordionOpen] = useState(false);

    const handleAccordionOpen = (eventKey: string | null) => {
        if (eventKey && eventKey !== isAccordionOpen.toString()) {
            setIsAccordionOpen(true);
        }
        switch (eventKey) {
            case '0':
                setIsAccordionOpen(true);
                setIsDisplayFirstName(!isDisplayFirstName);
                setIsDisplayLinkedinurl(false);
                setIsDisplayPhonenumber(false);
                setIsDisplayEmail(false);
                break;
            case '1':
                setIsAccordionOpen(true);
                setIsDisplayFirstName(!isDisplayFirstName);
                setIsDisplayLinkedinurl(false);
                setIsDisplayPhonenumber(false);
                setIsDisplayEmail(false);
                break;
            case '2':
                setIsAccordionOpen(true);
                setIsDisplayFirstName(false);
                setIsDisplayLinkedinurl(!isDisplayLinkedinurl);
                setIsDisplayPhonenumber(false);
                setIsDisplayEmail(false);
                break;
            case '3':
                setIsAccordionOpen(true);
                setIsDisplayFirstName(false);
                setIsDisplayLinkedinurl(false);
                setIsDisplayEmail(!isDisplayEmail);
                setIsDisplayPhonenumber(false);
                break;
            case '4':
                setIsAccordionOpen(true);
                setIsDisplayFirstName(false);
                setIsDisplayLinkedinurl(false);
                setIsDisplayEmail(false);
                setIsDisplayPhonenumber(!isDisplayPhonenumber);

                break;
        }
    };

    const isValidEmail = (value: string) => {
        return /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value);
    }

    const isValidLinkedinUrl = (url: string) => {

        const regex = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+(\/)?$/;
        return regex.test(url);
    }

    const validateForm = () => {
        let hasErrors = false;

        if (!firstName || firstName.trim() === '') {
            setFirstNameError('ERROR This field is required');
            hasErrors = true;
        } else {
            setFirstNameError('');
        }

        if (!lastName || lastName.trim() === '') {
            setLastNameError(' ERROR This field is required');
            hasErrors = true;
        } else {
            setLastNameError('');
        }

        if (!linkedinurl || linkedinurl.trim() === '') {
            setLinkedinUrlError(' ERROR This field is required');
            hasErrors = true;
        }
        else if (!isValidLinkedinUrl(linkedinurl)) {

            setLinkedinUrlError(' ERROR Invalid LinkedIn URL. eg:https://www.linkedin.com/in/username');
            hasErrors = true;

        }


        if (!email || email.trim() === '') {
            setEmailError(' ERROR This field is required');
            hasErrors = true;
        }
        else if (!isValidEmail(email)) {
            setEmailError('Invalid email. Please enter a valid email.');
            hasErrors = true;
        }
        else {
            setEmailError('');
        }

        if (!phoneNumber || phoneNumber.trim() === '') {
            setPhoneNumberError(' ERROR This field is required');
            hasErrors = true;
        }
        else if (!isValidPhoneNumber(dialCodeSelected + phoneNumber)) {
            setPhoneNumberError('Invalid phone number. Please enter a valid phone number.');
            hasErrors = true;
        }
        else {
            setPhoneNumberError('');
        }

        return hasErrors;
    }
    const [selectedFlag, setSelectedFlag] = useState("US");

    const handleCloseModal = () => {
        setFormState({ ...props?.data });
        setIsDisplayFirstName(false);
        setIsDisplayLinkedinurl(false);
        setIsDisplayPhonenumber(false);
        setIsDisplayEmail(false);
        setIsAccordionOpen(false);
        props?.closeModal();
    };

    const handleSave = async () => {
        setIsUpdating(!props?.isUpdating);
        const stepHasErrors = validateForm();
        if (stepHasErrors) {
            setIsUpdating(false);
            return
        }
        await props?.confirmSave(formState);
        setIsUpdating(false);
        setIsDisplayFirstName(false);
        setIsDisplayLinkedinurl(false);
        setIsDisplayPhonenumber(false);
        setIsDisplayEmail(false);
        setIsAccordionOpen(false);
    };

    useEffect(() => {
        const selectedFields = ['FirstName', 'LastName', 'Linkedin', 'Email', 'PhoneNumber', 'DialCode', 'Country'];

        selectedFields.forEach((field) => {
            const value = formState[field as keyof IFinancialDetails];

            switch (field) {
                case 'FirstName':
                    setFirstname(value);
                    break;
                case 'LastName':
                    setLastname(value);
                    break;
                case 'Linkedin':
                    setLinkedinurl(value);
                    break;
                case 'Email':
                    setEmail(value);
                    break;
                case 'PhoneNumber':
                    setPhoneNumber(value);
                    break;
                case 'DialCode':
                    setDialCodeSelected(value ? value : "+1");
                    break;
                case 'Country':
                    setSelectedFlag(value ? value : "US");
                    setSelected(value);
                    break;
                default:
                    break;
            }
        });
    }, []);

    useEffect(() => {
        if (firstName && firstName.trim() !== '') {
            setFirstNameError('');
        }

        if (lastName && lastName.trim() !== '') {
            setLastNameError('');
        }

        if (linkedinurl && linkedinurl.trim() !== '' && isValidLinkedinUrl(linkedinurl)) {
            setLinkedinUrlError('');
        }

        if (email && email.trim() !== '') {
            setEmailError('');
        }

        if (phoneNumber && phoneNumber.trim() !== '') {
            setPhoneNumberError('');
        }
    }, [firstName, lastName, linkedinurl, email, phoneNumber]);

    return (
        <Modal show={props?.isOpen} className='createCompanypopup large forStep5custom buyer-modal'>
            <Modal.Header>
                <div className='headerpopupCompany noStep'>
                    <h2 style={{ fontSize: "1rem", color: "#656565" }} className='title'>{props?.title}</h2>

                    <button onClick={() => handleCloseModal()}
                        className="close">
                        <span className="icon-close-pop" aria-hidden="true"></span>

                    </button>
                </div>
            </Modal.Header>
            <Modal.Body className=''>

                <Accordion>
                    <Accordion.Item eventKey="0" >
                        <Accordion.Header onClick={() => handleAccordionOpen('0')}>
                            <div className='form-group required p-0'>
                                <label htmlFor='' style={{ fontWeight: 600, display: "inline-block", marginRight: "1rem" }}>Name:</label>{" "}<span>{isDisplayFirstName === false ? formState?.firstname + " " + formState?.lastname : ""}</span>
                            </div>
                            {
                                (firstNameError && !lastNameError) && (
                                    <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                        <span className='error-red'>{firstNameError}</span>{" "}
                                    </div>
                                )
                            }
                            {
                                (!firstNameError && lastNameError) && (
                                    <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                        <span className='error-red'>{lastNameError}</span>{" "}
                                    </div>
                                )
                            }
                            {
                                (firstNameError && lastNameError) && (
                                    <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                        <span className='error-red'>{lastNameError}</span>{" "}
                                    </div>
                                )
                            }
                        </Accordion.Header>

                        <Accordion.Body>
                            <div className='form-group p-0 mb-3'>
                                <label htmlFor='' style={{ display: "inline-block", marginRight: "1rem" }}>First Name:</label>
                                <input
                                    maxLength={450}
                                    value={formState?.firstname}
                                    onChange={(e) => {
                                        setFirstname(e.target.value);
                                        updateState('firstname', e.target.value);
                                    }}
                                    className='form-control mt-2'
                                    type="text"
                                    required
                                    name=""
                                    id=""
                                />
                            </div>
                            <div className='form-group p-0'>
                                <label htmlFor='' style={{ display: "inline-block", marginRight: "1rem" }}>Last Name:</label>
                                <input
                                    maxLength={450}
                                    value={formState?.lastname}
                                    onChange={(e) => {
                                        setLastname(e.target.value);
                                        updateState('lastname', e.target.value);
                                    }}
                                    className='form-control mt-2'
                                    type="text"
                                    required
                                    name=""
                                    id=""
                                />
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header  onClick={() => handleAccordionOpen('2')}>
                            <div className='form-group required p-0'>
                                <label htmlFor='' style={{ fontWeight: 600, display: "inline-block", marginRight: "1rem" }}>LinkedIn URL:</label>{" "}<span>{isDisplayLinkedinurl === false ? formState?.linkedin_url : ""}</span>
                            </div>
                            {
                                linkedinUrlError && (
                                    <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                        <span className='error-red'> {linkedinUrlError} </span>{" "}
                                    </div>
                                )
                            }
                        </Accordion.Header>
                        <Accordion.Body>
                            <p style={{ color: "#595959" }}>Start with &ldquo;https://www.&rdquo; or &ldquo;www.&rdquo;</p>
                            <input
                                maxLength={450}
                                value={formState?.linkedin_url}
                                onChange={(e) => {
                                    setLinkedinurl(e.target.value);
                                    updateState('linkedin_url', e.target.value);
                                }}
                                className='form-control'
                                type="text"
                                required
                                name=""
                                id=""
                            />
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                        <Accordion.Header  onClick={() => handleAccordionOpen('3')}>
                            <div className='form-group required p-0'>
                                <label htmlFor='' style={{ fontWeight: 600, display: "inline-block", marginRight: "1rem" }}>Email:</label>{" "}<span>{isDisplayEmail === false ? formState?.email : ""}</span>
                            </div>
                            {
                                emailError && (
                                    <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                        <span className='error-red'> {emailError} </span>{" "}
                                    </div>
                                )
                            }
                        </Accordion.Header>
                        <Accordion.Body>
                            <input
                                maxLength={450}
                                value={formState?.email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    updateState('email', e.target.value);
                                }}
                                className='form-control mt-2'
                                type="email"
                                required
                                name=""
                                id=""
                            />
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4" >
                        <Accordion.Header onClick={() => handleAccordionOpen('4')}>
                            <div className='form-group required p-0'>
                                <label htmlFor='' style={{ fontWeight: 600, display: "inline-block", marginRight: "1rem" }}>Phone number:</label>{" "}
                                <span>
                                    {isDisplayPhonenumber === false && formState?.phone_number !== ''
                                        ? (formState?.phone_number.includes(dialCodeSelected)
                                            ? "(" + dialCodeSelected + ")" + formState?.phone_number.replace(dialCodeSelected, '')
                                            : "(" + dialCodeSelected + ")" + (formState?.phone_number))
                                        : ""}
                                </span>
                            </div>
                            {
                                phoneNumberError && (
                                    <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                        <span className='error-red'> {phoneNumberError} </span>{" "}
                                    </div>
                                )
                            }
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="numberphone mt-2" style={{ position: "relative" }}>
                                <input
                                    type="text"
                                    className="phonenumber"
                                    style={{
                                        textIndent:
                                            dialCodeSelected?.length === 2
                                                ? 35
                                                : dialCodeSelected?.length === 3
                                                    ? 40
                                                    : dialCodeSelected?.length === 4
                                                        ? 50
                                                        : 60,
                                        paddingBottom: 5,
                                    }}
                                    value={phoneNumber}
                                    onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                                        const inputText = evt.target.value.replace(/\D/g, '');
                                        const dialCode = dialCodeSelected || "+1";
                                        setPhoneNumber(inputText);
                                        updateState('phone_number', inputText);
                                        updateState('DialCode', dialCode);
                                    }}

                                />
                                <span style={{ position: "absolute", top: 5, left: 60 }}>
                                    ({dialCodeSelected})
                                </span>
                                <ReactFlagsSelect
                                    className="selectFlag"
                                    selected={selectedFlag}
                                    onSelect={handleSelect}
                                    searchable={true}
                                    showSelectedLabel={false}
                                    placeholder=" "
                                />
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

            </Modal.Body>
            <Modal.Footer>
                <div className='contentBorder formForEmail'>
                    {!isAccordionOpen && (
                        <>
                            <button
                                style={{ marginLeft: "1rem" }}
                                className='btn btn-devinsider px-5'
                                onClick={() => handleCloseModal()}
                            >
                                Close
                            </button>
                        </>
                    )}
                    {isAccordionOpen && (
                        <>
                            <button
                                style={{ marginLeft: "1rem" }}
                                className='btn btn-devinsider px-5 cancel'
                                onClick={() => handleCloseModal()}
                            >
                                Cancel
                            </button>
                            <button
                                className='btn btn-devinsider save text-white'
                                onClick={() => handleSave()}
                            >
                                {isUpdating ? <Loader /> : "Save"}
                            </button>
                        </>
                    )}
                </div>
            </Modal.Footer >
        </Modal>
    );
}

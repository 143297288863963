import { ICompanyBasicsBuyer } from "../types/company-basics-buyer";

export const initialCompanyBasicsbuyer: ICompanyBasicsBuyer = {
    brief: "",
    companyname: "",
    companywebsite:"",
    companyHeadCarter: "",
    employe: 0,
    founded: "",
    software_solution: [],
    featured_solution:[],
    targeted_industry:  [],
    geographic_sales_footprint: [],
};
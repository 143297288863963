"use client";

import { useEffect, useReducer, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { usePostSellerListing } from "../../../../api/seller";
import { useGetStaticParams } from "../../../../api/staticParams";
import { urlsApi } from "../../../../api/urls/apiUrl";
import { useGetTypeUser } from "../../../../api/user";
import CancelCreationModal from "../../../../components/modals/cancel-creation/cancel-creation";
import Payment from "../../../../components/payment/payment";
import FreeSellerFailed from "../../../../components/publish/free-publish-failed";
import FreeSellerSuccess from "../../../../components/publish/free-publish-success";
import { useKeycloakHooks } from "../../../../hooks/keycloak";
import { removeCachePaymentMethod, removeCacheProduct, removeCacheProductWithTax, removeCacheReceipt } from "../../../../query/removeCache";
import { IAcquisitionOverview } from "../../../../types/acquisition-overview";
import { ICompanyBasics } from "../../../../types/company-basics";
import { IFinancialDetails } from "../../../../types/financial-details";
import { INecessaryInformations } from "../../../../types/necessary-informations";
import { ISubscription } from "../../../../types/subscription";
import "../../seller.scss";
import { setAcquisitionOverviewAction, setCompanyBasicsAction, setFinancialDetailsAction, setNecessaryInformationsAction, setSubscriptionAction } from './redux/actions';
import { creationResellerReducer, initialState } from './redux/reducers';
import AcquisitionOverview from "./steps/acquisition-overview";
import CompanyBasics from "./steps/company-basics";
import FinancialDetails from "./steps/financial-details";
import NecessaryInformations from "./steps/necessary-informations";
import Subscription from "./steps/subscription";

interface StepsValidation {
  [step: number]: (state: any) => boolean;
}

export default function SellerListingCreation() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { keycloak, tokenInfo } = useKeycloakHooks();
  const [enabledGetRoles, setEnabledGetRoles] = useState<boolean>(true);
  const { data: userRoles, refetch: refetchGetRoles } = useGetTypeUser(tokenInfo, enabledGetRoles);
  const [enableHorizontals, setEnableHorizontals] = useState(true);
  const [enableEcosystems, setEnableEcosystems] = useState(true);
  const { data: horizontalsData, isFetched: isHorizontalsFetched } = useGetStaticParams('HORIZONTALS', enableHorizontals);
  const { data: ecosystemsData, isFetched: isEcosystemsFetched } = useGetStaticParams('TECHNO_ECOSYSTEM', enableEcosystems);
  const [currentStep,   setCurrentStep] = useState<number>(1);
  const [validatedSteps, setValidatedSteps] = useState<Array<number>>([]);

  const [isPlanConfirm, setIsPlanConfirm] = useState<boolean>(false);
  const [isPaymentProcess, setIsPaymentProcess] = useState<boolean>(false);
  const [isFreeProcess, setIsFreeProcess] = useState<boolean>(false);
  const [isFreeProcessFailed, setIsFreeProcessFailed] = useState<boolean>(false);
  const [messageFailed, setMessageFailed] = useState<string>("");
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [state, dispatch] = useReducer(creationResellerReducer, initialState);
  const [stepsCount, setStepsCount] = useState<number>(5);
  const [isPostLoading, setIsPostLoading] = useState<boolean>(false);
  const [idParam, setIdParam] = useState<number>(0);
  const mut = useMutation({
    mutationFn: usePostSellerListing,
    retry: 0,
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries([urlsApi.seller.my_listing, {}]);
      queryClient.invalidateQueries([urlsApi.buyer.mylisting, {}]);
    }
  });

  const topRef = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    if (topRef.current) {
      window.scrollTo({
        top: topRef.current.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  const goToHomePage = () => {
    navigate("/");
  };

  useEffect(() => {
    if (!keycloak?.authenticated) {
      goToHomePage();
    }
  }, [keycloak?.authenticated]);

  useEffect(() => {
    if (userRoles) setEnabledGetRoles(false);
  }, [userRoles]);
  useEffect(() => {
    if (isEcosystemsFetched) setEnableEcosystems(false);
  }, [isEcosystemsFetched]);

  const openCancelModal = () => {
    setShowCancelModal(true);
  };

  const addStepToValidated = (step: number) => {
    if (validatedSteps.indexOf(step) == -1) {
      setValidatedSteps(prevState => [...prevState, step]);
    }
  };

  const removeStepInValidated = (step: number) => {
    setValidatedSteps(prevState => {
      return prevState.filter(item => item < step);
    });
  };

  const closeCancelModal = () => {
    setShowCancelModal(false);
  };

  const closePopUpFreeModal = () => {
    setIsFreeProcess(false);
    navigate(`/seller/listing/details/${idParam}`);
  };

  const closePopUpFreeModalFailed = () => {
    setIsFreeProcessFailed(false);
    navigate(`/seller/listing/details/${idParam}`);
  };
  let specific_tecnhology: object[] = [];

  useEffect(() => {
    if (isHorizontalsFetched) setEnableHorizontals(false);
  }, [isHorizontalsFetched]);
  if (ecosystemsData && ecosystemsData.data && ecosystemsData.data.length > 0) {
    ecosystemsData.data.forEach((item: any) => {
      if (item.attributes) {
        specific_tecnhology.push(item.attributes.Label);
      }
    });
  }


  const handleNextStep = async (step: number, isGoingNextStep?: boolean) => {
    if (isGoingNextStep) {
      if (currentStep === stepsCount) {
        if (!isPaymentProcess) {
          setCurrentStep(1);
          setIsPlanConfirm(true);
        }
      } else if (currentStep === 1 && isPlanConfirm && !isPaymentProcess) {
        if (state?.subscription?.planType === "free") {
          try {
            const result = await handlePostListing(null);
            console.log(result);
            if (result?.data?.success === true) {
              setIsPostLoading(false);
              setIsFreeProcess(true);
              setIdParam(result?.data?.listing_id);
            }
          } catch (error) {
            const handlePostListing = (error as any)?.response;
            if(handlePostListing?.status === 409) {
              console.log("dataaa", handlePostListing?.data);
              setIdParam(handlePostListing?.data?.id);
              setIsFreeProcessFailed(true);
              setMessageFailed("You already have a seller listing");
            } else {
              setIsPostLoading(false);
              setIsFreeProcessFailed(true);
              setIdParam(handlePostListing?.data?.listing_id);
            }
          } finally {
            refetchGetRoles();
          }
            
        } else {
          setIsPaymentProcess(true);
        }
      } else setCurrentStep(step);
    } else {
      setCurrentStep(step);
    }
    scrollToTop();
  };

  const setAcquisitionOverview = (data: IAcquisitionOverview) => {
    dispatch(setAcquisitionOverviewAction(data));
  };

  const setNecessaryInformations = (data: INecessaryInformations) => {
    dispatch(setNecessaryInformationsAction(data));
  };

  const setCompanyBasics = (data: ICompanyBasics) => {
    dispatch(setCompanyBasicsAction(data));
  };

  const setFinancialDetails = (data: IFinancialDetails) => {
    dispatch(setFinancialDetailsAction(data));
  };

  const setSubscriptionPlan = (data: ISubscription) => {
    dispatch(setSubscriptionAction(data));
  };

  const leaveCreationListing = () => {
    closeCancelModal();
    navigate('/seller/listing');
  }

  const handlePostListing = async (paymentId: string | null, savePaymentMethod?: boolean) => {
    setIsPostLoading(true);

    let techno: any[] = [];
    let business: any[] = [];
    const currentYear = new Date().getFullYear();
    const recentYears = [currentYear, currentYear - 1, currentYear - 2];

    let revenues: any[] = [];
    let profits: any[] = [];

    state?.company_basics?.technology_ecosystem.forEach((eco: any) => {
      techno.push(eco?.value);
    });

    state?.company_basics?.business_model.forEach((eco: any) => {
      business.push(eco?.value);
    });

    for (let i = 0; i < recentYears.length; i++) {
      const year = recentYears[i];
      let totalRevenue = state?.financial_details?.[`total_revenue${i + 1}`];
      let totalProfit = state?.financial_details?.[`total_profit${i + 1}`];

      totalRevenue = totalRevenue !== undefined && totalRevenue !== "" ? totalRevenue : 0;
      totalProfit = totalProfit !== undefined && totalProfit !== "" ? totalProfit : 0;

      const revenueString = `${year}-${totalRevenue}`;
      const profitString = `${year}-${totalProfit}`;

      revenues.push(revenueString);
      profits.push(profitString);
    }
    // const softwareSolutionToSend = (state?.company_basics?.software_solution !== '' && state?.company_basics?.software_solution !== undefined && state?.company_basics?.software_solution !== null) ? state?.company_basics?.software_solution : [];
    // const featuredSolutionToSend = (state?.company_basics?.featured_solution !== '' && state?.company_basics?.featured_solution !== undefined && state?.company_basics?.featured_solution !== null) ? state?.company_basics?.featured_solution : [];

    let featuredData: object[] = [];
    let solutionData: object[] = [];


    if (horizontalsData && horizontalsData.data && horizontalsData.data.length > 0) {
      horizontalsData.data.forEach((item: any) => {
        if (item.attributes && item.attributes.Featured === false) {
          solutionData.push(item.attributes.Label);
        }
        else {
          featuredData.push(item.attributes.Label);
        }
      });
    }
    // const filteredSoftwareSolutions = softwareSolutionToSend.filter((solution: string) =>
    //   solutionData.map(obj => JSON.stringify(obj)).indexOf(JSON.stringify(solution)) !== -1
    // );
    // const filteredFeaturedSolutions = featuredSolutionToSend.filter((solution: string) =>
    //   featuredData.map(obj => JSON.stringify(obj)).indexOf(JSON.stringify(solution)) !== -1
    // );
    let technology_ecosystem = state?.company_basics?.technology_ecosystem?.map((item: { value: any; }) => item.value) || [];
    let specific_technology_ecosystem = technology_ecosystem.filter((value: object) => !specific_tecnhology.includes(value));

    if (state?.company_basics?.software_solution) {
      technology_ecosystem = technology_ecosystem;
      specific_technology_ecosystem = specific_technology_ecosystem;
    } else {

      technology_ecosystem = [];
      specific_technology_ecosystem = [];

    }

    let dataParam = {
      id_user: tokenInfo?.sub,
      // acquisition overview
      planType: state?.subscription?.planType,
      reason_listing: Number(state?.acquisition_overview?.reason),
      desc_reason_listing: state?.acquisition_overview?.explication,
      desired_acquisition: Number(state?.acquisition_overview?.acquisiton_type),
      urgency: Number(state?.acquisition_overview?.transaction_duration),
      valuation: Number(state?.acquisition_overview?.business_value),

      // potential buyers need
      opportunity: state?.necessary_informations?.acquirer_interest,
      stage: Number(state?.necessary_informations?.lifecycle_stage),
      value_driver: Number(state?.necessary_informations?.main_value),
      key_assets: state?.necessary_informations?.key_assets,
      target_audience: state?.necessary_informations?.target_audience,
      top_competitors: state?.necessary_informations?.top_competitors,
      company_reason: state?.necessary_informations?.summarize,

      // company basics
      location: state?.company_basics?.location,
      company_size: Number(state?.company_basics?.company_size),
      founded: state?.company_basics?.founded,
      business_model: business,
      technology_partnership: technology_ecosystem,
      targeted_industry: state?.company_basics?.targeted_industry,
      software_solution: state?.company_basics?.software_solution,
      featured_solution: state?.company_basics?.featured_solution,
      geographic_footprint: state?.company_basics?.geographic_sales_footprint,
      have_technology: state?.company_basics?.have_technology,

      // financial details
      structure_ownership: state?.financial_details?.ownership_structure,
      raised_funds: Number(state?.financial_details?.raised_funds),
      quarter_revenue: Number(state?.financial_details?.last_quarter_revenue),
      profitability: Number(state?.financial_details?.profitability),
      revenue_generated: revenues,
      profit_made: profits,
      userSpecificEcosystem: specific_technology_ecosystem,

      //Payment infos
      paymentId,
      savePaymentMethod
    };

    console.log("data", dataParam);

    //fonction insertion

    const p = await mut.mutateAsync(dataParam)

    return p;
  }

  const paymentMethodCallBack = async (
    paymentMethodId: string | null,
    setLoading: (l: boolean) => void,
    savePaymentMethod?: boolean,
    setShowPaymentStatus?: (
      show: boolean,
      data: {
        status: 'success' | 'error';
        invoiceNumber?: string;
        invoiceUrl?: string;
      }
    ) => void
  ) => {
    setLoading(true);

    try {
      const data = await handlePostListing(paymentMethodId, savePaymentMethod);
      if (data?.data?.success) {
        setIdParam(data?.data?.listing_id);
        setShowPaymentStatus && setShowPaymentStatus(true, {
          status: 'success',
          invoiceNumber: data.data?.invoice_number,
          invoiceUrl: data.data?.invoice_url
        })
      }
    } catch (error) {
      const handlePostListing = (error as any)?.response;
      if(handlePostListing?.status === 409) {
        console.log("dataaa", handlePostListing?.data);
        setIdParam(handlePostListing?.data?.id);
        setIsFreeProcessFailed(true);
        setMessageFailed("You already have a seller listing");
      } else {
        setIdParam(handlePostListing?.data?.listing_id);

        setShowPaymentStatus && setShowPaymentStatus(true, {
          status: 'error',
        });
      }
      // const handlePostListingData = (error as any)?.response.data;
    } finally {
        refetchGetRoles();
    }

    setLoading(false);
  };

  const handleStatusOk = () => {
    navigate(`/seller/listing/details/${idParam}`);
    removeCacheProductWithTax(queryClient);
    removeCacheReceipt(queryClient);
    removeCacheProduct(queryClient);
    removeCachePaymentMethod(queryClient);
  };

  return (
    <>
      <div className="findIsvcontainer programContainer sellerContainer" ref={topRef}>
        <div className='filterFindIsv notSecondMenu'>
          {currentStep == 1 && !isPaymentProcess &&
            <div className='contentFilter'>
              <div className="block titleLeft border-0 active">
                <span className="icon-prevbtn txtL"></span> <span className='txt'>
                  {isPlanConfirm ? "Confirm your subscription plan" : "Select your subscription plan"}
                </span>
              </div>
              {isPlanConfirm && (
                <a className="go-back-link" onClick={() => setCurrentStep(stepsCount)}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                </svg>
                  Go back</a>
              )}
            </div>
          }

          {isPaymentProcess &&
            <div className='contentFilter'>
              <div
                className={`block titleLeft border-0 active`}
              >
                <span className="icon-prevbtn txtL"></span> <span className='txt'>Complete payment details </span>
              </div>
            </div>
          }
        </div>


        {currentStep === 1 && !isPaymentProcess &&
          <Subscription
            handleNextStep={handleNextStep}
            showCancelModal={openCancelModal}
            setData={setSubscriptionPlan}
            data={state.subscription}
            currentStep={currentStep}
            isPostLoading={isPostLoading}
          />}
        {currentStep === 2 &&
          <AcquisitionOverview
            handleNextStep={handleNextStep}
            showCancelModal={openCancelModal}
            setData={setAcquisitionOverview}
            data={state.acquisition_overview}
            validateSteps={validatedSteps}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            removeStepInValidated={removeStepInValidated}
            addStepToValidated={addStepToValidated}
          />
        }
        {currentStep === 3 &&
          <NecessaryInformations
            handleNextStep={handleNextStep}
            showCancelModal={openCancelModal}
            setData={setNecessaryInformations}
            data={state.necessary_informations} validateSteps={validatedSteps}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            removeStepInValidated={removeStepInValidated}
            addStepToValidated={addStepToValidated}
          />
        }
        {currentStep === 4 &&
          <CompanyBasics
            handleNextStep={handleNextStep}
            showCancelModal={openCancelModal}
            setData={setCompanyBasics}
            data={state.company_basics}
            validateSteps={validatedSteps}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            removeStepInValidated={removeStepInValidated}
            addStepToValidated={addStepToValidated}
          />
        }
        {currentStep === 5 &&
          <FinancialDetails
            handleNextStep={handleNextStep}
            showCancelModal={openCancelModal}
            setData={setFinancialDetails}
            data={state.financial_details}
            validateSteps={validatedSteps}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            removeStepInValidated={removeStepInValidated}
            addStepToValidated={addStepToValidated}
          />
        }
        {isPaymentProcess &&
          <div className='container mx-auto minHeightwin pt-4'>
            <Payment
              planType={state?.subscription?.planType}
              listingType="seller"
              successCallback={paymentMethodCallBack}
              handleStatusOk={handleStatusOk}
              title="Publish listing"
              showTryAgain={false}
              showContinue={true}
              handleClose={handleStatusOk}
              handleCancel={openCancelModal}
            />
          </div>
        }
        {isFreeProcess &&
          <FreeSellerSuccess
            show={isFreeProcess}
            handleOk={closePopUpFreeModal}
          />
        }
        {isFreeProcessFailed &&
          <FreeSellerFailed
            show={isFreeProcessFailed}
            handleOk={closePopUpFreeModalFailed}
            message={messageFailed}
          />
        }
      </div>
      <CancelCreationModal
        isOpen={showCancelModal}
        closeModal={closeCancelModal}
        confirmCancel={leaveCreationListing}
        title={'Publish listing'}
      />
    </>
  );
}


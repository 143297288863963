import ISO3166 from 'iso-3166-1-alpha-2';
import { ChangeEvent, useEffect, useState } from "react";
import { Accordion, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CreatableSelect from "react-select/creatable";
import { useGetMySpecificTehnologyListing } from '../../../api/buyer';
import { generateYearList } from '../../../api/seller';
import { useGetStaticParams } from "../../../api/staticParams";
import IndustriesList from '../../../components/industriesList';
import SolutionsList from '../../../components/solutionsList';
import { ReactComponent as Loader } from '../../../resources/images/Rolling.svg';
import { Countries } from "../../../widget/countries";
import { renderCountryOptions, renderOptions } from '../utils/renderOptions';

type CountryItem = {
    countries: {
        iso2: string;
        country: string;
        continent: string;
    };
    region: string;
};
interface UserSpecificTechnology {
    id: number;
    idUser: string;
    specificTechnology: any[];
}
type SelectedOptions = {
    lifecycle: string[];
};

type EcosystemItem = {
    value: number | string;
    label: string;
    __isNew__?: boolean
};

export default function CompanyOverview(props: any) {
    const _ = require('lodash');

    const getInitialState = () => {
        return {
            ...props?.data,
            ma_intention: props?.data?.ma_intention !== 'manda_deleted_manda' ? props?.data?.ma_intention : '',
            challenge: props?.data?.challenge !== -1 ? props?.data?.challenge : 0,
            targeted_industry: props?.data?.targeted_industry?.find((el: any) => el !== "manda_deleted_manda") ? props?.data?.targeted_industry : [],
            software_solution: props?.data?.software_solution?.find((el: any) => el !== "manda_deleted_manda") ? props?.data?.software_solution : [],
            featured_solution: props?.data?.featured_solution?.find((el: any) => el !== "manda_deleted_manda") ? props?.data?.featured_solution : [],
            geographic_buyer: props?.data?.geographic_buyer?.find((el: any) => el !== "manda_deleted_manda") ? props?.data?.geographic_buyer : [],
            company_reason: props?.data?.company_reason !== 'manda_deleted_manda' ? props?.data?.company_reason : '',
            location: props?.data?.location !== 'manda_deleted_manda' ? props?.data?.location : '',
            founded: props?.data?.founded !== 'manda_deleted_manda' ? props?.data?.founded : '',
            company_size: props?.data?.company_size !== -1 ? props?.data?.company_size : 0,
            recent_acquisition: props?.data?.recent_acquisition !== -1 ? props?.data?.recent_acquisition : 0,
            main_acquisition_goal: props?.data?.main_acquisition_goal !== -1 ? props?.data?.main_acquisition_goal : 0,
            ma_budget_indication: props?.data?.ma_budget_indication !== -1 ? props?.data?.ma_budget_indication : 0
        };
    }

    const [formState, setFormState] = useState(getInitialState());

    useEffect(() => {
        if (props?.isOpen) {
            setFormState(getInitialState());
        }
    }, [props?.isOpen]);

    useEffect(() => {
        if (Object.keys(formState)?.length === 0) {
            setFormState(props?.data ?? {});
        }
    }, [props?.data]);

    const [isUpdating, setIsUpdating] = useState<boolean>(props?.isUpdating || false);

    const updateState = (property: string, value: any) => {
        setFormState((prevState: any) => ({
            ...prevState,
            [property]: value,
        }));
    }

    const [industryError, setIndustryError] = useState('');
    const [solutionError, setSolutionError] = useState('');

    const years = generateYearList();

    const [enableGetEmployees, setEnableGetEmployees] = useState(true);
    const [enableGetAcquisitions, setEnableGetAcquisitions] = useState(true);
    const [enableGetMainAcquisitions, setEnableGetMainAcquisitions] = useState(true);
    const [enableGetHorizontals, setEnableGetHorizontals] = useState(true);
    const [enableGetVerticals, setEnableGetVerticals] = useState(true);
    const [enableGetAcquisitionBudget, setEnableGetAcquisitionBudget] = useState(true);
    const [enableGetIsvBased, setEnableGetIsvBased] = useState(true);
    const [enableGetCountries, setEnableGetCountries] = useState(true);


    const { data: employeesData, isFetched: isEmployeesFetched } = useGetStaticParams('EMPLOYEES', enableGetEmployees);
    const { data: acquisitionsData, isFetched: isAcquisitionsFetched } = useGetStaticParams('ACQUISITIONS', enableGetAcquisitions);
    const { data: horizontals, isFetched: isHorizontalsFetched } = useGetStaticParams('HORIZONTALS', enableGetHorizontals);
    const { data: verticals, isFetched: isVerticalsFetched } = useGetStaticParams('VERTICALS', enableGetVerticals);
    const { data: mainAcquisition, isFetched: isMainAcquisitionsFetched } = useGetStaticParams('MAIN_PURPOSES', enableGetMainAcquisitions);
    const { data: acquisitionBudget, isFetched: isAcquisitionBudgetFetched } = useGetStaticParams('ACQUISITION_BUDGETS', enableGetAcquisitionBudget);
    const { data: isvBased, isFetched: isIsvBasedFetched } = useGetStaticParams('COUNTRIES_SORT_NAME', enableGetIsvBased);
    const { data: dataCountry, isFetched: isCountriesFetched } = useGetStaticParams('COUNTRIES', enableGetCountries);

    useEffect(() => {
        if (isEmployeesFetched) setEnableGetEmployees(false);
    }, [isEmployeesFetched]);

    useEffect(() => {
        if (isAcquisitionsFetched) setEnableGetAcquisitions(false);
    }, [isAcquisitionsFetched]);

    useEffect(() => {
        if (isHorizontalsFetched) setEnableGetHorizontals(false);
    }, [isHorizontalsFetched]);

    useEffect(() => {
        if (isVerticalsFetched) setEnableGetVerticals(false);
    }, [isVerticalsFetched]);

    useEffect(() => {
        if (isMainAcquisitionsFetched) setEnableGetMainAcquisitions(false);
    }, [isMainAcquisitionsFetched]);

    useEffect(() => {
        if (isAcquisitionBudgetFetched) setEnableGetAcquisitionBudget(false);
    }, [isAcquisitionBudgetFetched]);

    useEffect(() => {
        if (isIsvBasedFetched) setEnableGetIsvBased(false);
    }, [isIsvBasedFetched]);

    useEffect(() => {
        if (isCountriesFetched) setEnableGetCountries(false);
    }, [isCountriesFetched]);


    const [activeId, setActiveId] = useState<any>("null");
    const [activeGeoId, setActiveGeoId] = useState<any>("null");


    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [isDisplayBrief, setIsDisplayBrief] = useState(false);
    const [isDisplayIsvBased, setIsDisplayIsvBased] = useState(false);
    const [isDisplayYear, setIsDisplayYear] = useState(false);
    const [isDisplayEmploye, setIsDisplayEmploye] = useState(false);
    const [isDisplayAcquisition, setIsDisplayAcquisition] = useState(false);
    const [isDisplayMainAcquisition, setIsDisplayMainAcquisition] = useState(false);
    const [isDisplayAcquisitionBudget, setIsDisplayAcquisitionBudget] = useState(false);
    const [isDisplaySolution, setIsDisplaySolution] = useState(false);
    const [isDisplayIndustry, setIsDisplayIndustry] = useState(false);
    const [isDisplayGeography, setIsDisplayGeography] = useState(false);
    const [isDisplayTechnology, setIsDisplayTechnology] = useState(false);
    const maxLength = 1000;
    const maxDisplay = 200;

    const handleChange = (e: { target: { value: any; }; }) => {
        const inputValue = e.target.value;
        if (inputValue?.length <= maxLength) {
            updateState('company_reason', inputValue);
        }
    };

    const handleAccordionOpen = (eventKey: string | null) => {
        if (eventKey && eventKey !== isAccordionOpen.toString()) {
            setIsAccordionOpen(true);
        }
        switch (eventKey) {
            case '0':
                setIsAccordionOpen(true);
                setIsDisplayBrief(!isDisplayBrief);
                setIsDisplayIsvBased(false);
                setIsDisplayYear(false);
                setIsDisplayEmploye(false);
                setIsDisplayAcquisition(false);
                setIsDisplayMainAcquisition(false);
                setIsDisplayAcquisitionBudget(false);
                setIsDisplayTechnology(false);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(false);
                setIsDisplayGeography(false);
                break;
            case '1':
                setIsAccordionOpen(true);
                setIsDisplayBrief(false);
                setIsDisplayIsvBased(!isDisplayIsvBased);
                setIsDisplayYear(false);
                setIsDisplayEmploye(false);
                setIsDisplayAcquisition(false);
                setIsDisplayMainAcquisition(false);
                setIsDisplayAcquisitionBudget(false);
                setIsDisplayTechnology(false);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(false);
                setIsDisplayGeography(false);
                break;
            case '2':
                setIsAccordionOpen(true);
                setIsDisplayBrief(false);
                setIsDisplayIsvBased(false);
                setIsDisplayYear(!isDisplayYear);
                setIsDisplayEmploye(false);
                setIsDisplayAcquisition(false);
                setIsDisplayMainAcquisition(false);
                setIsDisplayAcquisitionBudget(false);
                setIsDisplayTechnology(false);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(false);
                setIsDisplayGeography(false);
                break;
            case '3':
                setIsAccordionOpen(true);
                setIsDisplayBrief(false);
                setIsDisplayIsvBased(false);
                setIsDisplayYear(false);
                setIsDisplayEmploye(!isDisplayEmploye);
                setIsDisplayAcquisition(false);
                setIsDisplayMainAcquisition(false);
                setIsDisplayAcquisitionBudget(false);
                setIsDisplayTechnology(false);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(false);
                setIsDisplayGeography(false);
                break;
            case '4':
                setIsAccordionOpen(true);
                setIsDisplayBrief(false);
                setIsDisplayIsvBased(false);
                setIsDisplayYear(false);
                setIsDisplayEmploye(false);
                setIsDisplayAcquisition(!isDisplayAcquisition);
                setIsDisplayMainAcquisition(false);
                setIsDisplayAcquisitionBudget(false);
                setIsDisplayTechnology(false);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(false);
                setIsDisplayGeography(false);
                break;
            case '5':
                setIsAccordionOpen(true);
                setIsDisplayBrief(false);
                setIsDisplayIsvBased(false);
                setIsDisplayYear(false);
                setIsDisplayEmploye(false);
                setIsDisplayAcquisition(false);
                setIsDisplayMainAcquisition(!isDisplayMainAcquisition);
                setIsDisplayAcquisitionBudget(false);
                setIsDisplayTechnology(false);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(false);
                setIsDisplayGeography(false);
                break;
            case '6':
                setIsAccordionOpen(true);
                setIsDisplayBrief(false);
                setIsDisplayIsvBased(false);
                setIsDisplayYear(false);
                setIsDisplayEmploye(false);
                setIsDisplayAcquisition(false);
                setIsDisplayMainAcquisition(false);
                setIsDisplayAcquisitionBudget(!isDisplayAcquisitionBudget);
                setIsDisplayTechnology(false);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(false);
                setIsDisplayGeography(false);
                break;
            case '7':
                setIsAccordionOpen(true);
                setIsDisplayBrief(false);
                setIsDisplayIsvBased(false);
                setIsDisplayYear(false);
                setIsDisplayEmploye(false);
                setIsDisplayAcquisition(false);
                setIsDisplayMainAcquisition(false);
                setIsDisplayAcquisitionBudget(false);
                setIsDisplayIndustry(!isDisplayIndustry);
                setIsDisplaySolution(false);
                setIsDisplayGeography(false);
                setIsDisplayTechnology(false);
                break;
            case '8':
                setIsAccordionOpen(true);
                setIsDisplayBrief(false);
                setIsDisplayIsvBased(false);
                setIsDisplayYear(false);
                setIsDisplayEmploye(false);
                setIsDisplayAcquisition(false);
                setIsDisplayMainAcquisition(false);
                setIsDisplayAcquisitionBudget(false);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(!isDisplaySolution);
                setIsDisplayGeography(false);
                setIsDisplayTechnology(false);
                break;
            case '9':
                setIsAccordionOpen(true);
                setIsDisplayBrief(false);
                setIsDisplayIsvBased(false);
                setIsDisplayYear(false);
                setIsDisplayEmploye(false);
                setIsDisplayAcquisition(false);
                setIsDisplayMainAcquisition(false);
                setIsDisplayAcquisitionBudget(false);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(false);
                setIsDisplayGeography(!isDisplayGeography);
                setIsDisplayTechnology(false);
                break;
            case '10':
                setIsAccordionOpen(true);
                setIsDisplayBrief(false);
                setIsDisplayIsvBased(false);
                setIsDisplayYear(false);
                setIsDisplayEmploye(false);
                setIsDisplayAcquisition(false);
                setIsDisplayMainAcquisition(false);
                setIsDisplayAcquisitionBudget(false);
                setIsDisplayTechnology(!isDisplayTechnology);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(false);
                setIsDisplayGeography(false);
                break;
        }
    };

    const setActiveSection = (eventKey: string | null) => {
        handleAccordionOpen(eventKey);
        toggleActive(eventKey);
    };

    function toggleActiveGeo(id: any) {
        if (activeGeoId === id) {
            setActiveGeoId("null");
        } else {
            setActiveGeoId(id);
        }
    }

    const [briefError, setBriefError] = useState('');
    const [companyHeadCarterError, setCompanyHeadCarterError] = useState('');
    const [yearError, setYearError] = useState('');
    const [employeError, setEmployeError] = useState('');
    const [ecosystemError, setEcosystemError] = useState('');
    const [acquisitionError, setAcquisitionError] = useState('');
    const [acquisitionBudgetError, setAcquisitionBudgetError] = useState('');
    const [mainAcquisitionError, setMainAcquisitionError] = useState('');
    const [geographicSalesError, setGeographicSalesError] = useState('');
    const [haveEcosystemError, setHaveEcosystemError] = useState('');


    let allSolution = [...formState?.software_solution, ...formState?.featured_solution];

    const filteredSolution = (solution: any) => {
        let alreadyExist: Array<string> = [];
        solution?.map((el: any) => {
            let name = horizontals?.data?.find((e: any) => e?.id === el)?.attributes?.Label;
            if(alreadyExist.indexOf(name) === -1) {
                alreadyExist.push(name);
            }
        })
        return alreadyExist;
    }

    const validateForm = () => {
        let hasError = false;
        if (!formState?.company_reason) {
            setBriefError(' ERROR This field is required');
            hasError = true;
        }
        if (!formState?.location || formState?.location === '' || formState?.location === 'Select an option...') {
            setCompanyHeadCarterError(' ERROR This field is required');
            hasError = true;
        }
        if (!formState?.founded) {
            setYearError(' ERROR This field is required');
            hasError = true;
        }

        if (Number(formState?.company_size) === 0) {
            setEmployeError(' ERROR This field is required');
            hasError = true;
        }
        if (Number(formState?.recent_acquisition) === 0) {
            setAcquisitionError(' ERROR This field is required');
            hasError = true;
        }

        if (Number(formState?.main_acquisition_goal) === 0) {
            setMainAcquisitionError(' ERROR This field is required');
            hasError = true;
        }

        if (formState?.targeted_industry?.length < 1) {
            setIndustryError(' ERROR This field is required');
            hasError = true;
        }

        if (filteredSolution(allSolution)?.length < 1) {
            setSolutionError(' ERROR This field is required');
            hasError = true;
        }
        if (formState?.geographic_buyer && formState?.geographic_buyer?.length == 0) {
            setGeographicSalesError(' ERROR This field is required');
            hasError = true;
        }

        return hasError;
    }

    useEffect(() => {
        if (formState?.company_reason && formState?.company_reason.trim() !== '') {
            setBriefError('');
        }
        if (Number(formState?.company_size) !== 0) {
            setEmployeError('');
        }
        if (formState?.targeted_industry?.length > 0) {
            setIndustryError('');
        }
        if (filteredSolution(allSolution)?.length > 0) {
            setSolutionError('');
        }
        if (formState?.location !== undefined && formState?.location !== 'Select an option...' && formState?.location !== '') {
            setYearError('');
        }

        if (formState?.geographic_buyer && formState.geographic_buyer?.length > 0) {
            setGeographicSalesError('');
        }

        if (formState?.location !== undefined && formState?.location !== 'Select an option...' && formState?.location !== '') {
            setCompanyHeadCarterError('');
        }
        if (Number(formState?.recent_acquisition) !== 0) {
            setAcquisitionError('');
        }

        if (Number(formState?.main_acquisition_goal) !== 0) {
            setMainAcquisitionError('');
        }


    }, [formState?.company_reason, formState?.company_size, formState?.location, formState.geographic_buyer, formState?.recent_acquisition, formState?.main_acquisition_goal, formState?.targeted_industry?.length, filteredSolution, allSolution]);


    function toggleActive(id: any) {
        if (activeId === id) {
            setActiveId("null");
        } else {
            setActiveId(id);
        }
    };

    const geographicData = isvBased && isvBased.data ? isvBased?.data?.reduce((acc: any, current: any) => {
        if (!current || !current.attributes) {
            return acc;
        }
        const continent = current?.attributes?.Continent;
        const countryInfo = {
            ISO2: current?.attributes?.ISO2,
            Name: current?.attributes?.Name,
        };

        if(!acc[continent]) {
            acc[continent] = {
                Continent: continent,
                Countries: [countryInfo],
            };
        } else {
            acc[continent].Countries.push(countryInfo);
        }

        return acc;

    }, {}) : {};

    const spanContent = isDisplayBrief === false && formState?.company_reason != '' ? formState?.company_reason?.substring(0, maxDisplay) + "..." : "";

    const addTargetedIndustry = (element: any) => {
        const updatedSelectedIndustry = [...formState?.targeted_industry, element?.id];
        updateState('targeted_industry', updatedSelectedIndustry);
    }

    const removeTargetedIndustry = (element: any) => {
        const updatedSelectedIndustry = formState?.targeted_industry?.filter(
            (el: any) => el !== element?.id
        );
        updateState('targeted_industry', updatedSelectedIndustry);
    }

    const addSoftwareSolution = (element: any) => {
        updateState('software_solution', [...formState?.software_solution, element?.id]);
    }

    const addFeaturedSolution = (element: any) => {
        updateState('featured_solution', [...formState?.featured_solution, element?.id]);
    }

    const removeSolution = (element: any) => {

        let featuredSolutions = horizontals?.data?.filter((el: any) => el?.attributes?.Featured === true);
        let softwareSolutions = horizontals?.data?.filter((el: any) => el?.attributes?.Featured === false);

        let foundInFeaturedList = featuredSolutions?.find(
            (el: any) => el?.attributes?.Label == element?.attributes?.Label
        )

        let foundInSolutionList = softwareSolutions?.find(
            (el: any) => el?.attributes?.Label == element?.attributes?.Label
        )

        if(foundInSolutionList)
            updateState('software_solution', formState?.software_solution?.filter(
                (el: any) => el !== foundInSolutionList?.id
            ));
        
        if(foundInFeaturedList)
            updateState('featured_solution', formState?.featured_solution?.filter(
                (el: any) => el !== foundInFeaturedList?.id
            ));
    }

    const removeItemSelected = (element: any) => {

        let featuredSolutions = horizontals?.data?.filter((el: any) => el?.attributes?.Featured === true);
        let softwareSolutions = horizontals?.data?.filter((el: any) => el?.attributes?.Featured === false);

        let foundInFeaturedList = featuredSolutions?.find(
            (el: any) => el?.attributes?.Label == element
        )

        let foundInSolutionList = softwareSolutions?.find(
            (el: any) => el?.attributes?.Label == element
        )

        if(foundInSolutionList)
            updateState('software_solution', formState?.software_solution?.filter(
                (el: any) => el !== foundInSolutionList?.id
            ));
        
        if(foundInFeaturedList)
            updateState('featured_solution', formState?.featured_solution?.filter(
                (el: any) => el !== foundInFeaturedList?.id
            ));
    }



    const handleCloseModal = () => {

        setFormState({ ...props?.data });
        setIsAccordionOpen(false);
        setIsDisplayBrief(false);
        setIsDisplayIsvBased(false);
        setIsDisplayYear(false);
        setIsDisplayEmploye(false);
        setIsDisplayAcquisition(false);
        setIsDisplayMainAcquisition(false);
        setIsDisplayAcquisitionBudget(false);
        setIsDisplayTechnology(false);
        setIsDisplayIndustry(false);
        setIsDisplaySolution(false);
        setIsDisplayGeography(false);
        props?.closeModal();

    };

    const handleSave = async () => {
        setIsUpdating(!props?.isUpdating);
        const stepHasErrors = validateForm();
        if (stepHasErrors) {
            setIsUpdating(false);
            return
        }
        await props?.confirmSave(formState);
        setIsUpdating(false);
        setIsAccordionOpen(false);
        setIsDisplayBrief(false);
        setIsDisplayIsvBased(false);
        setIsDisplayYear(false);
        setIsDisplayEmploye(false);
        setIsDisplayAcquisition(false);
        setIsDisplayMainAcquisition(false);
        setIsDisplayAcquisitionBudget(false);
        setIsDisplayTechnology(false);
        setIsDisplayIndustry(false);
        setIsDisplaySolution(false);
        setIsDisplayGeography(false);
        setIsAccordionOpen(false);
    };



    return (
        <Modal show={props?.isOpen} className='createCompanypopup large forStep5custom edit-modal buyer-modal'>
            <Modal.Header>
                <div className='headerpopupCompany noStep'>
                    <h2 style={{ fontSize: "1rem", color: "#656565" }} className='title'>{props?.title}</h2>

                    <button onClick={() => handleCloseModal()} className='close'>
                        <span className='icon-close-pop'></span>

                    </button>
                </div>
            </Modal.Header>
            <Modal.Body id="mainterest" className='overview-buyer'>

                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header onClick={() => handleAccordionOpen('0')}>
                            <div className='form-group required p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>Company summary:</label>{" "}<span>{spanContent}</span>
                                {
                                    briefError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> {briefError} </span>{" "}
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='form-group required p-0'>
                                {/* <label htmlFor='Brief'>Outline a brief overview of your company </label> */}
                            
                                <textarea
                                    id='Brief'
                                    className='form-control mt-2'
                                    maxLength={1000}
                                    value={formState?.company_reason}
                                    onChange={handleChange}
                                />
                            </div>
                            {formState?.company_reason?.length}/1000
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header onClick={() => handleAccordionOpen('1')}>
                            <div className='form-group required p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>Where is your company headquartered?</label><span>{isDisplayIsvBased === false ? ISO3166.getCountry(formState?.location) : ""}</span>
                                {
                                    companyHeadCarterError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> {companyHeadCarterError} </span>{" "}
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='form-group required p-0'>
                                <select className='form-control mt-2' name="location" value={formState?.location} onChange={(e) => { updateState(e.target.name, e.target.value); }}>
                                    {renderCountryOptions(isvBased?.data)}
                                </select>

                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header onClick={() => handleAccordionOpen('2')}>
                            <div className='form-group required p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>In what year was your company founded?</label>{" "}<span>{isDisplayYear === false ? formState?.founded : ""}</span>
                                {
                                    yearError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> {yearError} </span>{" "}
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='form-group required p-0'>
                                {/* <label htmlFor=''>In what year was your business founded?</label> */}
                                <select
                                    className='form-control mt-2'
                                    name='founded'
                                    onChange={(e) => { updateState(e.target.name, e.target.value); }}
                                    value={formState?.founded}
                                >
                                    <option value="">Select an option ...</option>
                                    {
                                        years?.map((el: any, index: any) => (
                                            <option key={index} value={el}>{el}</option>
                                        ))
                                    }
                                </select>

                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header onClick={() => handleAccordionOpen('3')}>
                            <div className='form-group required p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>How many people does your company employ?</label>{" "}<span>{isDisplayEmploye === false ? employeesData?.data?.find((el: any) => el?.id == formState?.company_size)?.attributes?.Label : ""}</span>
                                {
                                    employeError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> {employeError} </span>{" "}
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='form-group required p-0'>
                                {/* <label htmlFor=''>How many people does your company employ? </label> */}
                                <select className='form-control mt-2' name='company_size' value={formState?.company_size} onChange={(e) => { updateState(e.target.name, e.target.value); }}>
                                    {renderOptions(employeesData?.data)}
                                </select>

                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header onClick={() => handleAccordionOpen('4')}>
                            <div className='form-group required p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>Specify how may acquisitions you have made in the past 5 years</label>{" "}<span>{isDisplayAcquisition === false ? acquisitionsData?.data?.find((el: any) => el?.id == formState?.recent_acquisition)?.attributes?.Label : ""}</span>
                                {
                                    acquisitionError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> {acquisitionError} </span>{" "}
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='form-group required p-0'>
                                {/* <label htmlFor=''>Specify how many acquisitions you have made in the past 5 years</label> */}
                                <select className='form-control mt-2' name='recent_acquisition' value={formState?.recent_acquisition} onChange={(e) => { updateState(e.target.name, e.target.value); }}>
                                    {renderOptions(acquisitionsData?.data)}
                                </select>

                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header onClick={() => handleAccordionOpen('5')}>
                            <div className='form-group required p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>What would be the main purpose of acquisition?</label>{" "}<span>{isDisplayMainAcquisition === false ? mainAcquisition?.data?.find((el: any) => el?.id == formState?.main_acquisition_goal)?.attributes?.Label : ""}</span>
                                {
                                    mainAcquisitionError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> {mainAcquisitionError} </span>{" "}
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='form-group required p-0'>
                                {/* <label htmlFor=''>What would be the main purpose of acquisition?</label> */}
                                <select className='form-control mt-2' name='main_acquisition_goal' value={formState?.main_acquisition_goal} onChange={(e) => { updateState(e.target.name, e.target.value); }}>
                                    {renderOptions(mainAcquisition?.data)}
                                </select>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header onClick={() => handleAccordionOpen('6')}>
                            <div className='form-group not-required p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>What is your overall acquisition budget?</label>{" "}<span className='not'>{isDisplayAcquisitionBudget === false ? acquisitionBudget?.data?.find((el: any) => el?.id == formState?.ma_budget_indication)?.attributes?.Label : ""}</span>
                                {
                                    props?.errorSummarize && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='form-group not-required mb-4 p-0'>
                                {/* <label htmlFor=''>What is your overall acquistion budget? </label> */}
                                <select className='form-control mt-2' name='ma_budget_indication' value={formState?.ma_budget_indication} onChange={(e) => { updateState(e.target.name, e.target.value); }}>
                                    {renderOptions(acquisitionBudget?.data)}
                                </select>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header onClick={() => setActiveSection('7')}>
                            <div className='form-group required p-0 overview'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>What vertical industry(ies) do you serve?</label>{" "}<span>{formState?.targeted_industry?.length > 0 ? formState.targeted_industry?.length + ' industry(ies) selected' : null}</span>

                                {
                                    industryError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> {industryError} </span>{" "}
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <IndustriesList
                                industriesSelected={formState?.targeted_industry}
                                addIndustry={addTargetedIndustry}
                                removeIndustry={removeTargetedIndustry}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                        <Accordion.Header onClick={() => setActiveSection('8')}>
                            <div className='form-group required p-0 overview'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>What horizontal solution(s) do you provide?</label>{" "}
                                <span>
                                    {isDisplaySolution === false &&
                                        filteredSolution(allSolution)?.length > 0 ?
                                        filteredSolution(allSolution)?.length + ' solution(s) selected'
                                        : null}
                                </span>


                                {
                                    solutionError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> {solutionError} </span>{" "}
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <SolutionsList
                                featuredSolutionSelected={formState?.featured_solution}
                                solutionsSelected={formState?.software_solution}
                                addFeaturedSolution={addFeaturedSolution}
                                addSoftwareSolution={addSoftwareSolution}
                                removeSolution={removeSolution}
                                removeItemSelected={removeItemSelected}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9" id='accord'>
                        <Accordion.Header onClick={() => setActiveSection('9')}>
                            <div className='form-group required p-0 overview'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>What is your geographic sales footprinteee?</label>{" "}<span>{formState?.geographic_buyer?.length > 0 ? formState?.geographic_buyer?.length + ' country(ies) selected' : null}</span>

                                {
                                    geographicSalesError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> {geographicSalesError} </span>{" "}
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='form-group required p-0 geographic-container'>
                                <p style={{ color: "#444" }}>Select all that apply</p>
                                <div className="geographicList">
                                    <Accordion defaultActiveKey={activeGeoId}>
                                        <Countries
                                            geographicData={geographicData}
                                            activeId={activeGeoId}
                                            toggleActive={toggleActiveGeo}
                                            data={formState?.geographic_buyer}
                                            stateName={"geographic_buyer"}
                                            updateState={updateState}
                                            isEdit={true}
                                        />
                                    </Accordion>

                                </div>
                            </div>
                        </Accordion.Body>

                    </Accordion.Item>

                </Accordion>

            </Modal.Body>
            <Modal.Footer>
                <div className='contentBorder formForEmail'>
                    {!isAccordionOpen && (
                        <>
                            <button
                                style={{ marginLeft: "1rem" }}
                                className='btn btn-devinsider px-5'
                                onClick={() => handleCloseModal()}
                            >
                                Close
                            </button>
                        </>
                    )}
                    {isAccordionOpen && (
                        <>
                            <button
                                style={{ marginLeft: "1rem" }}
                                className='btn btn-devinsider px-5 cancel'
                                onClick={() => handleCloseModal()}
                            >
                                Cancel
                            </button>
                            <button
                                className='btn btn-devinsider save text-white'
                                onClick={() => handleSave()}
                            >
                                {isUpdating ? <Loader /> : "Save"}
                            </button>
                        </>
                    )}
                </div>
            </Modal.Footer >
        </Modal>
    );
}
function forceUpdate() {
    throw new Error("Function not implemented.");
}


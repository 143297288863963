import { useEffect, useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import { useMutation } from "react-query";
import { UseUpdateSeller, useGetListingById } from "../../../../api/seller";
import { ReactComponent as Loader } from '../../../../resources/images/Rolling.svg';

export default function ValueDriverEdit(props: any) {
    const [enabled, setEnabled] = useState(false);

    const { data, refetch } = useGetListingById(props?.idSeller, enabled);

    const mutUpdate = useMutation({mutationFn:UseUpdateSeller,
        retry: 0,
        onSuccess: (data) => {
            props.setIsLoading(false);
            props.handleOk();
            props.setData(formState);
        },
        onSettled: (data, error, variables, context) => {
            setIsAccordionOpen(false);
            refetch();
            setActiveId('');
        }
    });

    useEffect(() => {
        if (props?.idSeller) setEnabled(true);
        if (data) setEnabled(false);
    }, [data, props?.idSeller]);
    
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [activeId, setActiveId] = useState<string|null>("");

    const getInitialState = () => {
        return {
            ...props?.data,
            main_value: props?.value?.value_driver === -1 ? 0 : props?.value?.value_driver,
            key_assets: props?.value?.key_assets === "manda_deleted_manda" ? "" : props?.value?.key_assets,
            target_audience: props?.value?.targeted_audience === "manda_deleted_manda" ? "" : props?.value?.targeted_audience,
            top_competitors: props?.value?.top_competitors === "manda_deleted_manda" ? "" : props?.value?.top_competitors
        }
    }

    const [formState, setFormState] = useState(getInitialState());

    useEffect(() => {
        if(props?.show) setFormState(getInitialState());
    }, [props?.show]);

    function toggleActive(id: string|null) {
        if (activeId === id) {
            setActiveId("");
        } else {
            setActiveId(id);
        }
    }

    const updateState = (property: string, value: any) => {
        setFormState((prevState: any) => ({
            ...prevState,
            [property]: value,
        }));
    }

    const save = () => {
        if (Number(formState?.main_value) === 0) {
            props?.setErrorMainValue(true);
        }
        if (formState?.key_assets === "") {
            props?.setErrorKeyAssets(true);
        }
        if (formState?.target_audience === "") {
            props?.setErrorTargetAudience(true);
        }
        
        if(Number(formState?.main_value) !== 0 && formState?.key_assets !== "" && formState?.target_audience !== "") {
            props.setIsLoading(true);
            let param = {
                idSeller: props?.idSeller,
                value_driver: Number(formState?.main_value),
                key_assets: formState?.key_assets,
                target_audience: formState?.target_audience,
                top_competitors: formState?.top_competitors,
            }
    
            mutUpdate.mutateAsync(param);
        }
    }

    // Enlever les erreurs lors d'un modif
    useEffect(() => {
        if(Number(formState?.main_value) !== 0)  props?.setErrorMainValue(false);
        if(formState?.key_assets !== "")  props?.setErrorKeyAssets(false);
        if(formState?.target_audience !== "")  props?.setErrorTargetAudience(false);
    }, [formState?.main_value, props, formState?.key_assets, formState?.target_audience]);

    const handleAccordionOpen = (eventKey: string | null) => {
        if (eventKey && eventKey !== isAccordionOpen.toString()) {
            setIsAccordionOpen(true);
        }
    };

    const setActiveSection = (eventKey: string | null) => {
        handleAccordionOpen(eventKey);
        toggleActive(eventKey);
    };

    const handleExit = () => {
        setIsAccordionOpen(false);
        setActiveId('');
        return  props.handleOk && props.handleOk();
    }

    return (
        <Modal
            show={props.show}
            className='createCompanypopup large forStep5custom buyer-modal'
        >
            <Modal.Header>
                <div className='headerpopupCompany noStep'>
                    <h2 className='title'>Value drivers</h2>
                    <a
                        className='close'
                        onClick={() => !props.isLoading && handleExit()}
                    >
                        <span className='icon-close-pop'></span>
                    </a>
                </div>
            </Modal.Header>

            <Modal.Body className='modal-item-edit'>

                <Accordion defaultActiveKey={activeId}>

                    <Accordion.Item eventKey="0">
                        <Accordion.Header onClick={() => setActiveSection("0")}>
                            <div className='form-group required p-0'>
                                <label htmlFor='' style={{fontWeight: 600, marginRight:"1rem", display: "inline-block"}}>Where does the main value of your business come from?</label>{" "}<span className="hidable" style={{marginLeft:"1rem"}}>{props?.dataMainValueBusiness?.data?.find((el: any) => el?.id == formState?.main_value)?.attributes?.Label}</span>
                                {
                                    props?.errorMainValue && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <select className='form-control mt-2'
                                name='main_value'
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            >
                                <option value={0}>Select an option ...</option>
                                {
                                    props?.dataMainValueBusiness?.data?.map((el: any, index: any) => (
                                        <option selected={el?.id == formState?.main_value} value={el?.id} key={index}>{el?.attributes?.Label}</option>
                                    ))
                                }
                            </select>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1">
                        <Accordion.Header onClick={() => setActiveSection("1")}>
                            <div className='form-group required p-0'>
                                <label htmlFor='' style={{fontWeight: 600, marginRight:"1rem", display: "inline-block"}}>Describe your key assets:</label>{" "}<span className="hidable">{formState?.key_assets}</span>
                                {
                                    props?.errorKeyAssets && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <textarea className='form-control mt-2'
                                maxLength={500}
                                value={formState?.key_assets}
                                name="key_assets"
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            />
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                        <Accordion.Header onClick={() => setActiveSection("2")}>
                            <div className='form-group required p-0'>
                                <label htmlFor='' style={{fontWeight: 600, marginRight:"1rem", display: "inline-block"}}>Describe your target audience:</label>{" "}<span className="hidable">{formState?.target_audience}</span>
                                {
                                    props?.errorTargetAudience && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <textarea className='form-control mt-2'
                                maxLength={500}
                                value={formState?.target_audience}
                                name="target_audience"
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            />
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                        <Accordion.Header onClick={() => setActiveSection("3")}>
                            <div className='form-group p-0'>
                                <label htmlFor='' style={{fontWeight: 600, marginRight:"1rem", display: "inline-block"}}>Who are your top 3 competitors?</label>{" "}<span className="hidable">{formState?.top_competitors}</span>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <textarea className='form-control mt-2'
                                value={formState?.top_competitors}
                                maxLength={500}
                                name="top_competitors"
                                onChange={(evt) => updateState(evt.target.name, evt.target.value)}
                            />
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>

            </Modal.Body>
            <Modal.Footer>
                <div className='text-align-right'>
                    {!isAccordionOpen && (
                        <>
                            <button
                                style={{ marginLeft: "1rem" }}
                                className='btn btn-devinsider save'
                                onClick={() => handleExit()}>
                                Close
                            </button>
                        </>
                    )}
                    {isAccordionOpen && (
                        <>
                            <button
                                style={{ marginLeft: "1rem" }}
                                className='btn btn-devinsider cancel'
                                onClick={() => !props.isLoading && handleExit()}>
                                Cancel
                            </button>
                            <button
                                className='btn btn-devinsider save'
                                onClick={() => !props.isLoading && save()}>

                                {props.isLoading ? <Loader/> : "Save"}
                            </button>
                        </>
                    )}
                </div>
            </Modal.Footer>

        </Modal>
    )
}
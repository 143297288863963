import ISO3166 from 'iso-3166-1-alpha-2';
import { useEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import Chart from 'react-google-charts';
import { useNavigate } from "react-router-dom";
import { useGetAcquisitionType, useGetBusinessModel, useGetFundingRaised, useGetHowManyPeopleEmployed, useGetHowValueBusiness, useGetIndustryFiltre, useGetLifecycleStage, useGetMainValueBusiness, UseGetMySellerListing, useGetProfitability, useGetReasonListingBusiness, useGetRevenueQuarter, useGetSolutionsFiltre, useGetTechnologyPartenership, useGetTimeframes } from "../../../../api/seller";
import { useKeycloakHooks } from "../../../../hooks/keycloak";
import GraphImg from '../../../../resources/images/chart-area.svg';
import { scrollToTop } from '../../../../utils/dom';
import { LoaderCommon, LoadingAllPage } from '../../../../widget/loader';

const PreviewSeller = () => {
    useEffect(() => {
        scrollToTop();
    }, [])

    const navigate = useNavigate();

    const { keycloak, tokenInfo, initialized } = useKeycloakHooks();

    const [enabledData, setEnabledData] = useState(true);
    const { data: dataHowManyPeople } = useGetHowManyPeopleEmployed(enabledData);
    const { data: dataBusinessModel } = useGetBusinessModel(enabledData);
    const { data: dataLifecycle } = useGetLifecycleStage(enabledData);
    const { data: dataHowValueBusiness } = useGetHowValueBusiness(enabledData);
    const { data: dataSolutions } = useGetSolutionsFiltre(enabledData);
    const { data: dataIndustries } = useGetIndustryFiltre(enabledData);
    const { data: dataTechnologyPartenership } = useGetTechnologyPartenership(enabledData);
    const { data: dataReasonListing } = useGetReasonListingBusiness(enabledData);
    const { data: dataAcquisitions } = useGetAcquisitionType(enabledData);
    const { data: dataTimeframes } = useGetTimeframes(enabledData);
    const { data: dataMainValueBusiness } = useGetMainValueBusiness(enabledData);
    const { data: dataFundingRaised } = useGetFundingRaised(enabledData);
    const { data: dataRevenue } = useGetRevenueQuarter(enabledData);
    const { data: dataProfitabilityBusiness } = useGetProfitability(enabledData);

    const [enabledMyListing, setEnabledMyListing] = useState(true);

    const { data, isFetched: isMyListingFetched, isLoading: isMyListingLoading } = UseGetMySellerListing(enabledMyListing);


    useEffect(() => {
        if (dataHowManyPeople && dataBusinessModel && dataLifecycle && 
            dataHowValueBusiness && dataSolutions && dataIndustries &&
            dataTechnologyPartenership && dataReasonListing && dataAcquisitions &&
            dataTimeframes && dataMainValueBusiness && dataFundingRaised &&
            dataRevenue && dataProfitabilityBusiness
        ) {
            setEnabledData(false);
        }
        }, [dataHowManyPeople, dataBusinessModel, dataLifecycle, 
            dataHowValueBusiness, dataSolutions, dataIndustries,
            dataTechnologyPartenership, dataReasonListing, dataAcquisitions,
            dataTimeframes, dataMainValueBusiness, dataFundingRaised,
            dataRevenue, dataProfitabilityBusiness
    ]);

    const featuredSolutions = data?.seller?.featured_solution || [];
    const softwareSolutions = data?.seller?.software_solution || [];
    const allSolutions = [...softwareSolutions, ...featuredSolutions];

    const filteredSolution = (solution: any) => {
        let alreadyExist: Array<string> = [];
        solution?.map((el: any) => {
            let name = dataSolutions?.data?.find((e: any) => e?.id === el)?.attributes?.Label;
            if(alreadyExist.indexOf(name) === -1) {
                alreadyExist.push(name);
            }
        })
        return alreadyExist;
    }

    useEffect(() => {
        if (tokenInfo?.user_uuid) setEnabledMyListing(true);
        if (isMyListingFetched) setEnabledMyListing(false);
    }, [tokenInfo?.user_uuid, isMyListingFetched])

    const goToHomePage = () => {
        navigate("/");
    };

    useEffect(() => {
        if (initialized && !keycloak.authenticated) goToHomePage();
    }, [keycloak.authenticated, keycloak.token]);

    const disabledClass = data?.seller?.is_removed ? 'disabled' : '';

    const geographicData = data && data?.seller?.geographic_seller ? data?.seller?.geographic_seller?.reduce((acc: any, current: any) => {
        if (!current) {
            return acc;
        }
        const continent = current?.council_region;
        const countryInfo = {
            ISO2: current?.iso2,
            Name: current?.country,
        };

        if (!acc[continent]) {
            acc[continent] = {
                Continent: continent,
                Countries: [countryInfo],
            };
        } else {
            acc[continent].Countries.push(countryInfo);
        }

        return acc;

    }, {}) : {};

    const [activeId, setActiveId] = useState<any>("null");
    function toggleActive(id: any) {
        if (activeId === id) {
            setActiveId("null");
        } else {
            setActiveId(id);
        }
    }

    const handleExitPreview = () => {
        // navigate(`/seller/listing/details/${data?.seller?.id}`);
        window.close();
    };

    const revenueDataChart = data && data?.seller?.revenue_generated
    ? data?.seller?.revenue_generated.map((row: any) => {
        return [
            `${row.year}`,
            row.amount_value,
            "#ff6600"
        ];
    }).sort((a: any, b: any) => parseInt(a[0]) - parseInt(b[0]))
    : [];

    const profitDataChart = data && data?.seller?.profit_mades
        ? data?.seller?.profit_mades.map((row: any) => {
        return [
            `${row.year}`,
            row.amount_value,
            "#ff6600"
        ];
    }).sort((a: any, b: any) => parseInt(a[0]) - parseInt(b[0]))
    : [];

    function formatBusinessModels(businessModels: string[] | undefined): string {
        if (!Array.isArray(businessModels) || businessModels.length === 0) {
            return '';
        }
        return businessModels.map((model, index) => `${dataBusinessModel?.data?.find((el: any) => el?.id == model)?.attributes?.Label}`).join(' | ');
    }

    const [activeSection, setActiveSection] = useState('Opportunity');

    const opportunityRef = useRef(null);
    const companyOverviewRef = useRef(null);
    const goalRef = useRef(null);
    const valueDriverRef = useRef(null);

    const handleClickMenu = (ref: any, section: string) => {
        const elementPosition = ref.current.getBoundingClientRect().top + window.pageYOffset;
        const adjustment = 130; // Ajustez cette valeur en fonction de vos besoins
        window.scrollTo({
            top: elementPosition - adjustment,
            behavior: 'smooth'
        });
        setActiveSection(section);
    };

    return (
        <>
            {(!initialized || !data || 
                !dataBusinessModel || !dataHowManyPeople || !dataLifecycle || !dataHowValueBusiness || 
                !dataSolutions || !dataIndustries || !dataTechnologyPartenership || 
                !dataReasonListing || !dataAcquisitions || !dataTimeframes ||
                !dataMainValueBusiness || !dataFundingRaised || !dataRevenue ||
                !dataProfitabilityBusiness
            ) && <LoadingAllPage/>}
            {(initialized && dataBusinessModel && 
            dataHowManyPeople && dataLifecycle && dataHowValueBusiness && 
            dataSolutions && dataIndustries && dataTechnologyPartenership &&
            dataReasonListing && dataAcquisitions && dataTimeframes && 
            dataMainValueBusiness && dataRevenue && dataProfitabilityBusiness
            ) && (
                <div className='seller' id="preview">
                    {isMyListingLoading && <div className='forBillingCenter pt-3'> <LoaderCommon /></div>}
                    {isMyListingFetched &&
                        <>
                            <div className='contentBorder formForEmail'>
                                <button
                                    style={{ marginLeft: "1rem" }}
                                    className='btn btn-devinsider px-5 cancel'
                                    onClick={() => handleExitPreview()}
                                >
                                    Exit preview
                                </button>
                            </div>
                            <div className="findIsvcontainer programContainer sellerContainer">
                                <div className='filterFindIsv previewFixed hScroll'>
                                    <div className='contentFilter'>
                                        <div className='matchFilter'>
                                            <ul className='align-items-center'>
                                                <li className='d-flex align-items-center flex-column p-0 m-0'>
                                                    <span className='icon-building ico m-0'></span>
                                                    <div className='match m-0'>
                                                        <span>match</span>
                                                        <span>100%</span>
                                                    </div>
                                                </li>
                                                <li>{dataLifecycle?.data?.find((el: any) => el?.id == data?.seller?.stage)?.attributes?.Label}</li>
                                                <li><span className='icon-star-off'></span>
                                                </li>
                                            </ul>
                                        </div>
                                        <button
                                            className='btn btn-devinsider px-5 cancel mt-0 not-hover'
                                        >
                                            Send a message
                                        </button>
                                        <div className={`block titleLeft border-0 ${activeSection === 'Opportunity' ? 'active' : ''}`} onClick={() => handleClickMenu(opportunityRef, 'Opportunity')}>
                                            <span className="txtL">1</span> <span className='txt'>Opportunity in short</span>
                                        </div>
                                        <div className={`block titleLeft border-0 ${activeSection === 'Company overview' ? 'active' : ''}`} onClick={() => handleClickMenu(companyOverviewRef, 'Company overview')}>
                                            <span className="txtL">2</span> <span className='txt'>Company overview</span>
                                        </div>
                                        <div className={`block titleLeft border-0 ${activeSection === 'Goal' ? 'active' : ''}`} onClick={() => handleClickMenu(goalRef, 'Goal')}>
                                            <span className="txtL">3</span> <span className='txt'>Goal</span>
                                        </div>
                                        <div className={`block titleLeft border-0 ${activeSection === 'Value driver' ? 'active' : ''}`} onClick={() => handleClickMenu(valueDriverRef, 'Value driver')}>
                                            <span className="txtL">4</span> <span className='txt'>Value drivers</span>
                                        </div>
                                    </div>
                                </div>
        
                                <div className='container mx-auto minHeightwin sellerRight'>
                                    <div className='listOfIsv completePage'>
                                        <div className='scrollContainer'>
                                            <div className='contentUser' ref={opportunityRef}>
                                                <h2 className='title'>Opportunity in short/snapshot</h2>
                                                <span className={`txt ${disabledClass}`}>
                                                    {data?.seller?.opportunity}
                                                </span>
                                            </div>
                                            <div className='contentUser' ref={companyOverviewRef}>
                                                <h2 className='title'>Company overview</h2>
                                                <span className={`txt ${disabledClass}`}>
                                                    {data?.seller?.company_reason}
                                                </span>
                                                <div className='details'>
                                                    <ul className='listDetails'>
                                                        <li className="item">
                                                            <span className="ico icon-location"></span>
                                                            <div>Location</div>
                                                            <span className={`txt ${disabledClass}`}>{ISO3166.getCountry(data?.seller?.location)}</span>
                                                        </li>
                                                        <li className="item">
                                                            <span className="ico icon-founded"></span>
                                                            <div>Founded</div>
                                                            <span className={`txt ${disabledClass}`}>{data?.seller?.founded}</span>
                                                        </li>
                                                        <li className="item">
                                                            <span className="ico icon-compSize"></span>
                                                            <div>Company size</div>
                                                            <span className={`txt ${disabledClass}`}>{dataHowManyPeople?.data?.find((el: any) => el?.id == data?.seller?.company_size)?.attributes?.Label}</span>
                                                        </li>
                                                        <li className="item">
                                                            <span className="ico icon-IP-sales"></span>
                                                            <div>Business model</div>
                                                            <span className={`txt ${disabledClass}`}>{formatBusinessModels(data?.seller?.business_model)}</span>
                                                        </li>
                                                        <li className="item">
                                                            <span className="ico icon-stage"></span>
                                                            <div>Stage</div>
                                                            <span className={`txt ${disabledClass}`}>{dataLifecycle?.data?.find((el: any) => el?.id == data?.seller?.stage)?.attributes?.Label}</span>
                                                        </li>
                                                        <li className="item">
                                                            <span className="ico icon-valuation"></span>
                                                            <div>Valuation</div>
                                                            <span className={`txt ${disabledClass}`}>{dataHowValueBusiness?.data?.find((el: any) => el?.id == data?.seller?.valuation)?.attributes?.Label}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div>
                                                    <h2 className='title small'>Software solution (Horizontal)</h2>
                                                    <ul className='listTopic'>
                                                        {
                                                            filteredSolution(allSolutions)?.map((el: any) => (
                                                                <>
                                                                    <span className={`topic ${disabledClass}`}>{el}</span>
                                                                </>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                                <div>
                                                    <h2 className='title small'>Targeted Industry (Vertical)</h2>
                                                    <ul className='listTopic'>
                                                        {
                                                            data?.seller?.targeted_industry?.map((el: any) => (
                                                                <>
                                                                    <span className={`topic ${disabledClass}`}>{dataIndustries?.data?.find((element: any) => el == element?.id)?.attributes?.Label}</span>
                                                                </>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                                <div>
                                                    <h2 className='title small'>Geographic sales footprint</h2>
                                                    <div className="geographicList">
                                                        <Accordion defaultActiveKey={activeId}>
                                                            {
                                                                geographicData && Object.values(geographicData)?.map((item: any, index: number) => {
                                                                    return (
                                                                        <div
                                                                            className={
                                                                                activeId == index.toString()
                                                                                    ? "panel-wrap active-panel"
                                                                                    : "panel-wrap"
                                                                            }
                                                                            key={index}
                                                                        >
                                                                            <Accordion.Item eventKey={index.toString()}>
                                                                                <div className="panel-header">
                                                                                    <Accordion.Header
                                                                                        onClick={() => toggleActive(index.toString())}
                                                                                        className="panel-toggle selectedItem"
                                                                                    >
                                                                                        <span className={`topic ${disabledClass}`}>    {item && item?.Continent}</span>
                                                                                    </Accordion.Header>
                                                                                </div>
        
                                                                                <Accordion.Body>
                                                                                    {
                                                                                        item &&
                                                                                        item?.Countries &&
                                                                                        item?.Countries?.map((pays: any, key: number) => {
                                                                                            return (
                                                                                                <div className="panel-body" key={key}>
                                                                                                    <span className={`topic ${disabledClass}`}>
                                                                                                        {pays?.Name}
                                                                                                    </span>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>
        
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </Accordion>
        
                                                    </div>
                                                </div>
                                                {/* {
                                                    data?.seller?.have_technology && (
                                                        <div>
                                                            <h2 className='title small'>Technology partnerships</h2>
                                                            <ul className='listTopic'>
                                                                {
                                                                    data?.seller?.technology_partnership?.map((el: any) => (
                                                                        <>
                                                                            <span className={`topic ${disabledClass}`}>{el}</span>
                                                                        </>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </div>
                                                    )
                                                } */}
                                            </div>
                                            <div className='contentUser' ref={goalRef}>
                                                <h2 className='title'>Goal</h2>
                                                <div>
                                                    <h2 className='title small'>Reason for listing</h2>
                                                    <ul className='listTopic'>
                                                        <span className={`topic ${disabledClass}`}>{dataReasonListing?.data?.find((el: any) => el?.id == data?.seller?.reason_listing)?.attributes?.Label}</span>
                                                    </ul>
                                                    <span>
                                                        {data?.seller?.desc_reason_listing}
                                                    </span>
                                                    <div>
                                                        <h2 className='title small'>Desired acquisition type</h2>
                                                        <ul className='listTopic'>
                                                            <span className={`topic ${disabledClass}`}>{dataAcquisitions?.data?.find((el: any) => el?.id == data?.seller?.desired_acquisition)?.attributes?.Label}</span>
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <h2 className='title small'>Urgency</h2>
                                                        <ul className='listTopic'>
                                                            <span className={`topic ${disabledClass}`}>{dataTimeframes?.data?.find((el: any) => el?.id == data?.seller?.urgency)?.attributes?.Label}</span>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='contentUser' ref={valueDriverRef}>
                                                <h2 className='title'>Value drivers</h2>
                                                <div>
                                                    <h2 className='title small'>Top value driver</h2>
                                                    <ul className='listTopic'>
                                                        <span className={`topic ${disabledClass}`}>{dataMainValueBusiness?.data?.find((el: any) => data?.seller?.value_driver)?.attributes?.Label}</span>
                                                    </ul>
                                                    <div>
                                                        <h2 className='title small'>Key assets</h2>
                                                        <span className={`txt ${disabledClass}`}>{data?.seller?.key_assets}</span>
                                                    </div>
                                                    <div>
                                                        <h2 className='title small'>Target audience description</h2>
                                                        <span className={`txt ${disabledClass}`}>{data?.seller?.targeted_audience}</span>
                                                    </div>
                                                    {
                                                        data?.seller?.top_competitors && (
                                                        <div>
                                                            <h2 className='title small'>Top competitor</h2>
                                                            <span className={`txt ${disabledClass}`}>{data?.seller?.top_competitors}</span>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className='contentUser'>
                                                <h2 className='title'>Financial</h2>
                                                <div>
                                                    <h2 className='title small'>Ownership structure</h2>
                                                    <span className={`txt ${disabledClass}`}>{data?.seller?.structure_ownership}</span>
                                                    <div className='details'>
                                                        <ul className='listDetails justify-content-between'>
                                                            <li className="item">
                                                                <span className="ico icon-profitability"></span>
                                                                <div>Raised funds</div>
                                                                <span className={`txt ${disabledClass}`}>{dataFundingRaised?.data?.find((el: any) => el?.id == data?.seller?.raised_funds)?.attributes?.Label}</span>
                                                            </li>
                                                            <li className="item">
                                                                <span className="ico icon-sellers"></span>
                                                                <div>Last quarter revenue</div>
                                                                <span className={`txt ${disabledClass}`}>{dataRevenue?.data?.find((el: any) => el?.id == data?.seller?.quarter_revenue)?.attributes?.Label}</span>
                                                            </li>
                                                            <li className="item">
                                                                <span className="ico icon-compSize"></span>
                                                                <div>Profitability</div>
                                                                <span className={`txt ${disabledClass}`}>{dataProfitabilityBusiness?.data?.find((el: any) => el?.id == data?.seller?.profitability)?.attributes?.Label}</span>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    {
                                                        data?.seller?.revenue_generated?.some((el: any) => el?.amount_value !== 0) && (
                                                            <div>
                                                                <h2 className='title small'>Total revenue generate over the past 3 years</h2>
                                                                {
                                                                    revenueDataChart?.length > 0 && !data?.seller?.is_removed && (
                                                                        <Chart chartType="ColumnChart" data={[["Year", "Amount", { role: "style" }], ...revenueDataChart]} options={{ legend: 'none', hAxis: { title: 'Year', titleTextStyle: { italic: false, bold: true } }, vAxis: { title: 'Amount ($)', titleTextStyle: { italic: false, bold: true } } }} />
                                                                    )
                                                                }
                                                                {
                                                                    data?.seller?.is_removed && (
                                                                        <>
                                                                        <ul className='listDetails'>
                                                                            <li className="item">
                                                                            <img
                                                                                src={GraphImg}
                                                                                width={50}
                                                                                height={200}
                                                                            />
                                                                            </li>
                                                                        </ul>
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                   
                                                    {
                                                        data?.seller?.profit_mades?.some((el: any) => el?.amount_value !== 0) && (
                                                            <div>
                                                                <h2 className='title small'>Total profit made in the past 3 years</h2>
                                                                {
                                                                    profitDataChart?.length > 0 && !data?.seller?.is_removed && (
                                                                        <Chart chartType="ColumnChart" data={[["Year", "Amount", { role: "style" }], ...profitDataChart]} options={{ legend: 'none', hAxis: { title: 'Year', titleTextStyle: { italic: false, bold: true } }, vAxis: { title: 'Amount ($)', titleTextStyle: { italic: false, bold: true } } }} />
                                                                    )
                                                                }
                                                                {
                                                                    data?.seller?.is_removed && (
                                                                        <>
                                                                        <ul className="listDetails">
                                                                            <li className="item">
                                                                            <img
                                                                                src={GraphImg}
                                                                                width={50}
                                                                                height={200}
                                                                            />
                                                                            </li>
                                                                        </ul>
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    

                                                </div>
                                            </div>
                                            <div className='pagginationCategory'>
                                                <div className='pagination'>
        
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
             </div>
            )}
        </>
    )
}

export default PreviewSeller;
import ISO3166 from 'iso-3166-1-alpha-2';
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { UseGetMyBuyerMatchingListing, UseAddToFavorite, useGetUserSubscription, useGetMyBuyerListing } from "../../../../api/buyer";
import { useGetTypeUser } from "../../../../api/user";
import { useKeycloakHooks } from "../../../../hooks/keycloak";
import SellerSecondMenu from '../../../../layouts/second-headers/seller';
import BuyerSecondMenu from '../../../../layouts/second-headers/buyer';
import { LoaderCommon } from "../../../../widget/loader";
import '../../buyer.scss';
import { addToArray, paginate } from '../../../../utils/outils';
import SeeBtn from '../../../../components/seeBtn';
import { useMutation, useQuery } from 'react-query';
import SellerListing from '../../../seller/listing';
import { useGetHowManyPeopleEmployed, useGetHowValueBusiness, useGetLifecycleStage, useGetMainValueBusiness, UseGetMySellerListing, useGetProfitability } from '../../../../api/seller';
import { useGetStaticParams } from '../../../../api/staticParams';
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";

interface ISellerData {
    id: string;
    matching: number;
    isFavorite: boolean;
    isUnlock: boolean;
    seller: {
        id: number;
        stage: string;
        company_reason: string;
        location: string;
        founded: string;
        company_size: string;
        value_driver: string;
        valuation: string;
        profitability: string;
        targeted_industry: string[];
        software_solution: string[];
        featured_solution: string[];
        user_account: {
            is_premium: boolean;
        }
    }


}


export default function MyMatchingListing() {
    const navigate = useNavigate();

    const { keycloak, tokenInfo, initialized } = useKeycloakHooks();

    const [enableCheckSubscription, setEnabledCheckSubscription] = useState(true);
    const { data: checkSubscription, refetch: refetchCheck, isLoading } = useGetUserSubscription(enableCheckSubscription);

    const [enabledMySellerListing, setEnabledMySellerListing] = useState(false);
    const { data: sellerListing } = UseGetMySellerListing(enabledMySellerListing);

    const [enabledGetMyBuyerListing, setEnabledGetMyBuyerListing] = useState(false);
    const { data: buyerListing } = useGetMyBuyerListing(enabledGetMyBuyerListing);

    const [savedplanType, setplanType] = useState<number|null>(null);

    const [enabled, setEnabled] = useState(false);
    const { data: userRoles, isLoading: userRolesLoading } = useGetTypeUser(tokenInfo, enabled);
    const [enabledGetMyBuyer, setEnabledGetMyBuyer] = useState(false);
    const { data: myBuyer } = useGetMyBuyerListing(enabledGetMyBuyer);

    const [enableData, setEnableData] = useState(true);
    const { data: horizontals, isFetched: isHorizontalsFetched } = useGetStaticParams('HORIZONTALS', enableData);
    const { data: verticals, isFetched: isVerticalsFetched } = useGetStaticParams('VERTICALS', enableData);
    const { data: dataLifecycle, isFetched: isStageFetched } = useGetLifecycleStage(enableData);
    const { data: dataHowManyPeople, isFetched: isCompanySizeFetched } = useGetHowManyPeopleEmployed(enableData);
    const { data: dataMainValueBusiness, isFetched: isMainValueFetched } = useGetMainValueBusiness(enableData);
    const { data: dataHowValueBusiness, isFetched: isValuationFetched } = useGetHowValueBusiness(enableData);
    const { data: dataProfitabilityBusiness, isFetched: isProfitabilityFetched } = useGetProfitability(enableData);

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [refetchSource, setRefetchSource] = useState("");

    const [params, setParams] = useState({
        location: [] as string[],
        horizontal: [] as string[],
        vertical: [] as string[],
    });

    const mutGetMatching = useMutation({
        mutationFn: UseGetMyBuyerMatchingListing,
        onSuccess: (data) => {
            if (data.status === 200) {
                refetchCheck();
            } else {
                refetchCheck();
            }
            setIsDataLoading(false);
        },
        onError: () => {
            setIsDataLoading(false); 
        }
    });

    const fetchMyMatching = async (savedplanType: any, params: any): Promise<any> => {
        if (isInitialLoading || refetchSource !== "favorite") {
            setIsDataLoading(true);
        }
        const dataParam = {
            planType: savedplanType,
            params: params
        };

        try {
            const response = await mutGetMatching.mutateAsync(dataParam);
            return response;
        }catch (error) {
            console.error("Error fetching matching data:", error);
            throw error;
        } finally {
            setIsInitialLoading(false);
            setIsDataLoading(false);
        }
        
    };

    const useMyBuyerMatchingListing = (savedplanType: any, enabled: boolean) => {
        const { data, isFetched, refetch, isLoading } = useQuery(
            ['myBuyerMatching', savedplanType],
            () => fetchMyMatching(savedplanType, {}),
            {
                enabled: enabled
            }
        );
        return {
            data: data,
            isFetched: isFetched,
            refetch: refetch,
            isLoading: isLoading
        };
    };

    useEffect(() => {
        if (tokenInfo?.user_uuid) {
            setEnabled(true);
            setEnabledMySellerListing(true);
            setEnabledGetMyBuyerListing(true);
        }
    }, [myBuyer, tokenInfo?.user_uuid, userRoles])

    useEffect(() => {
        if (isHorizontalsFetched && isVerticalsFetched && isStageFetched &&
            isCompanySizeFetched && isMainValueFetched && isValuationFetched &&
            isProfitabilityFetched
        ) {
         setEnableData(false)
        }
    }, [isHorizontalsFetched, isVerticalsFetched, isStageFetched,
        isCompanySizeFetched, isMainValueFetched, isValuationFetched,
        isProfitabilityFetched
    ]);

    useEffect(() => {
        if (sellerListing) {
            setEnabledMySellerListing(false);
        }
    }, [sellerListing])

    useEffect(() => {
        if (buyerListing) {
            setEnabledGetMyBuyerListing(false);
        }
    }, [buyerListing])

    const [uniqueLocations, setUniqueLocations] = useState<string[]>([]);
    const [uniqueIndustries, setUniqueIndustries] = useState<string[]>([]);
    const [uniqueSolutions, setUniqueSolutions] = useState<string[]>([]);
    const [isMyBuyerListingFetched, setIsMyBuyerListingFetched] = useState<boolean>(true);

    const [filteredDatas, setFilteredDatas] = useState<Array<any>>([]);
    const [initialMatchings, setInitialMatchings] = useState<Array<ISellerData>>([]);

    useEffect(() => {
        setUniqueLocations(Array.from(new Set(initialMatchings?.map((item: any) => item.seller.location))));
        setUniqueIndustries(Array.from(new Set(initialMatchings?.flatMap((item: any) => item.seller.targeted_industry))));
        setUniqueSolutions(Array.from(new Set(initialMatchings?.flatMap((item: any) => [...item.seller.software_solution, ...item.seller.featured_solution]))));

        refetchCheck();
    }, [initialMatchings]);

    const handleGoToFavorite = () => {
        if (keycloak?.authenticated) { }
        else keycloak?.login();
    }

    // const goToDetails = () => {
    //     myBuyerListing && navigate(`/buyer/listing/details/${myBuyerListing.id}`);
    // };

    const [limitSolution, setLimitSolution] = useState(6);
    const [limitIndustry, setLimitIndustry] = useState(6);
    const [limitLocation, setLimitLocation] = useState(6);
    const [selectedSolution, setSelectedSolution] = useState<Array<string>>([]);
    const [selectedIndustry, setSelectedIndustry] = useState<Array<string>>([]);
    const [selectedLocation, setSelectedLocation] = useState<Array<string>>([]);
    const [searchInput, setSearchInput] = useState<string>('');

    // const uniqueLocations = Array.from(new Set(myBuyerListing.map((item: any) => item.seller.location)));

    // const uniqueIndustries = Array.from(new Set(myBuyerListing.flatMap((item: any) => item.seller.targeted_industry)));

    // const uniqueSolutions = Array.from(new Set(myBuyerListing.flatMap((item: any) => [...item.seller.software_solution, ...item.seller.featured_solution])));



    const [isLocationOpen, setIsLocationOpen] = useState(true);
    const [isSolutionOpen, setIsSolutionOpen] = useState(true);
    const [isIndustryOpen, setIsIndustryOpen] = useState(true);

    const toggleFilterSection = (setState: React.Dispatch<React.SetStateAction<boolean>>) => {
        setState(prevState => !prevState);
    };


    const getUniqueSolutionLabels = () => {
        return Array.from(new Set(
          uniqueSolutions?.map((el: any) => 
            horizontals?.data?.find((element: any) => el == element?.id)?.attributes?.Label
          )
        ));
    };

    const handleSeeSolution = (action: string) => {
        const uniqueLabelsCount = getUniqueSolutionLabels()?.length;
        setLimitSolution(action === '0' ? uniqueLabelsCount : 6);
    };

    const handleSeeIndustry = (action: string) => {
        setLimitIndustry(action === '0' ? uniqueIndustries?.length : 6);
    };

    const handleSeeLocation = (action: string) => {
        setLimitLocation(action === '0' ? uniqueLocations.length : 6);
    }

    const handleSelectSolution = (element: any) => {
        setSelectedSolution(addToArray(selectedSolution, element));
    }

    const handleSelectIndustry = (element: string) => {
        setSelectedIndustry(addToArray(selectedIndustry, element));
    }

    const handleSelectLocation = (element: any) => {
        setSelectedLocation(addToArray(selectedLocation, element));
    }



    // const filterBuyerListing = () => {
    //     const param = {
    //         location: selectedLocation,
    //         horizontal: selectedSolution,
    //         vertical: selectedIndustry,
    //     };

    //     const filteredData = myBuyerListing?.filter((el: any) => {
    //         const locationMatch = (!param.location || (Array.isArray(el?.seller.location) && el.seller.location.some((loc: any) => param.location.includes(loc))));
    //         const horizontalMatch = (!param.horizontal || (
    //             Array.isArray(el?.seller.software_solution) &&
    //             Array.isArray(el?.seller.featured_solution) &&
    //             [...el.seller.software_solution, ...el.seller.featured_solution].some(
    //                 (sol: any) => param.horizontal.includes(sol)
    //             )
    //         ));
    //         const verticalMatch = (!param.vertical || (Array.isArray(el?.seller.targeted_industry) && el.seller.targeted_industry.some((ind: any) => param.vertical.includes(ind))));

    //         if (param.location && !param.horizontal && !param.vertical) {
    //             return locationMatch;
    //         } else if (!param.location && param.horizontal && !param.vertical) {
    //             return horizontalMatch;
    //         } else if (!param.location && !param.horizontal && param.vertical) {
    //             return verticalMatch;
    //         } else if (param.location && !param.horizontal && param.vertical) {
    //             return locationMatch || verticalMatch;
    //         } else if (!param.location && param.horizontal && param.vertical) {
    //             return horizontalMatch || verticalMatch;
    //         } else if (param.location && param.horizontal && !param.vertical) {
    //             return verticalMatch || horizontalMatch;
    //         } else {
    //             return locationMatch || horizontalMatch || verticalMatch;
    //         }
    //     });
    //     const finalFilteredData = filteredData.length > 0 ? filteredData : [];
    //     setFilteredDatas(finalFilteredData);

    //     setParams((prevParams) => ({
    //         ...prevParams,
    //         ...(param.location ? { location: param.location } : {}),
    //         ...(param.horizontal ? { horizontal: param.horizontal } : {}),
    //         ...(param.vertical ? { vertical: param.vertical } : {}),
    //     }));
    // };

    const hasFilter = uniqueLocations.length > 0 && uniqueSolutions.length > 0 && filteredDatas.length > 0 && uniqueIndustries.length > 0;

    let shouldDisplayBlock = false;

    if (hasFilter == true) {
        shouldDisplayBlock = true;
    }

    const goToBuyerMatching = () => {
        if(buyerListing && buyerListing?.is_published && !buyerListing?.is_removed)
            navigate("/buyer/listing/my-matching");
    };
    const goToUpgrade = () => {
        navigate("/settings");
    };
    const goToSellerMatching = () => {
        if(sellerListing && sellerListing?.seller && sellerListing?.seller?.is_published && !sellerListing?.seller?.is_removed)
            navigate("/seller/listing/my-matching");
        else 
            navigate("/buyer/listing"); 
    };
    const resetSellerListing = () => {
        let param = {
            location: [],
            horizontal: [],
            vertical: [],
        }
        setSearchInput('');
        setRefetchSource("");
        setSelectedSolution([]);
        setSelectedLocation([]);
        setSelectedIndustry([]);

        setParams((prevParams) => ({
            ...prevParams,
            ...param
        }));

        setFilteredDatas(initialMatchings);
    }
    const goToDetails = (id: any) => {
        navigate(`/seller/listing/detail/matching/${id}`);
    };

    const goToEnrollBuyer = () => {
        navigate("/buyer/listing/creation");
    }

    const isOpportunistic = (el: any) => {
        var isOppo = false;

        if (el?.urgency === "Opportunistic" || el?.reason_listing === "I'm opportunistic and open to receive inquiries from potential buyers") {
            isOppo = true
        }

        return isOppo;
    }

    const isPremium = (el: any) => {
        return el?.user_account?.is_premium === true;
    };


    useEffect(() => {
        if (checkSubscription && checkSubscription.data) {
            let subscription = checkSubscription.data.subscription;
            console.log("subscription", subscription);
            if (subscription) {
                const planType = subscription.type.name === "MOTIVATED BUYER" ? 0 : 1;
                setplanType(planType);
            }
        }
    }, [checkSubscription]);

    useEffect(() => {
        if(savedplanType != null){
            const fetchData = async () => {
                await filterBuyerListing().then((data) => {
                    setInitialMatchings(data);
                    setIsMyBuyerListingFetched(true);
                });
            };

            fetchData();
        }
    }, [savedplanType]);


    const filterBuyerListing = async () => {
        setRefetchSource("");
       setIsDataLoading(true);
        try {
            const param = {
                location: selectedLocation,
                horizontal: selectedSolution,
                vertical: selectedIndustry
            };  
            const response = await fetchMyMatching(savedplanType, param);
            const data = response.data;


            const finalFilteredData = data.length > 0 ? data.map((item: any) => {
                // Try to find the corresponding item in the original data
                const originalItem = initialMatchings.find((original: any) => original.id === item.id);
                // If found, preserve its favorite state
                if (originalItem) {
                    return {
                        ...item,
                        isFavorite: originalItem.isFavorite
                    };
                }
                return item;
            }) : [];

            const sortedData = finalFilteredData.sort((a:ISellerData, b:ISellerData) => {
                if (a.seller.user_account.is_premium && !b.seller.user_account.is_premium) {
                    return -1;
                }
                if (!a.seller.user_account.is_premium && b.seller.user_account.is_premium) {
                    return 1;
                }
                return 0;
            });

            setFilteredDatas(sortedData);
            return sortedData;
        } catch(e) {

        }

        finally {
            setIsDataLoading(false);
        }
    }

    const handleSearch = () => {
        if (searchInput.trim() !== '') {
            const filteredData = filteredDatas.filter((el: any) => {
                const country = ISO3166.getCountry(el?.seller.location);
                const locationMatches = country && country.toLowerCase().includes(searchInput.toLowerCase());
                const founded = el?.seller?.founded?.toLowerCase().includes(searchInput.toLowerCase());
                const stage = dataLifecycle?.data?.find((element: any) => element?.id == el?.seller?.stage)?.attributes?.Label?.toLowerCase().includes(searchInput.toLowerCase());
                const reason = el?.seller?.company_reason?.toLowerCase().includes(searchInput.toLowerCase());
                const valueDriver = dataMainValueBusiness?.data?.find((element: any) => element?.id == el?.seller?.value_driver)?.attributes?.Label?.toLowerCase().includes(searchInput.toLowerCase());
                const companySize = dataHowManyPeople?.data?.find((element: any) => element?.id == el?.seller?.company_size)?.attributes?.Label?.toLowerCase().includes(searchInput.toLowerCase());
                const valueAtion = dataHowValueBusiness?.data?.find((element: any) => element?.id == el?.seller?.valuation)?.attributes?.Label?.toLowerCase().includes(searchInput.toLowerCase());
                const profbility = dataProfitabilityBusiness?.data?.find((element: any) => element?.id == el?.seller?.profitability)?.attributes?.Label?.toLowerCase().includes(searchInput.toLowerCase());
                return locationMatches || founded || stage || reason || valueDriver || companySize || valueAtion || profbility;
            });

            return filteredData || [];
        }

        return [];
    };


    const mutAddToFavorite = useMutation({
        mutationFn: UseAddToFavorite,
        onSuccess: async (data) => {
            // await filterBuyerListing();
        }
    })

    const toggleFavorite = async (itemId: any): Promise<any> => {
        setRefetchSource("favorite");
        
        const dataParam = {
            idBuyer: itemId,
        };
        
        try {
            const response = await mutAddToFavorite.mutateAsync(dataParam);

            setFilteredDatas(prevData => 
                prevData.map(item => 
                    item.id === itemId 
                        ? { ...item, isFavorite: !item.isFavorite } 
                        : item
                )
            );

            setInitialMatchings(prevData => 
                prevData.map(item => 
                    item.id === itemId 
                        ? { ...item, isFavorite: !item.isFavorite } 
                        : item
                )
            );
            
            return response;
        } catch (error) {
            console.error("Error toggling favorite:", error);
            throw error;
        }
    };

    return (
        <>
            {(!initialized || !isMyBuyerListingFetched || isDataLoading || !initialMatchings || !uniqueLocations || !uniqueIndustries || !uniqueSolutions || isLoading) &&
                <div className='loaderCommon'>
                    <LoaderCommon />
                </div>
            }
            {(initialized && !keycloak?.authenticated) && <Navigate to="/" />}
            {(initialized && keycloak?.authenticated && userRoles?.isBuyer && !isDataLoading && initialMatchings && uniqueLocations && uniqueIndustries && uniqueSolutions && !isLoading) &&
                <div style={{ paddingTop: "8rem" }} id="my-listing" className="findIsvcontainer programContainer sellerContainer my-listing">

                    {
                        userRoles?.isBuyer ? (
                            <BuyerSecondMenu
                                showSearch={true}
                                searchInput={searchInput}
                                setSearchInput={setSearchInput}
                                handleSearch={handleSearch}
                                handleGoToFavorite={handleGoToFavorite}
                            />
                        ) : (
                            <SellerSecondMenu
                                showSearch={true}
                                searchInput={searchInput}
                                setSearchInput={setSearchInput}
                                handleSearch={handleSearch}
                                handleGoToFavorite={handleGoToFavorite}
                            />
                        )
                    }

                    <div className="filterFindIsv leftTop hScroll">

                        <div className="contentFilter">
                            <div className="block sellerBuyer pb-0 border-0">
                                <div className="sellers active" onClick={() => goToBuyerMatching()}>
                                    <a href="javascript:;">
                                        <span className="icon-sellers"></span>Sellers
                                    </a>
                                </div>
                                <div className="buyer" onClick={() => goToSellerMatching()}>
                                    <a href="javascript:;">
                                        <span className="icon-buyerList"></span>Buyers
                                    </a>
                                </div>
                            </div>
                            {
                                initialMatchings && initialMatchings.length > 0  ? (
                                    <>
                                        <div className="containerBtn block infoProgramContainer border-0">
                                            <button className="btn btn-devinsider cancel" onClick={() => filterBuyerListing()}>Filter</button>
                                            <a className="resetFilter" onClick={() => resetSellerListing()}>
                                                <span className="icon-refresh" />
                                                Reset filter
                                            </a>
                                        </div>
                                        <div className="block">
                                            <h3 className={`title withChild ${isLocationOpen ? 'open' : 'closed'}`} onClick={() => toggleFilterSection(setIsLocationOpen)}>HQ location</h3>
                                            <div className="listCheck">
                                                {
                                                    paginate(uniqueLocations, limitLocation)?.sort((a, b) => {
                                                        const countryA = ISO3166.getCountry(a) || "";
                                                        const countryB = ISO3166.getCountry(b) || "";
                                                        return countryA.localeCompare(countryB);
                                                    })?.map((el: any, index: any) => (
                                                        <div className="custom-control custom-checkbox orange" key={index}>
                                                            <input
                                                                type="checkbox"
                                                                id={`location${index}`}
                                                                className="custom-control-input"
                                                                onChange={() => handleSelectLocation(el)}
                                                                checked={
                                                                    selectedLocation?.find(
                                                                        (selected: any) => selected === el
                                                                    )
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            <label htmlFor={`location${index}`} className="custom-control-label">
                                                                {ISO3166.getCountry(el)}
                                                            </label>
                                                        </div>
                                                    ))
                                                }
                                                <SeeBtn
                                                    dataSource={uniqueLocations}
                                                    limit={limitLocation}
                                                    seeMoreOrLess={(p: string) => handleSeeLocation(p)}
                                                />
                                            </div>
                                        </div>
                                        <div className="block">
                                            <h3 className={`title withChild ${isSolutionOpen ? 'open' : 'closed'}`} onClick={() => toggleFilterSection(setIsSolutionOpen)}>Solution (Horizontal)</h3>
                                            <div className="listCheck">
                                                {
                                                    getUniqueSolutionLabels()
                                                    .slice(0, limitSolution) 
                                                    .map((uniqueLabel: any, index: number) => {
                                                      const el = uniqueSolutions?.find((solutionId: any) => 
                                                        horizontals?.data?.find((element: any) => 
                                                          solutionId == element?.id && element?.attributes?.Label === uniqueLabel
                                                        )
                                                      );
                          
                                                      return (
                                                        <>
                                                                <div className="custom-control custom-checkbox orange" key={index}>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`solutions${index}`}
                                                                        className="custom-control-input"
                                                                        onChange={() => handleSelectSolution(el)}
                                                                        checked={
                                                                            selectedSolution?.find(
                                                                                (selected: any) => selected === el
                                                                            )
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                    <label htmlFor={`solutions${index}`} className="custom-control-label">
                                                                        {horizontals?.data?.find((element: any) => el == element?.id)?.attributes?.Label}
                                                                    </label>
                                                                </div>
                                                            </>
                                                        );
                                                    })
                                                }
                                                <SeeBtn
                                                    dataSource={getUniqueSolutionLabels()}
                                                    limit={limitSolution}
                                                    seeMoreOrLess={(p: string) => handleSeeSolution(p)}
                                                />
                                            </div>
                                        </div>
                                        <div className="block">
                                            <h3 className={`title withChild ${isIndustryOpen ? 'open' : 'closed'}`} onClick={() => toggleFilterSection(setIsIndustryOpen)}>Industry (Vertical)</h3>
                                            <div className="listCheck">
                                                {
                                                    paginate(uniqueIndustries, limitIndustry)?.sort()?.map((el: any, index: any) => (
                                                        <>
                                                            <div className="custom-control custom-checkbox orange" key={index}>
                                                                <input
                                                                    type="checkbox"
                                                                    id={`industries${index}`}
                                                                    className="custom-control-input"
                                                                    onChange={() => handleSelectIndustry(el)}
                                                                    checked={
                                                                        selectedIndustry?.find(
                                                                            (selected: any) => selected === el
                                                                        )
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                <label htmlFor={`industries${index}`} className="custom-control-label">
                                                                    {verticals?.data?.find((element: any) => element?.id == el)?.attributes?.Label}
                                                                </label>
                                                            </div>
                                                        </>
                                                    ))
                                                }


                                            </div>
                                        </div>
                                        <SeeBtn
                                            dataSource={uniqueIndustries}
                                            limit={limitIndustry}
                                            seeMoreOrLess={(p: string) => handleSeeIndustry(p)}
                                        />
                                    </>
                                ) : null
                            }
                        </div>

                    </div>
                    {
                        <div className="container mx-auto minHeightwin sellerRight">
                            <div className="listOfIsv listOfSeller scrollContainer">
                                {savedplanType === 0 ? (
                                    <div className="aboutSection mt-4 pb-5">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                                        enim ad minim veniam, quis nostrud exercitation ullamco laboris
                                        nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat
                                        nulla pariatur.
                                        {
                                            !userRoles?.isBuyer && (
                                                <div className="mt-3">
                                                    <a
                                                        className="btn btn-devinsider"
                                                        href="javascript:;"
                                                        onClick={() => goToEnrollBuyer()}
                                                    >
                                                        Enroll now!
                                                    </a>
                                                </div>
                                            )
                                        }
                                    </div>
                                ) : (
                                    <>
                                        <div className="aboutSection mt-4 pb-4">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                                            enim ad minim veniam, quis nostrud exercitation ullamco laboris
                                            nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                                            reprehenderit in voluptate velit esse cillum dolore eu fugiat
                                            nulla pariatur.
                                            <a className="btn btn-devinsider mt-3" href="javascript:;" onClick={() => goToUpgrade()}>Upgrade plan</a>
                                        </div>

                                    </>
                                )}

                                <ul className="listSeller">
                                    {searchInput.trim() !== '' ? (
                                        handleSearch()?.length > 0 ? handleSearch() : []).map((el: any, index: any) => (
                                            <li className={`list ${isOpportunistic(el.seller) ? 'opportunistic' : ''} ${isPremium(el.seller) ? 'premium' : ''}`.trim()} key={index}>
                                                <div>
                                                    <div className="logo">
                                                        <span className="icon-building1"></span>
                                                    </div>
                                                    {savedplanType === 0 && (
                                                        <div className='match'>
                                                            <span>match</span>
                                                            <span>{el?.matching < 60 ? '<60' : el?.matching}%</span>
                                                        </div>
                                                    )}

                                                </div>
                                                <div className="desc">
                                                    <h2 className="title">
                                                        {dataLifecycle?.data?.find((element: any) => element?.id == el?.seller?.stage)?.attributes?.Label} 
                                                        <OverlayTrigger
                                                            overlay={
                                                                <Tooltip className="custom-tooltip">
                                                                    { !el?.isFavorite ? "Click here to bookmark the listing" : "Click here to remove bookmark" }
                                                                </Tooltip>
                                                            }
                                                            >
                                                            <span style= {{cursor: 'pointer'}} className={`icon-star-${el?.isFavorite ? 'on' : 'off'}`} onClick={() => toggleFavorite(el.id)}></span>
                                                        </OverlayTrigger>
                                                    </h2>
                                                    <div className="about"><span>{el?.seller?.company_reason}</span></div>
                                                    <a className="btn btn-devinsider" href="javascript:;" onClick={() => goToDetails(el?.seller?.id)}>More details</a>
                                                    <ul>
                                                        <li className="item" key={"el_location"}>
                                                            <div>Location</div>
                                                            <span className="ico icon-location"></span>
                                                            <span className="txt">{ISO3166.getCountry(el?.seller?.location)}</span>
                                                        </li>
                                                        <li className="item" key={"el_founded"}>
                                                            <div>Founded</div>
                                                            <span className="ico icon-founded"></span>
                                                            <span className="txt">{el?.seller?.founded}</span>
                                                        </li>
                                                        <li className="item" key={"el_companySIze"}>
                                                            <div>Company size</div>
                                                            <span className="ico icon-compSize"></span>
                                                            <span className="txt">{dataHowManyPeople?.data?.find((element: any) => element?.id == el?.seller?.company_size)?.attributes?.Label}</span>
                                                        </li>
                                                        <li className="item" key={"el_value_driver"}>
                                                            <div>Top value driver</div>
                                                            <span className="ico icon-topValue"></span>
                                                            <span className="txt">{dataMainValueBusiness?.data?.find((element: any) => element?.id == el?.seller?.value_driver)?.attributes?.Label}</span>
                                                        </li>
                                                        <li className="item" key={"el_valuation"}>
                                                            <div>Valuation</div>
                                                            <span className="ico icon-valuation"></span>
                                                            <span className="txt">{dataHowValueBusiness?.data?.find((element: any) => element?.id == el?.seller?.valuation)?.attributes?.Label}</span>
                                                        </li>
                                                        <li className="item" key={"el_profitability"}>
                                                            <div>Profitability</div>
                                                            <span className="ico icon-profitability"></span>
                                                            <span className="txt">{dataProfitabilityBusiness?.data?.find((element: any) => element?.id == el?.seller?.profitability)?.attributes?.Label}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        )
                                    ) : (
                                        filteredDatas && filteredDatas.map((el: any, index: any) => (
                                            <li className={`list ${isOpportunistic(el.seller) ? 'opportunistic' : ''} ${isPremium(el.seller) ? 'premium' : ''}`.trim()} key={index}>
                                                <div>
                                                    <div className="logo">
                                                        <span className="icon-building1"></span>
                                                    </div>
                                                    {savedplanType === 0 && (
                                                        <div className='match'>
                                                            <span>match</span>
                                                            <span>{el?.matching < 60 ? '<60' : el?.matching}%</span>
                                                        </div>
                                                    )}

                                                </div>
                                                <div className="desc">
                                                    <h2 className="title">
                                                        {dataLifecycle?.data?.find((element: any) => element?.id == el?.seller?.stage)?.attributes?.Label} 
                                                        <OverlayTrigger
                                                            overlay={
                                                                <Tooltip className="custom-tooltip">
                                                                    { !el?.isFavorite ? "Click here to bookmark the listing" : "Click here to remove bookmark" }
                                                                </Tooltip>
                                                            }
                                                            >
                                                            <span style= {{cursor: 'pointer'}} className={`icon-star-${el?.isFavorite ? 'on' : 'off'}`} onClick={() => toggleFavorite(el.id)}></span>
                                                        </OverlayTrigger>
                                                    </h2>
                                                    <div className="about"><span>{el?.seller?.company_reason}</span></div>
                                                    <a className="btn btn-devinsider" href="javascript:;" onClick={() => goToDetails(el?.seller?.id)}>More details</a>
                                                    <ul>
                                                        <li className="item" key={"el_location"}>
                                                            <div>Location</div>
                                                            <span className="ico icon-location"></span>
                                                            <span className="txt">{ISO3166.getCountry(el?.seller?.location)}</span>
                                                        </li>
                                                        <li className="item" key={"el_founded"}>
                                                            <div>Founded</div>
                                                            <span className="ico icon-founded"></span>
                                                            <span className="txt">{el?.seller?.founded}</span>
                                                        </li>
                                                        <li className="item" key={"el_companySIze"}>
                                                            <div>Company size</div>
                                                            <span className="ico icon-compSize"></span>
                                                            <span className="txt">{dataHowManyPeople?.data?.find((element: any) => element?.id == el?.seller?.company_size)?.attributes?.Label}</span>
                                                        </li>
                                                        <li className="item" key={"el_value_driver"}>
                                                            <div>Top value driver</div>
                                                            <span className="ico icon-topValue"></span>
                                                            <span className="txt">{dataMainValueBusiness?.data?.find((element: any) => element?.id == el?.seller?.value_driver)?.attributes?.Label}</span>
                                                        </li>
                                                        <li className="item" key={"el_valuation"}>
                                                            <div>Valuation</div>
                                                            <span className="ico icon-valuation"></span>
                                                            <span className="txt">{dataHowValueBusiness?.data?.find((element: any) => element?.id == el?.seller?.valuation)?.attributes?.Label}</span>
                                                        </li>
                                                        <li className="item" key={"el_profitability"}>
                                                            <div>Profitability</div>
                                                            <span className="ico icon-profitability"></span>
                                                            <span className="txt">{dataProfitabilityBusiness?.data?.find((element: any) => element?.id == el?.seller?.profitability)?.attributes?.Label}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        ))
                                    )}
                                </ul>

                            </div>
                        </div>
                    }
                </div >

            }
            {
                (initialized && keycloak?.authenticated && !userRoles?.isBuyer) &&
                <SellerListing showListing={true} />
            }
        </>

    )
}
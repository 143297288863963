import { ChangeEvent, useEffect, useState } from "react";
import { Accordion, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import CreatableSelect from "react-select/creatable";
import { useGetMySpecificTehnologyListing } from '../../../../../api/buyer';
import { useGetStaticParams } from "../../../../../api/staticParams";
import BuyerStep from "../../../../../components/buyer/buyer-step";
import ModalIndustryList from "../../../../../components/modalIndustryList";
import ModalSolutionList from "../../../../../components/modalSolutionList";
import { IMAInterest } from "../../../../../types/MAInterest";
import { Countries } from "../../../../../widget/countries";
import { renderOptions } from '../../../utils/renderOptions';
import { industriesTextToDisplay, solutionsTextSolutionsToDisplay } from "../../../../../utils/display_text";

type IProps = {
    handleNextStep: (step: number) => void;
    showCancelModal: () => void;
    setData: (data: IMAInterest) => void;
    data: IMAInterest;
    validateSteps: Array<number>;
    currentStep: number;
    setCurrentStep: (step: number) => void;
    removeStepInValidated: (step: number) => void;
    addStepToValidated: (step: number) => void;
};
type ITransformedISV = {
    value: number;
    label: string;
}
type IISV = {
    id: number;
    attributes: {
        Label: string;
        createdAt: string;
        updatedAt: string;
        publishedAt: string;
    };
}
interface UserSpecificTechnology {
    id: number;
    idUser: string;
    specificTechnology: any[];
}
type SelectedOptions = {
    lifecycle: string[];
};

type EcosystemItem = {
    value: number | string;
    label: string;
    __isNew__?: boolean;
};

export default function MAInterest(props: IProps) {


    const [formState, setFormState] = useState(props?.data);

    const animatedComponents = makeAnimated();

    const transformISV = (countries: IISV[]): ITransformedISV[] => {
        return countries.map((ISV: IISV) => ({
            value: ISV.id,
            label: ISV.attributes.Label,
        }));
    };

    const [enableGetOpportunities, setEnableGetOpportunities] = useState(true);
    const [enableGetLifecycles, setEnableGetLifecycles] = useState(true);
    const [enableGetIsvBased, setEnableGetIsvBased] = useState(true);
    const [enableEcosystems, setEnableEcosystems] = useState(true);
    const [enableVerticals, setEnableVerticals] = useState(true);
    const [enableHorizontals, setEnableHorizontals] = useState(true);
    const [enableSpecificTechnologie, setEnableSpecificTechnologie] = useState(true);
    const { data: userSpecificTechnologie, isFetched: isSpecificTechnologieFetched } = useGetMySpecificTehnologyListing(enableSpecificTechnologie);
    const { data: opportunitiesData, isFetched: isOpportunitiesFetched } = useGetStaticParams('OPPORTUNITY_INTERESTS', enableGetOpportunities);
    const { data: lifecyclesData, isFetched: isLifecyclesFetched } = useGetStaticParams('LIFECYCLES', enableGetLifecycles);
    const { data: ecosystemsData, isFetched: isEcosystemsFetched } = useGetStaticParams('TECHNO_ECOSYSTEM', enableEcosystems);
    const { data: verticalsData, isFetched: isVerticalsFetched } = useGetStaticParams('VERTICALS', enableVerticals);
    const { data: horizontalsData, isFetched: isHorizontalsFetched } = useGetStaticParams('HORIZONTALS', enableHorizontals);
    const [enableGetCountries, setEnableGetCountries] = useState(true);
    const { data: countriesData, isFetched: isCountriesFetched } = useGetStaticParams('COUNTRIES', enableGetCountries);

    const [currentSelected, setCurrentSelected] = useState<{
        value: number;
        label: string;
    }>();

    const [userSpecificTechnologies, setUserSpecificTechnologies] = useState<Array<UserSpecificTechnology>>([]);
    const ecosystemArray = Array.isArray(formState?.ecosystem) && formState.ecosystem.length > 0 ? formState.ecosystem : [];

    const [currentSelectedList, setCurrentSelectedList] = useState<Array<{ value: number; label: string }>>(
        ecosystemArray?.map((item: any) => {
            return { value: item.value as number, label: item.label };
        }) || []
    );

    useEffect(() => {
        if (isOpportunitiesFetched) setEnableGetOpportunities(false);
    }, [isOpportunitiesFetched]);

    useEffect(() => {
        if (isSpecificTechnologieFetched) setEnableSpecificTechnologie(false);
    }, [isSpecificTechnologieFetched]);

    useEffect(() => {
        if (userSpecificTechnologie) {
            let specificTech: Array<UserSpecificTechnology> = [];
            userSpecificTechnologie.forEach((item: UserSpecificTechnology) => {
                specificTech = specificTech.concat(item.specificTechnology);
            });
            setUserSpecificTechnologies(specificTech);
        }
    }, [userSpecificTechnologie]);

    useEffect(() => {
        if (isLifecyclesFetched) setEnableGetLifecycles(false);
    }, [isLifecyclesFetched]);

    useEffect(() => {
        if (isEcosystemsFetched) setEnableEcosystems(false);
    }, [isEcosystemsFetched]);

    useEffect(() => {
        if (isVerticalsFetched) setEnableVerticals(false);
    }, [isVerticalsFetched]);

    useEffect(() => {
        if (isHorizontalsFetched) setEnableHorizontals(false);
    }, [isHorizontalsFetched]);

    useEffect(() => {
        if (isCountriesFetched) setEnableGetCountries(false);
    }, [isCountriesFetched]);

    const [showPIndustry, setShowPIndustry] = useState(false);
    const [showPSolution, setShowPSolution] = useState(false);
    const [selectedIndustry, setSelectedIndustry] = useState<any[]>(formState?.targeted_industry);
    const [selectedSolution, setSelectedSolution] = useState<any[]>(formState?.software_solution);
    const [selectedFeaturedSolution, setSelectedFeaturedSolution] = useState<any[]>(formState?.featured_solution);
    const [activeId, setActiveId] = useState<any>("-1");
    const [selectedGeographicData, setSelectedGeographicData] = useState<any>({});

    function toggleActive(id: any) {
        if (activeId === id) {
            setActiveId("null");
        } else {
            setActiveId(id);
        }
    }

    const [technologiesPartnership, setTechnologiesPartnership] = useState<Array<{ value: number; label: string }>>([]);

    useEffect(() => {
        if (ecosystemsData && ecosystemsData.data) {
            let combinedData = [];

            const mappedData = ecosystemsData.data.map((item: any) => ({
                value: item.id,
                label: item.attributes.Label
            }));

            combinedData = [...mappedData];

            let technology_ecosystem = formState?.ecosystem.map((item: any) => item.label) || [];
            if (formState?.ecosystem) {
                combinedData = mappedData.filter((item: { label: any; }) => !technology_ecosystem.includes(item.label));
            } else {
                combinedData = [...mappedData];
            }

            if (userSpecificTechnologies.length > 0) {
                const lastEcosystemDataId = ecosystemsData.data[ecosystemsData.data.length - 1].id;
                const transformedUserSpecificTechnologies = userSpecificTechnologies.map((item, index) => ({
                    value: lastEcosystemDataId + index + 1,
                    label: item
                }));
                combinedData = [...combinedData, ...transformedUserSpecificTechnologies];
            }

            setTechnologiesPartnership(combinedData);
        }
    }, [ecosystemsData, userSpecificTechnologies, formState]);




    const addListOption = (data: any) => {
        if (currentSelected) {
            let currentOptionList = technologiesPartnership.filter(
                (el: { value: number; label: string }) => el.value !== data.value
            );

            setCurrentSelectedList([...currentSelectedList, currentSelected]);
            setTechnologiesPartnership(currentOptionList);
            setCurrentSelected({ label: '', value: 0 });
            updateState('ecosystem', [...currentSelectedList, currentSelected]);
        }
    };
    const removeSelected = (id: number) => {
        let data = currentSelectedList?.find(
            (el: { value: number; label: string }) => el.value === id
        );
        let tempValue = currentSelectedList?.filter(
            (el: { value: number; label: string }) => el.value !== id
        );
        setCurrentSelectedList(tempValue);
        if (data) {
            setTechnologiesPartnership([data, ...technologiesPartnership]);
        }
        updateState('ecosystem', tempValue);
    };


    useEffect(() => {
        if (formState?.isvBased) {
            setSelectedGeographicData(formState.isvBased);
        }
    }, [formState]);

    const addTargetedIndustry = (element: any) => {
        setSelectedIndustry([...selectedIndustry, element?.id]);
    }

    const removeTargetedIndustry = (element: any) => {
        setSelectedIndustry(selectedIndustry?.filter(
            (el: any) => el !== element?.id
        ));
    }

    const addSoftwareSolution = (element: any) => {
        setSelectedSolution([...selectedSolution, element?.id]);
    }

    const addFeaturedSolution = (element: any) => {
        setSelectedFeaturedSolution([...selectedFeaturedSolution, element?.id]);
    }

    const removeSolution = (element: any) => {

        let featuredSolutions = horizontalsData?.data?.filter((el: any) => el?.attributes?.Featured === true);
        let softwareSolutions = horizontalsData?.data?.filter((el: any) => el?.attributes?.Featured === false);


        let foundInFeaturedList = featuredSolutions?.find(
            (el: any) => el?.attributes?.Label == element?.attributes?.Label
        )

        let foundInSolutionList = softwareSolutions?.find(
            (el: any) => el?.attributes?.Label == element?.attributes?.Label
        )

        if(foundInSolutionList)
            setSelectedSolution(selectedSolution?.filter(
                (el: any) => el !== foundInSolutionList?.id
            ));
        
        if(foundInFeaturedList)
            setSelectedFeaturedSolution(selectedFeaturedSolution?.filter(
                (el: any) => el !== foundInFeaturedList?.id
            ));
    }

    const removeItemSelected = (element: any) => {

        let featuredSolutions = horizontalsData?.data?.filter((el: any) => el?.attributes?.Featured === true);
        let softwareSolutions = horizontalsData?.data?.filter((el: any) => el?.attributes?.Featured === false);


        let foundInFeaturedList = featuredSolutions?.find(
            (el: any) => el?.attributes?.Label == element
        )

        let foundInSolutionList = softwareSolutions?.find(
            (el: any) => el?.attributes?.Label == element
        )

        if(foundInSolutionList)
            setSelectedSolution(selectedSolution?.filter(
                (el: any) => el !== foundInSolutionList?.id
            ));
        
        if(foundInFeaturedList)
            setSelectedFeaturedSolution(selectedFeaturedSolution?.filter(
                (el: any) => el !== foundInFeaturedList?.id
            ));
    }

    const validTargetedIndustry = () => {
        updateState('targeted_industry', selectedIndustry);
        setShowPIndustry(false);
    }

    const validSoftwareSolution = () => {
        updateState('software_solution', selectedSolution);
        updateState('featured_solution', selectedFeaturedSolution);
        setShowPSolution(false);
    }


    const backTarget = () => {
        setSelectedIndustry(formState?.targeted_industry);
        setShowPIndustry(false);
    }

    const backSolution = () => {
        setSelectedSolution(formState?.software_solution);
        setSelectedFeaturedSolution(formState?.featured_solution);
        setShowPSolution(false);
    }

    const updateState = (property: string, value: any) => {
        setFormState((prevState: any) => ({
            ...prevState,
            [property]: value,
        }));
    }

    const geographicData = countriesData && countriesData
        ? countriesData.data.reduce((acc: any, current: any) => {
            if (current && current.attributes) {
                const continent = current.attributes.Continent;
                const iso2 = current.attributes.ISO2;
                const name = current.attributes.Name;

                if (continent && iso2 && name) {
                    if (!acc[continent]) {
                        acc[continent] = {
                            Continent: continent,
                            Countries: [{ ISO2: iso2, Name: name }],
                        };
                    } else {
                        acc[continent].Countries.push({ ISO2: iso2, Name: name });
                    }
                }
            }
            return acc;
        }, {})
        : {};

    const [opportunityError, setOpportunityError] = useState('');
    const [lifecycleError, setLifecycleError] = useState('');
    const [isvBasedError, setIsvBasedError] = useState('');
    const [ecosystemError, setEcosystemError] = useState('');
    const [haveEcosystemError, setHaveEcosystemError] = useState('');
    const [industryError, setIndustryError] = useState('');
    const [solutionError, setSolutionError] = useState('');

    const [geographicSalesError, setGeographicSalesError] = useState('');

    const goToNextStep = (step: number, isGoingNextStep: boolean) => {
        setOpportunityError('');
        setLifecycleError('');
        setIsvBasedError('');
        setEcosystemError('');

        let hasErrors = false;

        if (Number(formState?.opportunity) === 0) {
            setOpportunityError(' ERROR This field is required');
            hasErrors = true;
        }

        if (formState?.ecosystem.length < 1) {
            setEcosystemError(' ERROR This field is required');
            hasErrors = true;
        }


        if (formState?.isvBased.length < 1) {
            setIsvBasedError(' ERROR This field is required');
            hasErrors = true;
        }


        if (formState?.targeted_industry.length < 1) {
            setIndustryError(' ERROR This field is required');
            hasErrors = true;
        }

        if (filteredSolution(allSolutions).length < 1) {
            setSolutionError(' ERROR This field is required');
            hasErrors = true;
        }

        if (formState?.lifecycle.length === 0) {
            setLifecycleError(' ERROR This field is required');
            hasErrors = true;
        }
        if (selectedGeographicData.length === 0) {
            setGeographicSalesError(' ERROR This field is required');
            hasErrors = true;
        } else {
            setGeographicSalesError('');
        }

        if (hasErrors) {
            if (isGoingNextStep) props?.removeStepInValidated(props?.currentStep);
            return;
        }

        props?.addStepToValidated(props?.currentStep);
        props?.setData(formState);
        props?.handleNextStep(step);
    }

    useEffect(() => {
        if (Number(formState?.opportunity) !== 0) {
            setOpportunityError('');
        }
        if (formState?.lifecycle.length > 0) {
            setLifecycleError('');
        }


        if (formState?.isvBased.length > 0) {
            setIsvBasedError('');
        }


        if (currentSelectedList !== undefined && currentSelectedList.length > 0) {
            setEcosystemError('');
        }



        if (selectedIndustry.length > 0) {
            setIndustryError('');

        }
        if (selectedSolution.length > 0) {
            setSolutionError('');
        }
        if (Object.keys(selectedGeographicData).length > 0) {
            setGeographicSalesError('');
        }

    }, [currentSelectedList, formState?.isvBased.length, formState?.lifecycle.length, formState?.opportunity, selectedGeographicData, selectedIndustry.length, selectedSolution]);



    const [transformedData, setTransformedData] = useState<ITransformedISV[]>();


    useEffect(() => {
        if (lifecyclesData) setTransformedData(transformISV(lifecyclesData.data));
    }, [lifecyclesData]);

    const lifecycleStageAsString = JSON.stringify(formState?.lifecycle).replace(/(\["|"\])/g, '');

    const isHaveSelectedLabel =
        currentSelected && currentSelected.label;

    let allSolutions = [...formState?.software_solution, ...formState?.featured_solution];

    const filteredSolution = (solution: any) => {
        let alreadyExist: Array<string> = [];
        solution?.map((el: any) => {
            let name = horizontalsData?.data?.find((e: any) => e?.id === el)?.attributes?.Label;
            if(alreadyExist.indexOf(name) === -1) {
                alreadyExist.push(name);
            }
        })
        return alreadyExist;
    }

    const txtBtnCat = solutionsTextSolutionsToDisplay(filteredSolution(allSolutions));

    const txtBtnIndustry = industriesTextToDisplay(formState?.targeted_industry);

    return (
        <>
            <BuyerStep
                validatedSteps={props?.validateSteps}
                currentStep={props?.currentStep}
                handleContinue={goToNextStep}
                setCurrentStep={props?.setCurrentStep}
            />
            <div id="mainterest" className='scrollContainer'>
                <div className='contentUser acquisition'>
                    <div className='contentBorder row'>
                        <div className='form-group required mb-4 p-0'>
                            <label htmlFor=''>What type of opportunity are you most interested in? </label>
                            {
                                opportunityError && (
                                    <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                        <span className='error-red'> ERROR </span>{" "}
                                        <span className='txt-error'>This field is required</span>
                                    </div>
                                )
                            }
                            <select name="opportunity" className='form-control mt-2' value={formState?.opportunity} onChange={(e) => { updateState(e.target.name, e.target.value); }}>
                                {renderOptions(opportunitiesData?.data)}
                            </select>

                        </div>

                        <div className='form-group required mb-4 p-0'>
                            <label className="d-inline-block mb-2" htmlFor=''>Select what stages  of the ISV life cycle you are the most interested in </label>
                            {
                                lifecycleError && (
                                    <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                        <span className='error-red'> ERROR </span>{" "}
                                        <span className='txt-error'>This field is required</span>
                                    </div>
                                )
                            }
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                options={transformedData as ITransformedISV[]}
                                value={formState?.lifecycle}
                                onChange={(selectedOptions) => {
                                    updateState('lifecycle', selectedOptions);
                                }}
                                placeholder="Select all that apply..."
                            />

                        </div>

                        <div className="form-group required selectSol p-0 m-0">
                            <label className='mb-2' htmlFor=''>What ISV verticals are you most interested in? </label>
                            {
                                industryError && (
                                    <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                        <span className='error-red'> ERROR </span>{" "}
                                        <span className='txt-error'>This field is required</span>
                                    </div>
                                )
                            }
                            <div className="d-flex mb-1 w-100 align-items-center">
                                <div className="btn btn-devinsider m-0" onClick={() => setShowPIndustry(true)}>{txtBtnIndustry}</div>
                                <div style={{ marginLeft: "1rem", marginRight: 0 }} className="listOf more">
                                    {' '}
                                    <div className='topicAds'>
                                        {formState?.targeted_industry?.map((el: any, key: number) => (
                                            <span className='topic' key={key}>{verticalsData?.data?.find((element: any) => element?.id === el)?.attributes?.Label}</span>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="form-group required selectSol mt-4 p-0 mr-0">
                            <label className='mb-2' htmlFor=''>What ISV horizontals are you most interested in? </label>
                            {
                                solutionError && (
                                    <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                        <span className='error-red'> ERROR </span>{" "}
                                        <span className='txt-error'>This field is required</span>
                                    </div>
                                )
                            }
                            <div className="d-flex mb-1 w-100 mb-3 align-items-center">
                                <div className="btn btn-devinsider m-0" onClick={() => setShowPSolution(true)}>{txtBtnCat}</div>
                                <div style={{ marginLeft: "1rem", marginRight: 0 }} className="listOf more">
                                    {' '}
                                    <div className='topicAds'>
                                        {filteredSolution(allSolutions)?.filter((value, index, self) => self.indexOf(value) === index)
                                            ?.map((el: any, key: number) => (
                                                <span className='topic' key={key}>{el}</span>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-group required mb-4 p-0'>
                            <label htmlFor=''>Where should the ISV business be based?</label>
                            {
                                geographicSalesError && (
                                    <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                        <span className='error-red'> ERROR </span>{" "}
                                        <span className='txt-error'>This field is required</span>
                                    </div>
                                )
                            }
                            <span className="selectalloverstyle">Select all that apply</span>

                            <div className="geographicList">
                                <Accordion defaultActiveKey={activeId}>
                                    <Countries
                                        geographicData={geographicData}
                                        activeId={activeId}
                                        toggleActive={toggleActive}
                                        data={formState.isvBased}
                                        stateName={"isvBased"}
                                        updateState={updateState}
                                        isEdit={false}
                                    />
                                </Accordion>
                            </div>
                        </div>
                        <div className='form-group required mb-4 p-0'>
                            <div className="paterneer position-relative mt-2">
                                <label htmlFor=''>What ecosystems/technology parterships would enhance the appeal of an ISV? </label>
                                {
                                    ecosystemError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                                <CreatableSelect
                                    options={technologiesPartnership}
                                    className="input-text form-control p-0 mt-2"
                                    classNamePrefix="partneer"
                                    onChange={(el: any) => {
                                        setCurrentSelected(el);
                                    }}
                                    placeholder="Select all that apply"
                                    value={currentSelected}
                                />
                                <button id="add-partneer" className='border-0 d-flex align-items-center' onClick={() =>
                                    isHaveSelectedLabel &&
                                    addListOption(currentSelected)
                                }>
                                    <svg width="30" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="#fff" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                    </svg>

                                </button>
                            </div>
                            {currentSelectedList &&
                                currentSelectedList.map(
                                    (el: { value: number; label: string }, index: number) => (
                                        <div className="paterneer d-flex mt-2" key={index}>
                                            <input
                                                type="text"
                                                className="input-text form-control"
                                                placeholder="Select all that apply"
                                                value={el.label}
                                                disabled={true}
                                            />
                                            <button style={{ background: "#d9534f" }} className='border-0 red-button' onClick={() => removeSelected(el.value)}>
                                                <svg width="30" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="#fff" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                </svg>
                                            </button>
                                        </div>
                                    )
                                )
                            }
                        </div>

                    </div>
                    <div className='text-align-right stepsButtons'>
                        <button className='btn btn-devinsider cancel' onClick={() => props?.showCancelModal()}>
                            Cancel
                        </button>
                        <button className='btn btn-devinsider save' onClick={() => goToNextStep(props?.currentStep + 1, true)}>
                            Continue
                        </button>
                    </div>
                </div>

                <ModalIndustryList
                    show={showPIndustry}
                    cancel={backTarget}
                    dataIndustry={verticalsData?.data}
                    addIndustry={addTargetedIndustry}
                    removeIndustry={removeTargetedIndustry}
                    industrySelected={selectedIndustry}
                    valid={validTargetedIndustry}
                />
                <ModalSolutionList
                    show={showPSolution}
                    cancel={backSolution}
                    dataSolutions={horizontalsData?.data}
                    addSoftwareSolution={addSoftwareSolution}
                    solutionSelected={selectedSolution}
                    valid={validSoftwareSolution}
                    addFeaturedSolution={addFeaturedSolution}
                    featuredSolutionSelected={selectedFeaturedSolution}
                    removeItem={removeItemSelected}
                    removeSolution={removeSolution}
                />
            </div>
        </>
    );
}
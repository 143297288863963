import ISO3166 from 'iso-3166-1-alpha-2';
import { useEffect, useState } from "react";
import { Accordion, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useMutation } from "react-query";
import CreatableSelect from "react-select/creatable";
import { UseUpdateSeller, generateYearList, useGetListingById, useGetSolutionsFiltre } from "../../../../api/seller";
import IndustriesList from '../../../../components/industriesList';
import SolutionsList from '../../../../components/solutionsList';
import { ReactComponent as Loader } from '../../../../resources/images/Rolling.svg';
import { Countries } from '../../../../widget/countries';
import Select from "react-select";
import makeAnimated from 'react-select/animated';

type EcosystemItem = {
    value: number | string;
    label: string;
    __isNew__?: boolean
};
type ITransformedBusinessModel = {
    value: number;
    label: string;
}
type IBusinessModel = {
    id: number;
    attributes: {
        Label: string;
        createdAt: string;
        updatedAt: string;
        publishedAt: string;
    };
}
export default function CompanyOverviewEdit(props: any) {
    const _ = require('lodash');
    const years = generateYearList();
    const [enabled, setEnabled] = useState(false);

    const { data: dataSolutions } = useGetSolutionsFiltre(enabled);
    
    let specific_tecnhology: object[] = [];
    if (props?.technologiesPartnership && props?.technologiesPartnership?.length > 0) {
        props?.technologiesPartnership?.forEach((item: any) => {
            if (item.attributes) {
                specific_tecnhology.push(item.attributes.Label);
            }
        });
    }
    const { data, refetch } = useGetListingById(props?.idSeller, enabled);

    const mutUpdate = useMutation({
        mutationFn: UseUpdateSeller,
        retry: 0,
        onSuccess: (data) => {
            props.setIsLoading(false);
            props.handleOk();
            props?.setDataNecessaryInformation(formStateNecessaryInfo);
            props?.setDataCompanyBasic(formStateCompany);
            props?.setDataAcquisition(formStateAcquisition);
        },
        onSettled: (data, error, variables, context) => {
            setIsAccordionOpen(false);
            refetch();
            setActiveId('');
            setActiveGeoId('');
        }
    });

    const transforStateTechnologyEdit = (data: any) => {
        let custom = data.map((el: any) => {
            let temp = {
                label: props?.dataTechnologyPartenership?.data?.find((element: any) => element?.id == el)?.attributes?.Label,
                value: el
            };
            return temp;
        });
        return custom;
    }

    const getStateCompany = () => {
        return {
            ...props?.dataCompanyBasic,
            location: props?.value?.location === "manda_deleted_manda" ? "" : props?.value?.location,
            founded: props?.value?.founded === "manda_deleted_manda" ? "" : props?.value?.founded,
            company_size: props?.value?.company_size === -1 ? 0 : props?.value?.company_size,
            business_model: props?.value?.business_model?.some((el: any) => el !== "manda_deleted_manda") ? props?.value?.business_model : [],
            targeted_industry: props?.value?.targeted_industry?.some((el: any) => el !== "manda_deleted_manda") ? props?.value?.targeted_industry : [],
            software_solution: props?.value?.software_solution?.some((el: any) => el !== "manda_deleted_manda") ? props?.value?.software_solution : [],
            featured_solution: props?.value?.featured_solution?.some((el: any) => el !== "manda_deleted_manda") ? props?.value?.featured_solution : [],
            have_technology: props?.value?.have_technology,
            geographic_sales_footprint: props?.value?.geographic_seller?.find((el: any) => el?.council_region !== "manda_deleted_manda") ? props?.transforStateGeographic(props?.value?.geographic_seller) : [],
            technology_ecosystem: props?.value?.technology_partnership?.find((el: any) => el !== "manda_deleted_manda") ? transforStateTechnologyEdit(props?.value?.technology_partnership) : [],
        };
    }
    const [formStateCompany, setFormStateCompany] = useState(getStateCompany());

    const getStateNecessaryInfo = () => {
        return {
            ...props?.dataNecessaryInformation,
            summarize: props?.value?.company_reason === "manda_deleted_manda" ? "" : props?.value?.company_reason,
            lifecycle_stage: props?.value?.stage === -1 ? 0 : props?.value?.stage,
        };
    }
    const [formStateNecessaryInfo, setFormStateNecessaryInfo] = useState(getStateNecessaryInfo);

    const getStateAcquisition = () => {
        return {
            ...props?.dataAcquisition,
            business_value: props?.value?.valuation === -1 ? 0 : props?.value?.valuation,
        };
    }
    const [formStateAcquisition, setFormStateAcquisition] = useState(getStateAcquisition());

    useEffect(() => {
        if (props?.show) {
            setFormStateCompany(getStateCompany());
            setFormStateNecessaryInfo(getStateNecessaryInfo());
            setFormStateAcquisition(getStateAcquisition());
        }

    }, [props?.show]);


    const transformToObjects = (stages: string[], valueType: string): { [key: number]: IBusinessModel } => {
        const transformedObject: { [key: number]: IBusinessModel } = {};

        stages.forEach((stage, index) => {
            transformedObject[index + 1] = {
                id: Number(stage),
                attributes: {
                    Label: stage,
                    createdAt: "",
                    updatedAt: "",
                    publishedAt: ""
                }
            };
        });

        return transformedObject;
    };

    const newNusinessModel = transformToObjects(formStateCompany?.business_model, "number");

    const transformedBusinessModelArray = Object.values(newNusinessModel).map((item: IBusinessModel) => ({
        value: item.id,
        label: props?.dataBusinessModel?.data?.find((el: any) => el?.id == item?.id)?.attributes?.Label
    }));

    const [selectedBusinessModel, setSelectedBusinessModel] = useState<ITransformedBusinessModel[]>([]);

    useEffect(() => {
        const newNusinessModel = transformToObjects(formStateCompany?.business_model, "number");

        const transformedBusinessModelArray = Object.values(newNusinessModel).map((item: IBusinessModel) => ({
            value: item.id,
            label: props?.dataBusinessModel?.data?.find((el: any) => el?.id == item.attributes.Label)?.attributes?.Label,
        }));

        const transformedIsvArrays = transformedBusinessModelArray.map((item: any) => ({
            value: item.value,
            label: item.label,
        }));


        setSelectedBusinessModel(transformedIsvArrays);

    }, [formStateCompany?.business_model, props?.dataBusinessModel?.data]);



    const filteredOptions = props?.dataBusinessModel?.data?.map((el: any) => {
        return {
            label: el?.attributes?.Label,
            value: el?.id
        };
    }).filter((option: { value: number; }) => !selectedBusinessModel.some(selectedOption => selectedOption.value === option.value));

    const [activeId, setActiveId] = useState<string | null>("");
    const [activeGeoId, setActiveGeoId] = useState<any>("");
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);

    const [currentSelected, setCurrentSelected] = useState<{
        value: number;
        label: string;
    }>();

    const ecosystemArray = Array.isArray(formStateCompany?.technology_ecosystem) && formStateCompany.technology_ecosystem.length > 0 ? formStateCompany.technology_ecosystem : [];

    const [currentSelectedList, setCurrentSelectedList] = useState<Array<{ value: number; label: string }>>(ecosystemArray);

    const isHaveSelectedLabel =
        currentSelected && currentSelected.label;

    const updateStateCompany = (property: string, value: any) => {
        setFormStateCompany((prevState: any) => ({
            ...prevState,
            [property]: value,
        }));
    }

    const updateStateNecessaryInfo = (property: string, value: any) => {
        setFormStateNecessaryInfo((prevState: any) => ({
            ...prevState,
            [property]: value,
        }));
    }

    const updateStateAcquisition = (property: string, value: any) => {
        setFormStateAcquisition((prevState: any) => ({
            ...prevState,
            [property]: value,
        }));
    }

    useEffect(() => {
        if (formStateCompany.have_technology === false) {
            setFormStateCompany((prevState: any) => ({
                ...prevState,
                technology_ecosystem: [],
            }));
            setCurrentSelectedList([]);
        }
    }, [formStateCompany.have_technology]);

    useEffect(() => {
        if (props?.idSeller) setEnabled(true);
        if (data && dataSolutions) setEnabled(false);
    }, [data, props?.idSeller]);

    function toggleActive(id: string | null) {
        if (activeId === id) {
            setActiveId("");
        } else {
            setActiveId(id);
        }
    }

    function toggleActiveGeo(id: any) {
        if (activeGeoId === id) {
            setActiveGeoId("null");
        } else {
            setActiveGeoId(id);
        }
    }

    const allSolutions = [...formStateCompany?.software_solution, ...formStateCompany?.featured_solution];


    const addTargetedIndustry = (element: any) => {
        updateStateCompany('targeted_industry', [...formStateCompany?.targeted_industry, element?.id]);
    }

    const removeTargetedIndustry = (element: any) => {
        updateStateCompany('targeted_industry', formStateCompany?.targeted_industry?.filter(
            (el: any) => el !== element?.id
        ));
    }
    const addSoftwareSolution = (element: any) => {
        updateStateCompany('software_solution', [...formStateCompany?.software_solution, element?.id]);
    }

    const addFeaturedSolution = (element: any) => {
        updateStateCompany('featured_solution', [...formStateCompany?.featured_solution, element?.id]);
    }

    const removeSolution = (element: any) => {

        let featuredSolutions = dataSolutions?.data?.filter((el: any) => el?.attributes?.Featured === true);
        let softwareSolutions = dataSolutions?.data?.filter((el: any) => el?.attributes?.Featured === false);

        let foundInFeaturedList = featuredSolutions?.find(
            (el: any) => el?.attributes?.Label == element?.attributes?.Label
        )

        let foundInSolutionList = softwareSolutions?.find(
            (el: any) => el?.attributes?.Label == element?.attributes?.Label
        )

        if(foundInFeaturedList)
            updateStateCompany('featured_solution', formStateCompany?.featured_solution?.filter(
                (el: any) => el !== foundInFeaturedList?.id
            ));

        if(foundInSolutionList)
            updateStateCompany('software_solution', formStateCompany?.software_solution?.filter(
                (el: any) => el !== foundInSolutionList?.id
            ));
    }

    const removeItemSelected = (element: any) => {

        let featuredSolutions = dataSolutions?.data?.filter((el: any) => el?.attributes?.Featured === true);
        let softwareSolutions = dataSolutions?.data?.filter((el: any) => el?.attributes?.Featured === false);

        let foundInFeaturedList = featuredSolutions?.find(
            (el: any) => el?.attributes?.Label == element
        )

        let foundInSolutionList = softwareSolutions?.find(
            (el: any) => el?.attributes?.Label == element
        )

        if(foundInSolutionList)
            updateStateCompany('software_solution', formStateCompany?.software_solution?.filter(
                (el: any) => el !== foundInSolutionList?.id
            ));

        if(foundInFeaturedList)
            updateStateCompany('featured_solution', formStateCompany?.featured_solution?.filter(
                (el: any) => el !== foundInFeaturedList?.id
            ));
    }

    const filteredSolution = (solution: any) => {
        let alreadyExist: Array<string> = [];
        solution?.map((el: any) => {
            let name = dataSolutions?.data?.find((e: any) => e?.id === el)?.attributes?.Label;
            if(alreadyExist.indexOf(name) === -1) {
                alreadyExist.push(name);
            }
        })
        return alreadyExist;
    }

    const addListOption = (data: any) => {
        if (currentSelected) {
            let currentOptionList = props?.technologiesPartnership.filter(
                (el: { value: number; label: string }) => el.value !== data.value
            );

            setCurrentSelectedList([...currentSelectedList, currentSelected]);
            props?.setTechnologiesPartnership(currentOptionList);
            setCurrentSelected({ label: '', value: 0 });
            updateStateCompany('technology_ecosystem', [...currentSelectedList, currentSelected]);
        }
    };

    const removeListOption = (id: number) => {
        let data = currentSelectedList?.find(
            (el: { value: number; label: string }) => el.value === id
        );
        let tempValue = currentSelectedList?.filter(
            (el: { value: number; label: string }) => el.value !== id
        );
        setCurrentSelectedList(tempValue);
        if (data) {
            props?.setTechnologiesPartnership([data, ...props?.technologiesPartnership]);
        }
        updateStateCompany('technology_ecosystem', tempValue);
    };


    const filteredTechnoOptions = props?.dataTechnologyPartenership?.data?.map((el: any) => ({
        label: el?.attributes?.Label,
        value: el?.id
    })).filter((option: { value: number; }) => 
        !currentSelectedList?.some( select => select.value === option.value)
    );

    const save = () => {
        let techno: any[] = [];

        // COMPANY BASIC 
        if (formStateCompany?.location === "") {
            props?.setErrorLocation(true);
        }
        if (formStateCompany?.founded === "") {
            props?.setErrorFounded(true);
        }
        if (Number(formStateCompany?.company_size) === 0) {
            props?.setErrorCompanySize(true);
        }
        if (formStateCompany?.business_model?.length === 0) {
            props?.setErrorBusinessModel(true);
        }
        if (formStateCompany?.targeted_industry?.length === 0) {
            props?.setErrorIndustry(true);
        }
        if (filteredSolution(allSolutions)?.length === 0) {
            props?.setErrorSolution(true);
        }
        if (formStateCompany?.geographic_sales_footprint?.length === 0) {
            props?.setErrorGeographic(true);
        }
        if (formStateCompany?.have_technology === null) {
            props?.setErrorHaveTechnoPartnership(true);
        }
        if (formStateCompany?.have_technology !== null && formStateCompany?.have_technology === true && formStateCompany?.technology_ecosystem.length < 1) {
            props?.setErrorTechnoPartnership(true);
        }

        // NECESSARY INFO
        if (formStateNecessaryInfo?.summarize === "") {
            props?.setErrorAcquirer(true);
        }
        if (Number(formStateNecessaryInfo?.lifecycle_stage) === 0) {
            props?.setErrorStage(true);
        }

        // ACQUISITION
        if (Number(formStateAcquisition?.business_value) === 0) {
            props?.setErrorValueBusiness(true);
        }

        if (formStateCompany?.location !== "" &&
            formStateCompany?.founded !== "" &&
            Number(formStateCompany?.company_size) !== 0 &&
            formStateCompany?.business_model?.length > 0 &&
            formStateCompany?.targeted_industry?.length > 0 &&
            filteredSolution(allSolutions)?.length > 0 &&
            formStateCompany?.have_technology !== null &&
            (formStateCompany?.have_technology === true && formStateCompany?.technology_ecosystem.length > 0 || formStateCompany?.have_technology === false) &&
            formStateNecessaryInfo?.summarize !== "" &&
            Number(formStateNecessaryInfo?.lifecycle_stage) !== 0 &&
            (Number(formStateAcquisition?.business_value) !== 0)) {

            props.setIsLoading(true);

            formStateCompany?.technology_ecosystem.forEach((eco: any) => {
                techno.push(eco?.value);
            });

            let technology_ecosystem = formStateCompany?.technology_ecosystem?.map((item: { value: any; }) => item.value) || [];
            let specific_technology_ecosystem = technology_ecosystem.filter((value: object) => !specific_tecnhology.includes(value));

            if (formStateCompany?.software_solution) {
                technology_ecosystem = technology_ecosystem;
                specific_technology_ecosystem = specific_technology_ecosystem;
            } else {

                technology_ecosystem = [];
                specific_technology_ecosystem = [];

            }

            let param = {
                idSeller: props?.idSeller,
                technology_partnership: technology_ecosystem,
                location: formStateCompany?.location,
                company_size: Number(formStateCompany?.company_size),
                founded: formStateCompany?.founded,
                business_model: formStateCompany?.business_model,
                have_technology: formStateCompany?.have_technology,
                targeted_industry: formStateCompany?.targeted_industry,
                software_solution: formStateCompany?.software_solution,
                featured_solution: formStateCompany?.featured_solution,
                geographic_footprint: formStateCompany?.geographic_sales_footprint,
                company_reason: formStateNecessaryInfo?.summarize,
                stage: Number(formStateNecessaryInfo?.lifecycle_stage),
                valuation: Number(formStateAcquisition?.business_value),
                userSpecificEcosystem: specific_technology_ecosystem,
            }

            mutUpdate.mutateAsync(param);
        }
    }

    // Enlever les erreurs lors d'un modif
    useEffect(() => {
        if (formStateCompany?.location !== "") props?.setErrorLocation(false);
        if (formStateCompany?.founded !== "") props?.setErrorFounded(false);
        if (Number(formStateCompany?.company_size) !== 0) props?.setErrorCompanySize(false);
        if (formStateCompany?.business_model?.length > 0) props?.setErrorBusinessModel(false);
        if (formStateCompany?.targeted_industry?.length > 0) props?.setErrorIndustry(false);
        if (filteredSolution(allSolutions)?.length > 0) props?.setErrorSolution(false);
        if (formStateCompany?.geographic_sales_footprint?.length > 0) props?.setErrorGeographic(false);
        if (formStateCompany?.have_technology !== null) props?.setErrorHaveTechnoPartnership(false);
        if (formStateCompany?.have_technology === true && formStateCompany?.technology_ecosystem.length > 0) props?.setErrorTechnoPartnership(false);
        if (formStateCompany?.have_technology === false) props?.setErrorTechnoPartnership(false);
        if (formStateNecessaryInfo?.summarize !== "") props?.setErrorAcquirer(false);
        if (Number(formStateNecessaryInfo?.lifecycle_stage) !== 0) props?.setErrorStage(false);
        if (Number(formStateAcquisition?.business_value) !== 0) props?.setErrorValueBusiness(false);
    }, [formStateCompany?.location, props, formStateCompany?.founded, formStateCompany?.company_size, formStateCompany?.business_model, formStateCompany?.targeted_industry, allSolutions, formStateCompany?.geographic_sales_footprint, formStateCompany?.have_technology, formStateCompany?.technology_ecosystem, formStateNecessaryInfo?.summarize, formStateNecessaryInfo?.lifecycle_stage, formStateAcquisition?.business_value, filteredSolution]);

    const handleAccordionOpen = (eventKey: string | null) => {
        if (eventKey && eventKey !== isAccordionOpen.toString()) {
            setIsAccordionOpen(true);
        }
    };

    const setActiveSection = (eventKey: string | null) => {
        handleAccordionOpen(eventKey);
        toggleActive(eventKey);
    };

    const handleExit = () => {
        setIsAccordionOpen(false);
        setActiveId('');
        setActiveGeoId('');
        return props.handleOk && props.handleOk();
    }


    return (
        <Modal
            show={props.show}
            className='createCompanypopup large forStep5custom edit-modal buyer-modal'
        >
            <Modal.Header>
                <div className='headerpopupCompany noStep'>
                    <h2 className='title'>Company overview</h2>
                    <a
                        className='close'
                        onClick={() => !props.isLoading && handleExit()}
                    >
                        <span className='icon-close-pop'></span>
                    </a>
                </div>
            </Modal.Header>
            <Modal.Body id="mainterest" className=''>
                <Accordion defaultActiveKey={activeId}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header onClick={() => setActiveSection('0')}>
                            <div className='form-group required mb-2 p-0 overview'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>Company overview:</label>{" "}
                                {(!isAccordionOpen ||  activeId != '0') && <span>{formStateNecessaryInfo?.summarize}</span>}
                                {
                                    props?.errorAcquirer && (
                                        <div className="container-error d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <textarea className='form-control mt-2'
                                maxLength={500}
                                name="summarize"
                                value={formStateNecessaryInfo?.summarize}
                                onChange={(evt) => updateStateNecessaryInfo(evt.target.name, evt.target.value)}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header onClick={() => setActiveSection('1')}>
                            <div className='form-group required mb-2 p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>Where is your headquarter?</label>{" "}
                                {(!isAccordionOpen ||  activeId != '1') && <span>{ISO3166.getCountry(formStateCompany?.location)}</span>}
                                {
                                    props?.errorLocation && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <select className='form-control mt-2'
                                name='location'
                                onChange={(evt) => updateStateCompany(evt.target.name, evt.target.value)}
                            >
                                <option value="">Select an option ...</option>
                                {
                                    props?.dataCountry?.data?.map((el: any, index: any) => (
                                        <option selected={el?.attributes?.ISO2 == formStateCompany?.location} value={el?.attributes?.ISO2} key={index}>{el?.attributes?.Name}</option>
                                    ))
                                }
                            </select>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header onClick={() => setActiveSection('2')}>
                            <div className='form-group required mb-2 p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>In what year was your company founded?</label>{" "}
                                {(!isAccordionOpen ||  activeId != '2') && <span>{formStateCompany?.founded}</span>}
                                {
                                    props?.errorFounded && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <select className='form-control mt-2'
                                name='founded'
                                onChange={(evt) => updateStateCompany(evt.target.name, evt.target.value)}
                            >
                                <option value="">Select an option ...</option>
                                {
                                    years?.map((el: any, index: any) => (
                                        <option selected={el == formStateCompany?.founded} value={el} key={index}>{el}</option>
                                    ))
                                }
                            </select>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header onClick={() => setActiveSection('3')}>
                            <div className='form-group required mb-2 p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>How many people does your company employ?</label>{" "}
                                {(!isAccordionOpen ||  activeId != '3') && <span>{props?.dataHowManyPeople?.data?.find((el: any) => el?.id == formStateCompany?.company_size)?.attributes?.Label}</span>}
                                {
                                    props?.errorCompanySize && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <select className='form-control mt-2'
                                name='company_size'
                                onChange={(evt) => updateStateCompany(evt.target.name, evt.target.value)}
                            >
                                <option value={0}>Select an option ...</option>
                                {
                                    props?.dataHowManyPeople?.data?.map((el: any, index: any) => (
                                        <option selected={el?.id == formStateCompany?.company_size} value={el?.id} key={index}>{el?.attributes?.Label}</option>
                                    ))
                                }
                            </select>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header onClick={() => setActiveSection('4')}>
                            <div className='form-group required mb-2 p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>What is your business model?</label>{" "}
                                {(!isAccordionOpen ||  activeId != '4') && <span>{(!props?.errorBusinessModel && formStateCompany?.business_model?.length > 0) && formStateCompany?.business_model?.length + ' business model selected'}</span>}
                                {
                                    props?.errorBusinessModel && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            {/* <select className='form-control mt-2'
                                name='business_model'
                                onChange={(evt) => updateStateCompany(evt.target.name, evt.target.value)}
                            >
                                <option value="">Select an option ...</option>
                                {
                                    props?.dataBusinessModel?.data?.map((el: any, index: any) => (
                                        <option selected={el?.attributes?.Label == formStateCompany?.business_model} value={el?.attributes?.Label} key={index}>{el?.attributes?.Label}</option>
                                    ))
                                }
                            </select> */}
                            {/* <Select
                                closeMenuOnSelect={false}
                                filterOption={() => true}
                                className='form-control mt-2 p-0'
                                classNamePrefix='react-select'
                                placeholder="Select all that apply ..."
                                name='business_model'
                                id="selectable"
                                isMulti={true}
                                value={selectedBusinessModel}
                                options={transformedData as ITransformedBusinessModel[]}
                                onChange={(selectedOptions) => {
                                    setSelectedBusinessModel(selectedOptions as ITransformedBusinessModel[]);
                                    const businessModel = selectedOptions.map(option => option.label);
                                    updateStateCompany('business_model', businessModel);
                                }}
                            /> */}
                            <Select
                                classNamePrefix='react-select'
                                className='form-control mt-2 p-0'
                                filterOption={() => true}
                                closeMenuOnSelect={false}
                                placeholder="Select all that apply ..."
                                name='business_model'
                                id="selectable"
                                isMulti={true}
                                value={selectedBusinessModel}
                                options={filteredOptions}
                                onChange={(selectedOptions) => {
                                    setSelectedBusinessModel(selectedOptions as ITransformedBusinessModel[]);
                                    const businessModel = selectedOptions.map(option => option.value);
                                    updateStateCompany('business_model', businessModel);
                                }}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header onClick={() => setActiveSection('5')}>
                            <div className='form-group required mb-2 p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>What stage of the lifecycle is your business in?</label>{" "}
                                {(!isAccordionOpen ||  activeId != '5') && <span>{props?.dataLifecycle?.data?.find((el: any) => el?.id == formStateNecessaryInfo?.lifecycle_stage)?.attributes?.Label}</span>}
                                {
                                    props?.errorStage && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <select className='form-control mt-2'
                                name='lifecycle_stage'
                                onChange={(evt) => updateStateNecessaryInfo(evt.target.name, evt.target.value)}
                            >
                                <option value={0}>Select an option ...</option>
                                {
                                    props?.dataLifecycle?.data?.map((el: any, index: any) => (
                                        <option selected={el?.id == formStateNecessaryInfo?.lifecycle_stage} value={el?.id} key={index}>{el?.attributes?.Label}</option>
                                    ))
                                }
                            </select>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header onClick={() => setActiveSection('6')}>
                            <div className='form-group required mb-2 p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>How would you value your business?</label>{" "}
                                {(!isAccordionOpen ||  activeId != '6') && <span>{props?.dataHowValueBusiness?.data?.find((el: any) => el?.id == formStateAcquisition?.business_value)?.attributes?.Label}</span>}
                                {
                                    props?.errorValueBusiness && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <select className='form-control mt-2'
                                name='business_value'
                                onChange={(evt) => updateStateAcquisition(evt.target.name, evt.target.value)}
                            >
                                <option value={0}>Select an option ...</option>
                                {
                                    props?.dataHowValueBusiness?.data?.map((el: any, index: any) => (
                                        <option selected={el?.id == formStateAcquisition?.business_value} value={el?.id} key={index}>{el?.attributes?.Label}</option>
                                    ))
                                }
                            </select>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header onClick={() => setActiveSection('7')}>
                            <div className='form-group required mb-2 p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>What vertical industry(ies) do you serve?</label>{" "}
                                {(!isAccordionOpen ||  activeId != '7') && <span>{(!props?.errorIndustry && formStateCompany?.targeted_industry?.length > 0) && formStateCompany?.targeted_industry?.length + ' industry(ies) selected'}</span>}
                                {
                                    props?.errorIndustry && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <IndustriesList
                                industriesSelected={formStateCompany?.targeted_industry}
                                addIndustry={addTargetedIndustry}
                                removeIndustry={removeTargetedIndustry}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                        <Accordion.Header onClick={() => setActiveSection('8')}>
                            <div className='form-group required mb-2 p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>What horizontal solution(s) do you provide?</label>{" "}
                                {(!isAccordionOpen ||  activeId != '8') && <span>{(!props?.errorSolution && filteredSolution(allSolutions)?.length > 0) && filteredSolution(allSolutions)?.length + ` solution(s) selected`}</span>}
                                {
                                    props?.errorSolution && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <SolutionsList
                                featuredSolutionSelected={formStateCompany?.featured_solution}
                                solutionsSelected={formStateCompany?.software_solution}
                                addFeaturedSolution={addFeaturedSolution}
                                addSoftwareSolution={addSoftwareSolution}
                                removeSolution={removeSolution}
                                removeItemSelected={removeItemSelected}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                        <Accordion.Header onClick={() => setActiveSection('9')}>
                            <div className='form-group required mb-2 p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>What is your geographic sales footprint?</label>{" "}
                                {(!isAccordionOpen ||  activeId != '9') && <span>{(!props?.errorGeographic && formStateCompany?.geographic_sales_footprint?.length > 0) && formStateCompany?.geographic_sales_footprint?.length + ` country(ies) selected`}</span>}
                                {
                                    props?.errorGeographic && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="geographicList">
                                <Accordion defaultActiveKey={activeGeoId}>
                                    <Countries
                                        geographicData={props?.geographicData}
                                        activeId={activeGeoId}
                                        toggleActive={toggleActiveGeo}
                                        data={formStateCompany?.geographic_sales_footprint}
                                        stateName={"geographic_sales_footprint"}
                                        updateState={updateStateCompany}
                                        isEdit={true}
                                    />
                                </Accordion>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="10">
                        <Accordion.Header onClick={() => setActiveSection('10')}>
                            <div className='form-group required mb-2 p-0'>
                            <div className=''>
                                <label htmlFor='' style={{ fontWeight: 600 }}>
                                    Do you currently have any technology partnerships with
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id="trade">
                                                Software vendors (ISVs) that offer an embedded or white labeled solution that
                                                becomes an integral part of another vendors’s software,
                                                resulting in a strategic partnership.
                                            </Tooltip>
                                        }
                                    >
                                        <span className="oemTooltip">OEMs</span>
                                    </OverlayTrigger>
                                    or
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id="trade">
                                                Software ecosystems that provide software vendors (ISVs) with tools, platforms,
                                                technologies they can build solutions on top of.
                                            </Tooltip>
                                        }
                                    >
                                        <span className="oemTooltip">Ecosystems?</span>
                                    </OverlayTrigger>
                                    {
                                    props?.errorTechnoPartnership && (
                                        <div className="container-error d-inline m-0" style={{ color: 'red' }}>
                                            <span style={{ margin: 0, padding: 0 }} className='error-red'> ERROR </span>{" "}
                                            <span style={{ margin: 0, padding: 0 }} className='error-red'>This field is required</span>
                                        </div>
                                    )
                                }
                                </label>
                                {
                                    props?.errorHaveTechnoPartnership && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                            </div>
                                {
                                    props?.errorHaveTechnoPartnership && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                                {
                                    props?.errorTechnoPartnership && (
                                        <div className="container-error d-inline ml-2" style={{ color: 'red' }}>
                                            <span style={{margin:0, padding:0}} className='error-red'> ERROR </span>{" "}
                                            <span style={{margin:0, padding:0}} className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>

                            <div className='form-group mb-4 p-0'>
                                <div
                                    className="checkcontainer"
                                >
                                    <Form.Check
                                        name="have_technology"
                                        type="radio"
                                        label="Yes"
                                        id="Yes"
                                        onClick={() => updateStateCompany("have_technology", true)}
                                        checked={formStateCompany?.have_technology === true ? true : false}
                                        onChange={() => null}
                                    />
                                </div>
                                <div
                                    className="checkcontainer"
                                >
                                    <Form.Check
                                        name="have_technology"
                                        type="radio"
                                        label="No"
                                        id="No"
                                        onClick={() => updateStateCompany("have_technology", false)}
                                        checked={formStateCompany?.have_technology === false ? true : false}
                                        onChange={() => null}
                                    />
                                </div>
                            </div>

                            <div className='form-group mb-4 p-0'>
                                {
                                    formStateCompany?.have_technology !== null && formStateCompany?.have_technology && (
                                        // <Select
                                        // options={props?.dataTechnologyPartenership?.data?.map((el: any) => {
                                        //     return {
                                        //         label: el?.attributes?.Label,
                                        //         value: el?.attributes?.Label
                                        //     };
                                        // })}
                                        //     filterOption={() => true}
                                        //     className='form-control mt-2 p-0'
                                        //     classNamePrefix='react-select'
                                        //     placeholder="Select all that apply ..."
                                        //     name='technology_ecosystem'
                                        //     id="selectable"
                                        //     isMulti={true}
                                        //     value={formStateCompany?.technology_ecosystem}
                                        //     onChange={(selectedOptions: any) => {

                                        //         updateStateCompany('technology_ecosystem', selectedOptions);
                                        //     }}
                                        // />
                                        <>
                                            <div className='form-group required mb-2 p-0 eco paterneer position-relative'>
                                                <CreatableSelect
                                                    options={filteredTechnoOptions}
                                                    className="input-text form-control mt-2 p-0"
                                                    classNamePrefix="partneer"
                                                    onChange={(el: any) => {
                                                        setCurrentSelected(el);
                                                    }}
                                                    placeholder="Select all that apply..."
                                                    value={currentSelected}
                                                />
                                                <button id="add-partneer" className='border-0 d-flex align-items-center' onClick={() =>
                                                    isHaveSelectedLabel &&
                                                    addListOption(currentSelected)
                                                }>
                                                    <svg width="30" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="#fff" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                    </svg>

                                                </button>
                                            </div>
                                            {currentSelectedList &&
                                                currentSelectedList.map(
                                                    (el: { value: number; label: string }, index: number) => (
                                                        <div className="paterneer d-flex p-0 mb-2" key={index}>
                                                            <input
                                                                type="text"
                                                                className="input-text form-control"
                                                                placeholder=""
                                                                value={el.label}
                                                                disabled={true}
                                                            />
                                                            <button style={{ background: "#d9534f" }} className='border-0 red-button' onClick={() => removeListOption(el.value)}>
                                                                <svg width="30" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#fff" className="w-6 h-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    )
                                                )
                                            }
                                        </>
                                    )
                                }
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Modal.Body>
            <Modal.Footer>
                <div className='text-align-right'>
                    {!isAccordionOpen && (
                        <>
                            <button
                                style={{ marginLeft: "1rem" }}
                                className='btn btn-devinsider save'
                                onClick={() => handleExit()}>
                                Close
                            </button>
                        </>
                    )}
                    {isAccordionOpen && (
                        <>
                            <button
                                style={{ marginLeft: "1rem" }}
                                className='btn btn-devinsider cancel'
                                onClick={() => !props.isLoading && handleExit()}>
                                Cancel
                            </button>
                            <button
                                className='btn btn-devinsider save'
                                onClick={() => !props.isLoading && save()}>

                                {props.isLoading ? <Loader /> : "Save"}
                            </button>
                        </>
                    )}
                </div>
            </Modal.Footer>
        </Modal>
    )
}
import { useEffect, useState } from "react";
import { useKeycloakHooks } from "../../../../hooks/keycloak";
import { useGetSellerMatchingListing, UseGetMySellerListing } from "../../../../api/seller";
import { UseAddToFavorite, UseGetMyBuyerFilterListing, useGetMyBuyerListing } from "../../../../api/buyer";
import { LoaderCommon } from "../../../../widget/loader";
import { useGetTypeUser } from "../../../../api/user";
import { Navigate, useNavigate } from "react-router-dom";
import { urlsApi } from "../../../../api/urls/apiUrl";
import ISO3166 from 'iso-3166-1-alpha-2';
import '../../seller.scss';
import SellerSecondMenu from "../../../../layouts/second-headers/seller";
import BuyerSecondMenu from "../../../../layouts/second-headers/buyer";
import { addToArray, paginate } from "../../../../utils/outils";
import SeeBtn from "../../../../components/seeBtn";
import { getRoleDisplayName } from "../../../../utils/role-mapping";
import Lock from "../../../../resources/images/lock.svg";
import { useMutation, useQuery } from 'react-query';
import { useGetStaticParams } from "../../../../api/staticParams";
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";

export default function SellerMatchingListing() {
    const navigate = useNavigate();

    const [enabled, setEnabled] = useState(false);
    const [searchInput, setSearchInput] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [refetchSource, setRefetchSource] = useState("");

    const { keycloak, tokenInfo, initialized } = useKeycloakHooks();

    const mutGetMatching = useMutation({
        mutationFn: useGetSellerMatchingListing,
        onSuccess: (data) => {
            if (data.status === 200) {
                refetchDatamatching();
            } else {
                refetchDatamatching();
            }
            setIsLoading(false);
        },
        onError: () => {
            setIsLoading(false); 
        }
    });

    const fetchMyMatching = async (params: any): Promise<any> => {
        if (isInitialLoading || refetchSource !== "favorite") {
            setIsLoading(true);
        }
    
        const dataParam = {
            params: params
        };
        
        try {
            const response = await mutGetMatching.mutateAsync(dataParam);
            return response;
        } catch (error) {
            console.error("Error fetching matching data:", error);
            throw error;
        } finally {
            setIsInitialLoading(false);
            setIsLoading(false);
        }
    };

    const [enabledMySellerListing, setEnabledMySellerListing] = useState(false);
    const { data: sellerListing } = UseGetMySellerListing(enabledMySellerListing);

    const [enabledGetMyBuyerListing, setEnabledGetMyBuyerListing] = useState(false);
    const { data: buyerListing } = useGetMyBuyerListing(enabledGetMyBuyerListing);

    const [enabledData, setEnabledData] = useState(true);
    const { data: mainAcquisition, isFetched: isMainAcquisitionsFetched } = useGetStaticParams('MAIN_PURPOSES', enabledData);
    const { data: horizontals, isFetched: isHorizontalsFetched } = useGetStaticParams('HORIZONTALS', enabledData);
    const { data: verticals, isFetched: iVerticalsFetched } = useGetStaticParams('VERTICALS', enabledData);
    

    const useMyBuyerMatchingListing = (enabled: boolean) => {
        const { data, isFetched, refetch, isLoading } = useQuery(
            ['mySellerMatching'],
            () => fetchMyMatching({}),
            {
                enabled: enabled
            }
        );
        return {
            data: data,
            isFetched: isFetched,
            refetch: refetch,
            isLoading: isLoading
        };
    };

    const { data: dataMatching, isLoading: loadingDataMatching, isFetched: dataMatchingFetched, refetch: refetchDatamatching } = useMyBuyerMatchingListing(enabled);
    const { data: userRoles } = useGetTypeUser(tokenInfo, enabled);

    const [fitlersSolutions, setFilterSolutions] = useState<string[]>([]);
    const [fitlersIndustries, setFilterIndustries] = useState<string[]>([]);
    const [fitlersLocations, setFilterLocations] = useState<string[]>([]);

    const [limitSolution, setLimitSolution] = useState(6);
    const [limitIndustry, setLimitIndustry] = useState(6);
    const [limitLocation, setLimitLocation] = useState(6);
    const [selectedSolution, setSelectedSolution] = useState<Array<string>>([]);
    const [selectedIndustry, setSelectedIndustry] = useState<Array<string>>([]);
    const [selectedLocation, setSelectedLocation] = useState<Array<string>>([]);
    const [selectedStatus, setSelectedStatus] = useState<boolean>();

    const [isLocationOpen, setIsLocationOpen] = useState(true);
    const [isSolutionOpen, setIsSolutionOpen] = useState(true);
    const [isIndustryOpen, setIsIndustryOpen] = useState(true);
    const [isStatusOpen, setIsStatusOpen] = useState(true);

    const [filteredDatas, setFilteredDatas] = useState<Array<any>>();

    const [isDataLoading, setIsDataLoading] = useState(false);

    useEffect(() => {
        if (sellerListing) {
            setEnabledMySellerListing(false);
        }
    }, [sellerListing])

    useEffect(() => {
        if (buyerListing) {
            setEnabledGetMyBuyerListing(false);
        }
    }, [buyerListing])

    useEffect(() => {
        if (isMainAcquisitionsFetched && isHorizontalsFetched && iVerticalsFetched) {
            setEnabledData(false);
        }
    }, [isMainAcquisitionsFetched, isHorizontalsFetched, iVerticalsFetched])

    const getUniqueSolutionLabels = () => {
        return Array.from(new Set(
          fitlersSolutions?.map((el: any) => 
            horizontals?.data?.find((element: any) => el == element?.id)?.attributes?.Label
          )
        ));
    };

    const handleSeeSolution = (action: string) => {
        const uniqueLabelsCount = getUniqueSolutionLabels()?.length;
        setLimitSolution(action === '0' ? uniqueLabelsCount : 6);
    };

    const handleSeeIndustry = (action: string) => {
        setLimitIndustry(action === '0' ? fitlersIndustries.length : 6);
    };

    const handleSeeLocation = (action: string) => {
        setLimitLocation(action === '0' ? fitlersLocations.length : 6);
    }

    const handleSelectSolution = (element: any) => {
        setSelectedSolution(addToArray(selectedSolution, element));
    }

    const handleSelectIndustry = (element: string) => {
        setSelectedIndustry(addToArray(selectedIndustry, element));
    }

    const handleSelectLocation = (element: any) => {
        setSelectedLocation(addToArray(selectedLocation, element));
    }

    const handleSelectStatus = (element: boolean) => {
        if (element === false) {
            setSelectedStatus(false);
        } else setSelectedStatus(true);
    }
    const mutFilters = useMutation({
        mutationFn: UseGetMyBuyerFilterListing,
        onSuccess: (data) => {
            refetchDatamatching();
        }
    })
    const filterMatchingListing = async () => {
        setRefetchSource("");
        setIsDataLoading(true);
        try {
            const param = {
                location: selectedLocation,
                horizontal: selectedSolution,
                vertical: selectedIndustry,
                status: selectedStatus
            };
        
            const response = await fetchMyMatching(param);
            const data = response.data;
            
            const finalFilteredData = data.length > 0 ? data.map((item: any) => {
                // Try to find the corresponding item in the original data
                const originalItem = dataMatching?.data.find((original: any) => original.id === item.id);
                // If found, preserve its favorite state
                if (originalItem) {
                    return {
                        ...item,
                        isFavorite: originalItem.isFavorite
                    };
                }
                return item;
            }) : [];
            
            setFilteredDatas(finalFilteredData)
        } catch(e) {

        }

        finally {
            setIsDataLoading(false);
        }
    }
    
    // const filterMatchingListing = () => {
    //     const param = {
    //         location: selectedLocation,
    //         horizontal: selectedSolution,
            // vertical: selectedIndustry,
            // status: selectedStatus
    //     };


    //     const finalFilteredData = filteredData?.length > 0 ? filteredData : [];
    //     setFilteredDatas(finalFilteredData);
    // }

    const resetFilterMatchingListing = () => {
        setSearchInput('');
        setRefetchSource("");
        setSelectedSolution([]);
        setSelectedLocation([]);
        setSelectedIndustry([]);
        setSelectedStatus(undefined);
        setFilteredDatas(undefined);
    }

    const toggleFilterSection = (setState: React.Dispatch<React.SetStateAction<boolean>>) => {
        setState(prevState => !prevState);
    };

    const isMotivated = (el: any) => {
        var isMotivated = false;

        if (el?.userAccount?.is_premium) isMotivated = true;

        return isMotivated;
    }



    const handleSearch = () => {
        if (searchInput.trim() !== '') {
            const filteredData = dataMatching?.data?.filter((el: any) => {
                const country = ISO3166.getCountry(el?.buyer?.location);
                const locationMatches = country && country?.toLowerCase().includes(searchInput.toLowerCase());
                const goalMatches = mainAcquisition?.data?.find((element: any) => element?.id == el?.buyer?.main_acquisition_goal)?.attributes?.Label?.toLowerCase().includes(searchInput.toLowerCase());
                const hqLocationMatches =
                    el?.buyer?.preferred_hq_location &&
                    el?.buyer?.preferred_hq_location?.some((hqLocation: any) =>
                        ISO3166?.getCountry(hqLocation.iso2)?.toLowerCase().includes(searchInput.toLowerCase())
                    );


                const companyReasonMatches = el?.buyer?.company_reason?.toLowerCase().includes(searchInput.toLowerCase());
                const stageMatches = el?.buyer?.stage?.toLowerCase().includes(searchInput.toLowerCase());

                return (
                    locationMatches ||
                    goalMatches ||
                    hqLocationMatches ||
                    companyReasonMatches ||
                    stageMatches
                );
            });

            return filteredData && filteredData.length > 0 ? filteredData : [];
        }

        return [];
    };

    const goToBuyerDetails = (id: number) => {
        navigate(`/buyer/listing/detail/matching/${id}`);
    }

    const hasFilter = fitlersLocations.length > 0 && fitlersSolutions.length > 0 && fitlersIndustries.length > 0 && userRoles && dataMatching?.data.length > 0;

    let shouldDisplayBlock = false;

    if (hasFilter == true) {
        shouldDisplayBlock = true;
    }

    useEffect(() => {
        if (keycloak?.token) {
            setEnabled(true);
            setEnabledMySellerListing(true);
            setEnabledGetMyBuyerListing(true);
            setIsInitialLoading(true);
            setRefetchSource("");
        }
        if (userRoles && dataMatching) {
            setEnabled(false);
            setIsInitialLoading(false);
        }
    }, [keycloak?.token, dataMatching, userRoles]);

    useEffect(() => {
        if (dataMatchingFetched) {
            setFilterSolutions(Array.from(new Set(dataMatching?.data.flatMap((dm: any) => [...dm.buyer.preferred_software, ...dm.buyer.preferred_featured_software]))));
            setFilterIndustries(Array.from(new Set(dataMatching?.data.flatMap((dm: any) => dm.buyer.preferred_industries))));
            setFilterLocations(Array.from(new Set(dataMatching?.data.map((dm: any) => dm.buyer.location))));
        }
    }, [dataMatching, dataMatchingFetched]);


    const mutAddToFavorite = useMutation({
        mutationFn: UseAddToFavorite,
        onSuccess: (data) => {
            if (data.status === 200) {
                setRefetchSource("favorite");
                refetchDatamatching();
                if (filteredDatas && filteredDatas.length > 0) {
                    const toggledItemId = data.data?.id;
                    if (toggledItemId) {
                        const updatedFilteredData = filteredDatas.map(item => {
                            if (item.id === toggledItemId) {
                                return {
                                    ...item,
                                    isFavorite: !item.isFavorite
                                };
                            }
                            return item;
                        });
                        setFilteredDatas(updatedFilteredData);
                    }
                }
            } else {
                setRefetchSource("favorite");
                refetchDatamatching();
            }
        }
    });

    const toggleFavorite = async (itemId: any): Promise<any> => {
        setRefetchSource("favorite");
        const dataParam = {
            idBuyer: itemId,
        }
        const response = await mutAddToFavorite.mutateAsync(dataParam);
        if (filteredDatas && filteredDatas.length > 0) {
            const updatedFilteredData = filteredDatas.map(item => {
                if (item.id === itemId) {
                    return {
                        ...item,
                        isFavorite: !item.isFavorite
                    };
                }
                return item;
            });
            setFilteredDatas(updatedFilteredData);
        }
        return response;
    }

    const goToBuyerMatching = () => {
        if(buyerListing && buyerListing?.is_published && !buyerListing?.is_removed)
            navigate("/buyer/listing/my-matching");
        else 
            navigate("/seller/listing");
    };

    const goToSellerMatching = () => {
        if(sellerListing && sellerListing?.seller && sellerListing?.seller?.is_published && !sellerListing?.seller?.is_removed)
            navigate("/seller/listing/my-matching");
    };

    const goToEnrollSeller = () => {
        navigate("/seller/listing/creation");
    }

    return (
        <>
            {((initialized && loadingDataMatching) || !initialized || !dataMatching || isDataLoading || isLoading) &&
                <div className='loaderCommon'>
                    <LoaderCommon />
                </div>
            }

            {(initialized && !keycloak?.authenticated) && <Navigate to="/" />}

            {(initialized && keycloak?.authenticated && dataMatching && !loadingDataMatching && !isDataLoading && !isLoading) &&
                (
                    <div id="my-listing" className="findIsvcontainer programContainer sellerContainer my-listing top">

                        {
                            userRoles?.isSeller ? (
                                <SellerSecondMenu
                                    showSearch={true}
                                    searchInput={searchInput}
                                    setSearchInput={setSearchInput}
                                    handleSearch={handleSearch}
                                    handleGoToFavorite={() => null}
                                />
                            ) : (
                                <BuyerSecondMenu
                                    showSearch={true}
                                    searchInput={searchInput}
                                    setSearchInput={setSearchInput}
                                    handleSearch={handleSearch}
                                    handleGoToFavorite={() => null}
                                />
                            )
                        }

                        <div className="filterFindIsv leftTop hScroll">
                            <div className="contentFilter">
                                <div className="block sellerBuyer pb-0 border-0">
                                    <div className="sellers" onClick={() => goToBuyerMatching()}>
                                        <a href="javascript:;">
                                            <span className="icon-sellers"></span>Sellers
                                        </a>
                                    </div>
                                    <div className="buyer active" onClick={() => goToSellerMatching()}>
                                        <a href="javascript:;">
                                            <span className="icon-buyerList"></span>Buyers
                                        </a>
                                    </div>
                                </div>

                                {
                                    shouldDisplayBlock && (
                                        <>
                                            <div className="containerBtn block infoProgramContainer border-0">
                                                <button className="btn btn-devinsider cancel" onClick={() => filterMatchingListing()}>Filter</button>
                                                <a className="resetFilter" onClick={() => resetFilterMatchingListing()}>
                                                    <span className="icon-refresh" />
                                                    Reset filter
                                                </a>
                                            </div>

                                            <div className="block">
                                                <h3 className={`title withChild ${isLocationOpen ? 'open' : 'closed'}`} onClick={() => toggleFilterSection(setIsLocationOpen)}>HQ location</h3>
                                                <div className="listCheck">
                                                    {
                                                        paginate(fitlersLocations, limitLocation)?.sort((a, b) => {
                                                            const countryA = ISO3166.getCountry(a) || "";
                                                            const countryB = ISO3166.getCountry(b) || "";
                                                            return countryA.localeCompare(countryB);
                                                        })?.map((el: any, index: any) => (
                                                            <div className="custom-control custom-checkbox orange" key={index}>
                                                                <input
                                                                    type="checkbox"
                                                                    id={`location${index}`}
                                                                    className="custom-control-input"
                                                                    onChange={() => handleSelectLocation(el)}
                                                                    checked={
                                                                        selectedLocation?.find(
                                                                            (selected: any) => selected === el
                                                                        )
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                <label htmlFor={`location${index}`} className="custom-control-label">
                                                                    {ISO3166.getCountry(el)}
                                                                </label>
                                                            </div>
                                                        ))
                                                    }
                                                    <SeeBtn
                                                        dataSource={fitlersLocations}
                                                        limit={limitLocation}
                                                        seeMoreOrLess={(p: string) => handleSeeLocation(p)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="block">
                                                <h3 className={`title withChild ${isSolutionOpen ? 'open' : 'closed'}`} onClick={() => toggleFilterSection(setIsSolutionOpen)}>Solution (Horizontal)</h3>
                                                <div className="listCheck">
                                                    {
                                                        getUniqueSolutionLabels()
                                                        .slice(0, limitSolution) 
                                                        .map((uniqueLabel: any, index: number) => {
                                                          const el = fitlersSolutions?.find((solutionId: any) => 
                                                            horizontals?.data?.find((element: any) => 
                                                              solutionId == element?.id && element?.attributes?.Label === uniqueLabel
                                                            )
                                                        );
                                                          
                                                            return (
                                                                <>
                                                                    <div className="custom-control custom-checkbox orange" key={index}>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={`solutions${index}`}
                                                                            className="custom-control-input"
                                                                            onChange={() => handleSelectSolution(el)}
                                                                            checked={
                                                                                selectedSolution?.find(
                                                                                    (selected: any) => selected === el
                                                                                )
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                        />
                                                                        <label htmlFor={`solutions${index}`} className="custom-control-label">
                                                                            {horizontals?.data?.find((element: any) => element?.id == el)?.attributes?.Label}
                                                                        </label>
                                                                    </div>
                                                                </>
                                                            );
                                                        })
                                                    }
                                                    <SeeBtn
                                                        dataSource={getUniqueSolutionLabels()}
                                                        limit={limitSolution}
                                                        seeMoreOrLess={(p: string) => handleSeeSolution(p)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="block">
                                                <h3 className={`title withChild ${isIndustryOpen ? 'open' : 'closed'}`} onClick={() => toggleFilterSection(setIsIndustryOpen)}>Industry (Vertical)</h3>
                                                <div className="listCheck">
                                                    {
                                                        paginate(fitlersIndustries, limitIndustry)?.sort()?.map((el: any, index: any) => (
                                                            <>
                                                                <div className="custom-control custom-checkbox orange" key={index}>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`industries${index}`}
                                                                        className="custom-control-input"
                                                                        onChange={() => handleSelectIndustry(el)}
                                                                        checked={
                                                                            selectedIndustry?.find(
                                                                                (selected: any) => selected === el
                                                                            )
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                    <label htmlFor={`industries${index}`} className="custom-control-label">
                                                                        {verticals?.data?.find((element: any) => element?.id == el)?.attributes?.Label}
                                                                    </label>
                                                                </div>
                                                            </>
                                                        ))
                                                    }
                                                    <SeeBtn
                                                        dataSource={fitlersIndustries}
                                                        limit={limitIndustry}
                                                        seeMoreOrLess={(p: string) => handleSeeIndustry(p)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="block">
                                                <h3 className={`title withChild ${isStatusOpen ? 'open' : 'closed'}`} onClick={() => toggleFilterSection(setIsStatusOpen)}>Status</h3>
                                                <div className="listCheck">
                                                    <div className="custom-control custom-checkbox orange">
                                                        <input
                                                            type="checkbox"
                                                            id={`status0`}
                                                            className="custom-control-input"
                                                            onChange={() => handleSelectStatus(false)}
                                                            checked={
                                                                selectedStatus === false
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        <label htmlFor={`status0`} className="custom-control-label">
                                                            Locked
                                                        </label>
                                                    </div>
                                                    <div className="custom-control custom-checkbox orange">
                                                        <input
                                                            type="checkbox"
                                                            id={`status1`}
                                                            className="custom-control-input"
                                                            onChange={() => handleSelectStatus(true)}
                                                            checked={
                                                                selectedStatus === true
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        <label htmlFor={`status1`} className="custom-control-label">
                                                            Unlocked
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }

                            </div>
                        </div>

                        <div className="container mx-auto minHeightwin sellerRight">
                            <div className="listOfIsv listOfSeller scrollContainer">
                                <div className="aboutSection">
                                    {
                                        !userRoles?.isSeller ? (
                                            <>
                                                To access buyer listings, enroll as a seller.

                                                <div className="mt-3">
                                                    <a
                                                        className="btn btn-devinsider"
                                                        href="javascript:;"
                                                        onClick={() => goToEnrollSeller()}
                                                    >
                                                        Enroll now!
                                                    </a>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                                                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                                                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                reprehenderit in voluptate velit esse cillum dolore eu fugiat
                                                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                                                sunt in culpa qui officia deserunt mollit anim id est laborum..
                                            </>
                                        )
                                    }

                                </div>

                                <ul className="listSeller">
                                    {searchInput.trim() !== '' ? (
                                        handleSearch().map((el: any, index: number) => (
                                            <li className={`list ${isMotivated(el?.buyer) ? 'motivated' : ''}`} key={index}>
                                                {/* {el?.buyer?.company_image !== null && el?.buyer?.company_image !== undefined && el?.buyer?.company_image !== "" ? (
                                                    <>
                                                        <div>
                                                            <div className="logo">
                                                                <img src={process.env.REACT_APP_API_URL + urlsApi.upload.get_upload_image + '/' + el?.buyer?.company_image}
                                                                    width={60}
                                                                    height={60}
                                                                    alt="Company Logo" />
                                                            </div>
                                                            <div className='match'>
                                                                <span>match</span>
                                                                <span>{parseInt(el?.matching) < 60 ? '<60' : el?.matching}%</span>
                                                            </div>
                                                        </div>


                                                    </>

                                                ) : ( */}
                                                    <>
                                                        <div className="logo">
                                                            <span className='icon-building1'></span>
                                                        </div>
                                                        <div className='match'>
                                                            <span>match</span>
                                                            <span>{parseInt(el?.matching) < 60 ? '<60' : el?.matching}%</span>
                                                            {/*<span>{el?.matching}%</span>*/}
                                                        </div>
                                                    </>
                                                {/* )
                                                } */}
                                                <div className="desc">
                                                    <div className="title">
                                                        {el?.buyer?.company_name !== "" && el?.buyer?.company_name !== "user_anonyma" ? 'Company Confidential' : 'Company Confidential'} 
                                                        <OverlayTrigger
                                                            overlay={
                                                                <Tooltip className="custom-tooltip">
                                                                    { el?.isFavorite ? 'Click here to remove bookmark' : 'Click here to bookmark the listing' }
                                                                </Tooltip>
                                                            }
                                                            >
                                                            <span className={`icon-star-${el?.isFavorite ? 'on' : 'off'}`} onClick={() => toggleFavorite(el?.id)}></span>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div className="about fs-6 mt-2">
                                                        <span>{el?.buyer?.company_reason}</span>
                                                    </div>
                                                    <a className="btn btn-devinsider" href="javascript:;" onClick={() => goToBuyerDetails(el?.buyer?.id)}>More details</a>
                                                    <ul>
                                                        <li className="item">
                                                            <div>Location</div>
                                                            <span className="ico icon-location"></span>
                                                            <span className="txt">{ISO3166.getCountry(el?.buyer?.location)}</span>
                                                        </li>
                                                        <li className="item">
                                                            <div>Buyer type</div>
                                                            <span className="ico icon-location"></span>
                                                            <span className="txt">{tokenInfo && getRoleDisplayName(el?.buyer_roles)}</span>
                                                        </li>
                                                        <li className="item">
                                                            <div>Main Acquisition Goal</div>
                                                            <span className="ico icon-topValue"></span>
                                                            <span className="txt">{mainAcquisition?.data?.find((element: any) => element?.id == el?.buyer?.main_acquisition_goal)?.attributes?.Label}</span>
                                                        </li>
                                                    </ul>
                                                    <div className="item listing">
                                                        <span style={{ display: "inline-block", marginRight: "1rem" }}>Preferred acquisition target location : </span>

                                                        {el?.buyer?.preferred_hq_location && el?.buyer?.preferred_hq_location.slice(0, 3).map((hqLocation: any, i: number) => (
                                                            <span className="txt" key={i}>{ISO3166.getCountry(hqLocation.iso2)}</span>
                                                        ))}
                                                        {el?.buyer?.preferred_hq_location && el?.buyer?.preferred_hq_location.length > 3 && (
                                                            <span className="txt">...</span>
                                                        )}
                                                    </div>
                                                </div>
                                                {el?.isUnlock ? null : <img className='matching-locked' src={Lock} alt="lock" />}
                                            </li>
                                        ))
                                    ) : (
                                        (filteredDatas) ? (
                                            filteredDatas?.map((el: any, index: any) => (
                                                <li className={`list ${isMotivated(el?.buyer) ? 'motivated' : ''}`} key={index}>
                                                    {/* {el?.buyer?.company_image !== null && el?.buyer?.company_image !== undefined && el?.buyer?.company_image !== "" ? (
                                                        <>
                                                            <div>
                                                                <div className="logo">
                                                                    <img src={process.env.REACT_APP_API_URL + urlsApi.upload.get_upload_image + '/' + el?.buyer?.company_image}
                                                                        width={60}
                                                                        height={60}
                                                                        alt="Company Logo" />
                                                                </div>
                                                                <div className='match'>
                                                                    <span>match</span>
                                                                    <span>{parseInt(el?.matching) < 60 ? '<60' : el?.matching}%</span>
                                                                </div>
                                                            </div>


                                                        </>

                                                    ) : ( */}
                                                        <>
                                                            <div className="logo">
                                                                <span className='icon-building1'></span>
                                                            </div>
                                                            <div className='match'>
                                                                <span>match</span>
                                                                <span>{parseInt(el?.matching) < 60 ? '<60' : el?.matching}%</span>
                                                            </div>
                                                        </>
                                                    {/* )
                                                    } */}

                                                    <div className="desc">
                                                        <div className="title">
                                                            {el?.buyer?.company_name !== "" && el?.buyer?.company_name !== "user_anonyma" ? 'Company Confidential' : 'Company Confidential'}
                                                            
                                                            <OverlayTrigger
                                                                overlay={
                                                                    <Tooltip className="custom-tooltip">
                                                                        { el?.isFavorite ? 'Click here to remove bookmark' : 'Click here to bookmark the listing' }
                                                                    </Tooltip>
                                                                }
                                                                >
                                                                <span className={`icon-star-${el?.isFavorite ? 'on' : 'off'}`} onClick={() => toggleFavorite(el?.id)}></span>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div className="about fs-6 mt-2">
                                                            <span>{el?.buyer?.company_reason}</span>
                                                        </div>
                                                        <a className="btn btn-devinsider" href="javascript:;" onClick={() => goToBuyerDetails(el?.buyer?.id)}>More details</a>
                                                        <ul>
                                                            <li className="item">
                                                                <div>Location</div>
                                                                <span className="ico icon-location"></span>
                                                                <span className="txt">{ISO3166.getCountry(el?.buyer?.location)}</span>
                                                            </li>
                                                            <li className="item">
                                                                <div>Buyer type</div>
                                                                <span className="ico icon-location"></span>
                                                                <span className="txt">{tokenInfo && getRoleDisplayName(el?.buyer_roles)}</span>
                                                            </li>
                                                            <li className="item">
                                                                <div>Main Acquisition Goal</div>
                                                                <span className="ico icon-topValue"></span>

                                                                <span className="txt">{mainAcquisition?.data?.find((element: any) => element?.id == el?.buyer?.main_acquisition_goal)?.attributes?.Label}</span>
                                                            </li>
                                                        </ul>
                                                        <div className="item listing">
                                                            <span style={{ display: "inline-block", marginRight: "1rem" }}>Preferred acquisition target location : </span>

                                                            {el?.buyer?.preferred_hq_location && el?.buyer?.preferred_hq_location.slice(0, 3).map((hqLocation: any, i: number) => (
                                                                <span className="txt" key={i}>{ISO3166.getCountry(hqLocation.iso2)}</span>
                                                            ))}
                                                            {el?.buyer?.preferred_hq_location && el?.buyer?.preferred_hq_location.length > 3 && (
                                                                <span className="txt">...</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {el?.isUnlock ? null : <img className='matching-locked' src={Lock} alt="lock" />}
                                                </li>
                                            ))
                                        ) : (
                                            dataMatching && dataMatching?.data.length > 0 ? (
                                                dataMatching?.data.map((el: any, index: any) => (
                                                    <li className={`list ${isMotivated(el?.buyer) ? 'motivated' : ''}`} key={index}>

                                                        {/* {el?.buyer?.company_image !== null && el?.buyer?.company_image !== undefined && el?.buyer?.company_image !== "" ? (
                                                            <>
                                                                <div className="logo">
                                                                    <img src={process.env.REACT_APP_API_URL + urlsApi.upload.get_upload_image + '/' + el?.buyer?.company_image}
                                                                        width={60}
                                                                        height={60}
                                                                        alt="Company Logo" />
                                                                </div>
                                                                <div className='match'>
                                                                    <span>match</span>
                                                                    <span>{parseInt(el?.matching) < 60 ? '<60' : el?.matching}%</span>
                                                                </div>


                                                            </>

                                                        ) : ( */}
                                                            <div>
                                                                <div className="logo">
                                                                    <span className='icon-building1'></span>
                                                                </div>
                                                                <div className='match'>
                                                                    <span>match</span>
                                                                    <span>{parseInt(el?.matching) < 60 ? '<60' : el?.matching}%</span>
                                                                </div>
                                                            </div>
                                                        {/* )
                                                        } */}

                                                        <div className="desc">
                                                            <div className="title">
                                                                {el?.buyer?.company_name !== "" && el?.buyer?.company_name !== "user_anonyma" ? 'Company Confidential' : 'Company Confidential'}  
                                                                
                                                                <OverlayTrigger
                                                                overlay={
                                                                    <Tooltip className="custom-tooltip">
                                                                        { el?.isFavorite ? 'Click here to remove bookmark' : 'Click here to bookmark the listing' }
                                                                    </Tooltip>
                                                                }
                                                                >
                                                                <span className={`pointer icon-star-${el?.isFavorite ? 'on' : 'off'}`} onClick={() => toggleFavorite(el?.id)}></span>
                                                            </OverlayTrigger>
                                                            </div>
                                                            <div className="about fs-6 mt-2">
                                                                <span>{el?.buyer?.company_reason}</span>
                                                            </div>
                                                            <a className="btn btn-devinsider" href="javascript:;" onClick={() => goToBuyerDetails(el?.buyer?.id)}>More details</a>
                                                            <ul>
                                                                <li className="item">
                                                                    <div>Location</div>
                                                                    <span className="ico icon-location"></span>
                                                                    <span className="txt">{ISO3166.getCountry(el?.buyer?.location)}</span>
                                                                </li>
                                                                <li className="item">
                                                                    <div>Buyer type</div>
                                                                    <span className="ico icon-location"></span>
                                                                    <span className="txt">{tokenInfo && getRoleDisplayName(el?.buyer_roles)}</span>
                                                                </li>
                                                                <li className="item">
                                                                    <div>Main Acquisition Goals</div>
                                                                    <span className="ico icon-topValue"></span>
    
                                                                    <span className="txt">{mainAcquisition?.data?.find((element: any) => element?.id == el?.buyer?.main_acquisition_goal)?.attributes?.Label}</span>
                                                                </li>
                                                            </ul>
                                                            <div className="item listing">
                                                                <span style={{ display: "inline-block", marginRight: "1rem" }}>Preferred acquisition target location : </span>

                                                                {el?.buyer?.preferred_hq_location && el?.buyer?.preferred_hq_location.slice(0, 3).map((hqLocation: any, i: number) => (
                                                                    <span className="txt" key={i}>{ISO3166.getCountry(hqLocation.iso2)}</span>
                                                                ))}
                                                                {el?.buyer?.preferred_hq_location && el?.buyer?.preferred_hq_location.length > 3 && (
                                                                    <span className="txt">...</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {el?.isUnlock ? null : <img className='matching-locked' src={Lock} alt="lock" />}
                                                    </li>
                                                ))
                                            ) : (
                                                <li className="no-data-message"></li>
                                            )
                                        )
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            }

        </>
    )
}
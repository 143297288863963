import { api } from '../query/api';
import { useFetch } from '../query/reactQuery';
import { urlsApi } from "./urls/apiUrl";

export const useGetTypeUser = (data: any, enabled: boolean) =>
  useFetch<any>(urlsApi.user.checkTypeUser+ '/' + data?.user_uuid, {}, {enabled: !!data?.user_uuid && enabled});

export const useGetNumberViewMessage = (enabled: boolean) =>
  useFetch<any>(urlsApi.messaging.view_number_message, {}, { enabled }, process.env.REACT_APP_MESSAGING_BACK_URL);

export const UseChangeCommunityPreferences = (data: any) =>
  api.post<any>(urlsApi.mailing.community_preferences, data);

export const UseGetCommunityPreferences = (user_id: any, enabled: boolean) =>
  useFetch<any>(urlsApi.mailing.community_preferences+'/'+user_id, {}, { enabled });

export const UseGetCommunityPreferencesMessage = (enabled: boolean) => 
  useFetch<any>(urlsApi.mailing.get_community_preferences_message, {}, { enabled }, process.env.REACT_APP_MESSAGING_BACK_URL);

export const UseChangeCommunityPreferencesMessage = (data: any) =>
  api.post<any>(urlsApi.mailing.update_community_preferences_message, data, process.env.REACT_APP_MESSAGING_BACK_URL);